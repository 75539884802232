<split (areaCollapsed)="onSplitLayoutChanged($event)" (dragEnd)="onSplitLayoutChanged($event)" *ngIf="moduleElement"
       [direction]="moduleElement.direction" [gutterSize]="moduleElement.gutterSize">

  <ng-template [ngIf]="moduleElementParts && moduleElementParts.length != 0">
    <div #partContainer [style.height.px]="partContainerHeight" [style.width.px]="partContainerWidth"
         class="overflow-y-hidden">
      <div class="tabrow" *ngIf="moduleElementParts">
        <li *ngFor="let moduleElementPart of moduleElementParts; let tabIndex = index;"
            [class]="part && part.id === moduleElementPart.id ? 'selected' : ''">
          <a appSeleniumDirective [moduleTab]="moduleElementPart.module"
             (click)="onPartTabChange(moduleElementPart)">{{ moduleElementPart.label || moduleElementPart.name }} </a>
          <span *ngIf="moduleElementPart.showBadge" class="p-badge">{{moduleElementPart.badgeCount}}</span>
        </li>
      </div>

      <ng-template [ngIf]="part && part._embedded && part._embedded.module">
        <div class="content-renderer-part-container">
          <split direction="horizontal" [gutterSize]="part._embedded.module.gutterSize">
            <app-content-renderer [masterElementContext]="masterElementContext" [isDialog]="isDialog"
                                  [moduleId]="part._embedded.module.id" [entity]="selectedEntity"
                                  [isPart]="true"></app-content-renderer>
          </split>
        </div>
      </ng-template>
    </div>
  </ng-template>

  <ng-template [ngIf]="moduleElement.element">

    <split (areaCollapsed)="onSplitLayoutChanged($event)" (dragEnd)="onSplitLayoutChanged($event)" [gutterSize]="0">

      <split-area *ngIf="leftToolbarExists(moduleElement.element)" [order]="1" class="toolbar-left-right toolbar-left"
                  [pixelSize]="33">
        <app-generic-toolbar [params]="getToolbarExtraParams()"
                             [items]="moduleElement.element.leftToolbarItems"></app-generic-toolbar>
      </split-area>

      <split-area [isCollapsed]="moduleElement.isCollapsed" [toggleDirection]="moduleElement.collapseDirection"
                  class="overflow-y-hidden" [order]="2" [size]="100">

        <div class="generic-element-container" [ngSwitch]="moduleElement.element.elementType.name">
          <ng-template ngSwitchCase="FullCalendar">
            <app-generic-full-calendar [isDialog]="isDialog" [masterElementContext]="masterElementContext"
                                       [masterEntity]="masterEntity" [isPart]="isPart" [masterField]="masterField"
                                       [parentComponent]="parentComponent"
                                       (componentInstantiated)="onGenericComponentInstantiated($event)" [entity]="selectedEntity"
                                       [moduleElement]="moduleElement" [element]="moduleElement.element"
                                       [toolbarItems]="moduleElement.toolbarItems"
                                       [statusBarItems]="moduleElement.statusBarItems"></app-generic-full-calendar>
          </ng-template>
          <ng-template ngSwitchCase="PdfViewer">
            <app-pdf-viewer [pdfToolbar]="'full'" [isDialog]="isDialog" [masterElementContext]="masterElementContext"
                            [isPart]="isPart" [parentComponent]="parentComponent"
                            (componentInstantiated)="onGenericComponentInstantiated($event)" [entity]="selectedEntity"
                            [selectedMasterEntity]="selectedEntity" [moduleElement]="moduleElement"
                            [element]="moduleElement.element" [toolbarItems]="moduleElement.toolbarItems"
                            [statusBarItems]="moduleElement.statusBarItems"></app-pdf-viewer>
          </ng-template>
          <ng-template ngSwitchCase="MiniPdfViewer">
            <app-pdf-viewer [pdfToolbar]="'mini'" [isDialog]="isDialog" [masterElementContext]="masterElementContext"
                            [isPart]="isPart" [parentComponent]="parentComponent"
                            (componentInstantiated)="onGenericComponentInstantiated($event)" [entity]="selectedEntity"
                            [selectedMasterEntity]="selectedEntity" [moduleElement]="moduleElement"
                            [element]="moduleElement.element" [toolbarItems]="moduleElement.toolbarItems"
                            [statusBarItems]="moduleElement.statusBarItems"></app-pdf-viewer>
          </ng-template>
          <ng-template ngSwitchCase="DmsView">
            <app-dms-view [isDialog]="isDialog" [masterElementContext]="masterElementContext"
                            [isPart]="isPart" [parentComponent]="parentComponent"
                            (componentInstantiated)="onGenericComponentInstantiated($event)" [entity]="selectedEntity"
                            [selectedMasterEntity]="selectedEntity" [moduleElement]="moduleElement"
                            [element]="moduleElement.element" [toolbarItems]="moduleElement.toolbarItems"
                            [statusBarItems]="moduleElement.statusBarItems"></app-dms-view>
          </ng-template>
          <ng-template ngSwitchCase="MemoFieldDmsSearch">
            <app-custom-memo-field-dms-search [isDialog]="isDialog" [masterElementContext]="masterElementContext"
                          [isPart]="isPart" [parentComponent]="parentComponent"
                          (componentInstantiated)="onGenericComponentInstantiated($event)" [entity]="selectedEntity"
                          [selectedMasterEntity]="selectedEntity" [moduleElement]="moduleElement"
                          [element]="moduleElement.element" [toolbarItems]="moduleElement.toolbarItems"
                          [statusBarItems]="moduleElement.statusBarItems"></app-custom-memo-field-dms-search>
          </ng-template>
          <ng-template ngSwitchCase="ImageViewer">
            <app-image-viewer [additional]="additional" [isDialog]="isDialog"
                              [masterElementContext]="masterElementContext" [isPart]="isPart"
                              [parentComponent]="parentComponent"
                              (componentInstantiated)="onGenericComponentInstantiated($event)" [entity]="selectedEntity"
                              [selectedMasterEntity]="selectedEntity" [moduleElement]="moduleElement"
                              [element]="moduleElement.element" [toolbarItems]="moduleElement.toolbarItems"
                              [statusBarItems]="moduleElement.statusBarItems"></app-image-viewer>
          </ng-template>
          <ng-template ngSwitchCase="Grid">
            <app-generic-turbo-grid [isDialog]="isDialog" [masterElementContext]="masterElementContext"
                                    [masterEntity]="masterEntity" [masterEntityEditingField]="masterEntityEditingField"
                                    [isPart]="isPart" [masterField]="masterField" [parentComponent]="parentComponent"
                                    (componentInstantiated)="onGenericComponentInstantiated($event)"
                                    [selectedMasterEntity]="selectedEntity" [moduleElement]="moduleElement"
                                    [element]="moduleElement.element" [fields]="moduleElement.fields"
                                    [toolbarItems]="moduleElement.toolbarItems"
                                    [statusBarItems]="moduleElement.statusBarItems"
                                    [masterFilterField]="masterFilterField" [masterFilterValue]="masterFilterValue"
                                    [justAFilter]="justAFilter"></app-generic-turbo-grid>
          </ng-template>
          <ng-template ngSwitchCase="NewGrid">
            <app-generic-turbo-grid [isDialog]="isDialog" [masterElementContext]="masterElementContext"
                                    [masterEntity]="masterEntity" [masterEntityEditingField]="masterEntityEditingField"
                                    [isPart]="isPart" [masterField]="masterField" [parentComponent]="parentComponent"
                                    (componentInstantiated)="onGenericComponentInstantiated($event)"
                                    [selectedMasterEntity]="selectedEntity" [moduleElement]="moduleElement"
                                    [element]="moduleElement.element" [fields]="moduleElement.fields"
                                    [toolbarItems]="moduleElement.toolbarItems"
                                    [statusBarItems]="moduleElement.statusBarItems"
                                    [masterFilterField]="masterFilterField" [masterFilterValue]="masterFilterValue"
                                    [justAFilter]="justAFilter"></app-generic-turbo-grid>
          </ng-template>
          <ng-template ngSwitchCase="TreeGrid">
            <app-generic-tree-grid [isDialog]="isDialog" [masterElementContext]="masterElementContext"
                                   [masterEntity]="masterEntity" [isPart]="isPart" [masterField]="masterField"
                                   [parentComponent]="parentComponent"
                                   (componentInstantiated)="onGenericComponentInstantiated($event)"
                                   [selectedMasterEntity]="selectedEntity" [moduleElement]="moduleElement"
                                   [element]="moduleElement.element" [fields]="moduleElement.fields"
                                   [toolbarItems]="moduleElement.toolbarItems"
                                   [statusBarItems]="moduleElement.statusBarItems"></app-generic-tree-grid>
          </ng-template>
          <ng-template ngSwitchCase="Form">
            <app-form-viewer [isDialog]="isDialog" [masterElementContext]="masterElementContext"
                             [parentComponent]="parentComponent" [isPart]="isPart"
                             (componentInstantiated)="onGenericComponentInstantiated($event)"
                             [moduleElement]="moduleElement" [formId]="moduleElement.element.id"
                             [entity]="selectedEntity"></app-form-viewer>
          </ng-template>
          <ng-template ngSwitchCase="TodoForm">
            <app-custom-todo-form [isDialog]="isDialog" [masterElementContext]="masterElementContext"
                                  [parentComponent]="parentComponent" [isPart]="isPart"
                                  (componentInstantiated)="onGenericComponentInstantiated($event)"
                                  [moduleElement]="moduleElement" [element]="moduleElement.element"
                                  [entity]="selectedEntity"></app-custom-todo-form>
          </ng-template>
          <ng-template ngSwitchCase="CronJobForm">
            <app-custom-cron-job-form [isDialog]="isDialog" [masterElementContext]="masterElementContext"
                                      [parentComponent]="parentComponent" [isPart]="isPart"
                                      (componentInstantiated)="onGenericComponentInstantiated($event)"
                                      [moduleElement]="moduleElement" [element]="moduleElement.element"
                                      [entity]="selectedEntity"></app-custom-cron-job-form>
          </ng-template>
          <ng-template ngSwitchCase="Docuware">
            <app-generic-iframe [isDialog]="isDialog" [masterElementContext]="masterElementContext"
                                [masterEntity]="masterEntity" [isPart]="isPart" [masterField]="masterField"
                                [parentComponent]="parentComponent"
                                (componentInstantiated)="onGenericComponentInstantiated($event)"
                                [moduleElement]="moduleElement" [element]="moduleElement.element"
                                [fields]="moduleElement.fields" [toolbarItems]="moduleElement.toolbarItems"
                                [statusBarItems]="moduleElement.statusBarItems"></app-generic-iframe>
          </ng-template>
          <ng-template ngSwitchCase="ReportView">
            <app-report [isDialog]="isDialog" [masterElementContext]="masterElementContext"
                        [masterEntity]="masterEntity" [isPart]="isPart" [masterField]="masterField"
                        [parentComponent]="parentComponent"
                        (componentInstantiated)="onGenericComponentInstantiated($event)"
                        [selectedMasterEntity]="selectedEntity" [moduleElement]="moduleElement"
                        [element]="moduleElement.element" [fields]="moduleElement.fields"
                        [toolbarItems]="moduleElement.toolbarItems"
                        [statusBarItems]="moduleElement.statusBarItems"></app-report>
          </ng-template>
          <ng-template ngSwitchCase="DynamicTable">
            <app-generic-dynamic-table [isDialog]="isDialog" [masterElementContext]="masterElementContext"
                                       [masterEntity]="masterEntity" [isPart]="isPart" [masterField]="masterField"
                                       [parentComponent]="parentComponent"
                                       (componentInstantiated)="onGenericComponentInstantiated($event)"
                                       [selectedMasterEntity]="selectedEntity" [moduleElement]="moduleElement"
                                       [element]="moduleElement.element" [fields]="moduleElement.fields"
                                       [toolbarItems]="moduleElement.toolbarItems"
                                       [statusBarItems]="moduleElement.statusBarItems"></app-generic-dynamic-table>
          </ng-template>
          <ng-template ngSwitchCase="DynamicTree">
            <!-- using old until we update primeng -->
            <app-generic-dynamic-tree-old [isDialog]="isDialog" [masterElementContext]="masterElementContext"
                                          [masterEntity]="masterEntity" [isPart]="isPart" [masterField]="masterField"
                                          [parentComponent]="parentComponent"
                                          (componentInstantiated)="onGenericComponentInstantiated($event)"
                                          [selectedMasterEntity]="selectedEntity" [moduleElement]="moduleElement"
                                          [element]="moduleElement.element" [fields]="moduleElement.fields"
                                          [toolbarItems]="moduleElement.toolbarItems"
                                          [statusBarItems]="moduleElement.statusBarItems"></app-generic-dynamic-tree-old>
          </ng-template>
          <ng-template ngSwitchCase="Assignments">
            <app-custom-assignment [isDialog]="isDialog" [masterElementContext]="masterElementContext"
                                   [masterEntity]="masterEntity" [isPart]="isPart" [masterField]="masterField"
                                   [parentComponent]="parentComponent"
                                   (componentInstantiated)="onGenericComponentInstantiated($event)"
                                   [selectedMasterEntity]="selectedEntity" [moduleElement]="moduleElement"
                                   [element]="moduleElement.element" [fields]="moduleElement.fields"
                                   [toolbarItems]="moduleElement.toolbarItems"
                                   [statusBarItems]="moduleElement.statusBarItems"></app-custom-assignment>
          </ng-template>
          <ng-template ngSwitchCase="LeasedEmployeeSearch">
            <app-custom-leased-employee-search [isDialog]="isDialog" [masterElementContext]="masterElementContext"
                                               [masterEntity]="masterEntity" [isPart]="isPart"
                                               [masterField]="masterField" [parentComponent]="parentComponent"
                                               (componentInstantiated)="onGenericComponentInstantiated($event)"
                                               [selectedMasterEntity]="selectedEntity" [moduleElement]="moduleElement"
                                               [element]="moduleElement.element" [fields]="moduleElement.fields"
                                               [toolbarItems]="moduleElement.toolbarItems"
                                               [statusBarItems]="moduleElement.statusBarItems"></app-custom-leased-employee-search>
          </ng-template>
          <ng-template ngSwitchCase="Wizard">
            <app-custom-wizard [isDialog]="isDialog" [masterElementContext]="masterElementContext"
                               [masterEntity]="masterEntity" [isPart]="isPart" [masterField]="masterField"
                               [parentComponent]="parentComponent"
                               (componentInstantiated)="onGenericComponentInstantiated($event)"
                               [selectedMasterEntity]="selectedEntity" [moduleElement]="moduleElement"
                               [element]="moduleElement.element" [fields]="moduleElement.fields"
                               [toolbarItems]="moduleElement.toolbarItems"
                               [statusBarItems]="moduleElement.statusBarItems"></app-custom-wizard>
          </ng-template>
          <ng-template ngSwitchCase="Questionnaire">
            <app-custom-questionnaire [isDialog]="isDialog" [masterElementContext]="masterElementContext"
                                      [masterEntity]="masterEntity" [isPart]="isPart" [masterField]="masterField"
                                      [parentComponent]="parentComponent"
                                      (componentInstantiated)="onGenericComponentInstantiated($event)"
                                      [entity]="selectedEntity" [moduleElement]="moduleElement"
                                      [element]="moduleElement.element" [fields]="moduleElement.fields"
                                      [toolbarItems]="moduleElement.toolbarItems"
                                      [statusBarItems]="moduleElement.statusBarItems"></app-custom-questionnaire>
          </ng-template>
          <ng-template ngSwitchCase="Chart">
            <app-custom-chart [isDialog]="isDialog" [masterElementContext]="masterElementContext"
                              [masterEntity]="masterEntity" [isPart]="isPart" [masterField]="masterField"
                              [parentComponent]="parentComponent"
                              (componentInstantiated)="onGenericComponentInstantiated($event)" [entity]="selectedEntity"
                              [moduleElement]="moduleElement" [element]="moduleElement.element"
                              [fields]="moduleElement.fields" [toolbarItems]="moduleElement.toolbarItems"
                              [statusBarItems]="moduleElement.statusBarItems"></app-custom-chart>
          </ng-template>
          <ng-template ngSwitchCase="SqlTable">
            <app-custom-sql-table [isDialog]="isDialog" [masterElementContext]="masterElementContext"
                                  [masterEntity]="masterEntity" [isPart]="isPart" [masterField]="masterField"
                                  [parentComponent]="parentComponent"
                                  (componentInstantiated)="onGenericComponentInstantiated($event)"
                                  [entity]="selectedEntity" [moduleElement]="moduleElement"
                                  [element]="moduleElement.element" [fields]="moduleElement.fields"
                                  [toolbarItems]="moduleElement.toolbarItems"
                                  [statusBarItems]="moduleElement.statusBarItems"></app-custom-sql-table>
          </ng-template>
          <ng-template ngSwitchCase="FilePreview">
            <app-generic-file-preview [isDialog]="isDialog" [masterElementContext]="masterElementContext"
                                      [masterEntity]="masterEntity" [isPart]="isPart" [masterField]="masterField"
                                      [parentComponent]="parentComponent"
                                      (componentInstantiated)="onGenericComponentInstantiated($event)"
                                      [moduleElement]="moduleElement" [element]="moduleElement.element"
                                      [fields]="moduleElement.fields" [toolbarItems]="moduleElement.toolbarItems"
                                      [statusBarItems]="moduleElement.statusBarItems"
                                      [entity]="selectedEntity"></app-generic-file-preview>
          </ng-template>
          <ng-template ngSwitchCase="Planning">
            <app-custom-planning [isDialog]="isDialog" [masterElementContext]="masterElementContext"
                                 [masterEntity]="masterEntity" [isPart]="isPart" [masterField]="masterField"
                                 [parentComponent]="parentComponent"
                                 (componentInstantiated)="onGenericComponentInstantiated($event)"
                                 [moduleElement]="moduleElement" [element]="moduleElement.element"
                                 [fields]="moduleElement.fields" [toolbarItems]="moduleElement.toolbarItems"
                                 [statusBarItems]="moduleElement.statusBarItems"
                                 [entity]="selectedEntity"></app-custom-planning>
          </ng-template>
          <ng-template ngSwitchCase="MemoFieldDefinitionValue">
            <app-custom-memo-field-definition-value [isDialog]="isDialog" [masterElementContext]="masterElementContext"
                                                    [masterEntity]="masterEntity" [isPart]="isPart"
                                                    [masterField]="masterField" [parentComponent]="parentComponent"
                                                    (componentInstantiated)="onGenericComponentInstantiated($event)"
                                                    [moduleElement]="moduleElement" [element]="moduleElement.element"
                                                    [fields]="moduleElement.fields"
                                                    [toolbarItems]="moduleElement.toolbarItems"
                                                    [statusBarItems]="moduleElement.statusBarItems"
                                                    [entity]="selectedEntity"></app-custom-memo-field-definition-value>
          </ng-template>
          <ng-template ngSwitchCase="ConditionTreeGrid">
            <app-custom-condition-tree-grid [isDialog]="isDialog" [masterElementContext]="masterElementContext"
                                            [masterEntity]="masterEntity" [isPart]="isPart" [masterField]="masterField"
                                            [parentComponent]="parentComponent"
                                            (componentInstantiated)="onGenericComponentInstantiated($event)"
                                            [moduleElement]="moduleElement" [element]="moduleElement.element"
                                            [fields]="moduleElement.fields" [toolbarItems]="moduleElement.toolbarItems"
                                            [statusBarItems]="moduleElement.statusBarItems"></app-custom-condition-tree-grid>
          </ng-template>
          <ng-template ngSwitchCase="WorkingTimeModelPositionGrid">
            <app-custom-working-time-model-position-grid [isDialog]="isDialog"
                                                         [masterElementContext]="masterElementContext"
                                                         [masterEntity]="masterEntity" [isPart]="isPart"
                                                         [masterField]="masterField" [parentComponent]="parentComponent"
                                                         (componentInstantiated)="onGenericComponentInstantiated($event)"
                                                         [moduleElement]="moduleElement"
                                                         [element]="moduleElement.element"
                                                         [fields]="moduleElement.fields"
                                                         [toolbarItems]="moduleElement.toolbarItems"
                                                         [statusBarItems]="moduleElement.statusBarItems"></app-custom-working-time-model-position-grid>
          </ng-template>
          <ng-template ngSwitchCase="WorkHourGrid">
            <app-custom-work-hour-grid [isDialog]="isDialog" [masterElementContext]="masterElementContext"
                                       [masterEntity]="masterEntity" [isPart]="isPart" [masterField]="masterField"
                                       [parentComponent]="parentComponent"
                                       (componentInstantiated)="onGenericComponentInstantiated($event)"
                                       [moduleElement]="moduleElement" [element]="moduleElement.element"
                                       [fields]="moduleElement.fields" [toolbarItems]="moduleElement.toolbarItems"
                                       [statusBarItems]="moduleElement.statusBarItems"></app-custom-work-hour-grid>
          </ng-template>
          <ng-template ngSwitchCase="WorkHourDocuwareGrid">
            <app-custom-work-hour-docuware-grid [isDialog]="isDialog" [masterElementContext]="masterElementContext"
                                                [masterEntity]="masterEntity" [isPart]="isPart"
                                                [masterField]="masterField" [parentComponent]="parentComponent"
                                                (componentInstantiated)="onGenericComponentInstantiated($event)"
                                                [moduleElement]="moduleElement" [element]="moduleElement.element"
                                                [fields]="moduleElement.fields"
                                                [toolbarItems]="moduleElement.toolbarItems"
                                                [statusBarItems]="moduleElement.statusBarItems"></app-custom-work-hour-docuware-grid>
          </ng-template>
          <ng-template ngSwitchCase="TimeEntryWeeklyOverview">
            <app-work-hour-time-entry-weekly-overview [isDialog]="isDialog"
                                                      [masterElementContext]="masterElementContext"
                                                      [masterEntity]="masterEntity" [isPart]="isPart"
                                                      [masterField]="masterField" [parentComponent]="parentComponent"
                                                      (componentInstantiated)="onGenericComponentInstantiated($event)"
                                                      [moduleElement]="moduleElement" [element]="moduleElement.element"
                                                      [fields]="moduleElement.fields"
                                                      [toolbarItems]="moduleElement.toolbarItems"
                                                      [statusBarItems]="moduleElement.statusBarItems"></app-work-hour-time-entry-weekly-overview>
          </ng-template>
          <ng-template ngSwitchCase="CustomerInvoicePositionTable">
            <app-custom-customer-invoice-position-table [isDialog]="isDialog"
                                                        [masterElementContext]="masterElementContext"
                                                        [masterEntity]="masterEntity" [isPart]="isPart"
                                                        [masterField]="masterField" [parentComponent]="parentComponent"
                                                        (componentInstantiated)="onGenericComponentInstantiated($event)"
                                                        [moduleElement]="moduleElement"
                                                        [element]="moduleElement.element"
                                                        [fields]="moduleElement.fields"
                                                        [toolbarItems]="moduleElement.toolbarItems"
                                                        [statusBarItems]="moduleElement.statusBarItems"></app-custom-customer-invoice-position-table>
          </ng-template>
          <ng-template ngSwitchCase="CustomerInvoicePositionDetailTable">
            <app-custom-customer-invoice-position-detail-table [isDialog]="isDialog"
                                                               [masterElementContext]="masterElementContext"
                                                               [masterEntity]="masterEntity" [isPart]="isPart"
                                                               [masterField]="masterField"
                                                               [parentComponent]="parentComponent"
                                                               (componentInstantiated)="onGenericComponentInstantiated($event)"
                                                               [moduleElement]="moduleElement"
                                                               [element]="moduleElement.element"
                                                               [fields]="moduleElement.fields"
                                                               [toolbarItems]="moduleElement.toolbarItems"
                                                               [statusBarItems]="moduleElement.statusBarItems"></app-custom-customer-invoice-position-detail-table>
          </ng-template>
          <ng-template ngSwitchCase="CustomerInternalNotesForm">
            <app-custom-customer-internal-notes-form [isDialog]="isDialog" [masterElementContext]="masterElementContext"
                                                     [masterEntity]="masterEntity" [isPart]="isPart"
                                                     [masterField]="masterField" [parentComponent]="parentComponent"
                                                     (componentInstantiated)="onGenericComponentInstantiated($event)"
                                                     [moduleElement]="moduleElement" [element]="moduleElement.element"
                                                     [fields]="moduleElement.fields"
                                                     [toolbarItems]="moduleElement.toolbarItems"
                                                     [statusBarItems]="moduleElement.statusBarItems"></app-custom-customer-internal-notes-form>
          </ng-template>
        </div>

      </split-area>

      <split-area *ngIf="rightToolbarExists(moduleElement.element)" [order]="3" class="toolbar-left-right toolbar-right"
                  [pixelSize]="33">
        <app-generic-toolbar [params]="getToolbarExtraParams()"
                             [items]="moduleElement.element.rightToolbarItems"></app-generic-toolbar>
      </split-area>

    </split>

  </ng-template>

  <ng-template ngFor let-currentElement [ngForOf]="moduleElement.children">
    <split-area [order]="currentElement.orderPosition" [size]="currentElement.percentageSize"
                [pixelSize]="currentElement.pixelSize"
                [isCollapsed]="currentElement.isCollapsed"
                [toggleDirection]="currentElement.collapseDirection">

      {{isPreview ? currentElement.label : ''}}
      <app-split-template [isDialog]="isDialog" [masterElementContext]="masterElementContext"
                          [moduleElement]="currentElement" [isPart]="isPart" [entity]="selectedEntity"
                          [masterField]="masterField"></app-split-template>

    </split-area>
  </ng-template>
</split>
