import {of as observableOf, Observable} from 'rxjs';
import {AbstractExecutionStep} from '../../../core/executor/abstract-execution-step';
import {ExecutionStepStatus} from '../../../core/executor/execution-step-status';
import {GenericElementAbstract} from '../../content-renderer/elements/generic-element-abstract.component';
import {FormViewerComponent} from '../../form-viewer/form-viewer.component';
import {FormService} from '../../form-viewer/form.service';
import {ChangeDetectorRefHelper} from '../../helpers/change-detector-ref.helper';
import {GenericDialogModuleService} from '../../content-renderer/elements/generic-dialog/service/generic-dialog-module.service';
import {EntityStatus} from '../entity/entity-status';
import {EntityManagerService} from '../../../core/service/entity-manager/entity-manager.service';

export class SetDefaultTextToMasterFormElementExecutionStep extends AbstractExecutionStep {

  public doExecute(): Observable<ExecutionStepStatus> {
    const payload = this.getPayload();

    if (!(payload.getValue() instanceof GenericElementAbstract)) {
      return this.getFailObservable('You need to pass GenericElementAbstract as Payload value!');
    }

    return this.doSet(payload.getValue());
  }

  protected doSet(component: GenericElementAbstract): Observable<ExecutionStepStatus> {
    const form: FormViewerComponent = component.getElementContext().getMasterElementContext().component,
      selectedEntity: {text: string} = component.getSelectedEntity();

    if (form) {
      const formElement = this.injector.get(FormService).getElementByMany(form.form, {
        editorToolbarButtonsConfiguration: 'defaultWithModuleButton'
      });

      if (formElement) {
        const entity = form.getSelectedEntity();

        this.injector.get(EntityManagerService, null).persistMore(entity, [
          {property: EntityStatus.ENTITY_CHANGED_FLAG, newValue: true, force: true},
          {property: formElement.datamodelField, newValue: selectedEntity.text, force: true}
        ]);
        form.setEntity({...entity});

        form.recheckToolbarItems();
        ChangeDetectorRefHelper.detectChanges(form);
      }
    }

    this.injector.get(GenericDialogModuleService, null).persistHide();

    return observableOf({status: true, content: null});
  }
}
