/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/forms";
import * as i2 from "primeng/components/inputtextarea/inputtextarea";
import * as i3 from "../../directives/selenium.directive";
import * as i4 from "angular-froala-wysiwyg/editor/editor.directive";
import * as i5 from "@angular/common";
import * as i6 from "./element-input-textarea.component";
import * as i7 from "../form.service";
import * as i8 from "../form-viewer.service";
import * as i9 from "@ngx-translate/core";
var styles_ElementInputTextareaComponent = ["[_nghost-%COMP%] {\n      height: 100%;\n    }\n\n    .container[_ngcontent-%COMP%], .editor-container[_ngcontent-%COMP%], .ui-inputtext[_ngcontent-%COMP%] {\n      height: 100%;\n    }\n\n      .fr-wrapper {\n      height: calc(100% - 85px);\n      min-height: 85px;\n    }\n\n      .fr-element {\n      height: 100%;\n    }\n\n    textarea[readonly].ui-widget-header[_ngcontent-%COMP%]   .ui-inputtext.ui-state-default[_ngcontent-%COMP%], textarea[readonly].ui-widget-content[_ngcontent-%COMP%]   .ui-inputtext.ui-state-default[_ngcontent-%COMP%], textarea[readonly].ui-inputtext.ui-state-default[_ngcontent-%COMP%], textarea[readonly].ui-widget-header[_ngcontent-%COMP%]   .ui-inputtext.ui-state-default[_ngcontent-%COMP%]:focus:not(:active):not(:hover), textarea[readonly].ui-widget-content[_ngcontent-%COMP%]   .ui-inputtext.ui-state-default[_ngcontent-%COMP%]:focus:not(:active):not(:hover), textarea[readonly].ui-inputtext.ui-state-default[_ngcontent-%COMP%]:focus:not(:active):not(:hover), textarea[readonly].ui-inputtext.ui-state-focus[_ngcontent-%COMP%], textarea[readonly].ui-inputtext[_ngcontent-%COMP%]:focus, textarea[readonly].ui-inputtext[_ngcontent-%COMP%], textarea[readonly].ui-inputtext[_ngcontent-%COMP%] {\n      background-color: lightgrey;\n      border-color: lightgrey;\n      color: black;\n      box-shadow: none;\n      -moz-box-shadow: none;\n      -webkit-box-shadow: none;\n    }"];
var RenderType_ElementInputTextareaComponent = i0.ɵcrt({ encapsulation: 0, styles: styles_ElementInputTextareaComponent, data: {} });
export { RenderType_ElementInputTextareaComponent as RenderType_ElementInputTextareaComponent };
function View_ElementInputTextareaComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, [[1, 0], ["inputElement", 1]], null, 7, "textarea", [["appSeleniumDirective", ""], ["pInputTextarea", ""]], [[8, "placeholder", 0], [8, "readOnly", 0], [4, "font-weight", null], [8, "tabIndex", 0], [2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null], [2, "ui-inputtext", null], [2, "ui-corner-all", null], [2, "ui-inputtextarea-resizable", null], [2, "ui-state-default", null], [2, "ui-widget", null], [2, "ui-state-filled", null]], [[null, "keyup"], [null, "keydown"], [null, "input"], [null, "blur"], [null, "compositionstart"], [null, "compositionend"], [null, "focus"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("input" === en)) {
        var pd_0 = (i0.ɵnov(_v, 1)._handleInput($event.target.value) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i0.ɵnov(_v, 1).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("compositionstart" === en)) {
        var pd_2 = (i0.ɵnov(_v, 1)._compositionStart() !== false);
        ad = (pd_2 && ad);
    } if (("compositionend" === en)) {
        var pd_3 = (i0.ɵnov(_v, 1)._compositionEnd($event.target.value) !== false);
        ad = (pd_3 && ad);
    } if (("input" === en)) {
        var pd_4 = (i0.ɵnov(_v, 6).onInput($event) !== false);
        ad = (pd_4 && ad);
    } if (("focus" === en)) {
        var pd_5 = (i0.ɵnov(_v, 6).onFocus($event) !== false);
        ad = (pd_5 && ad);
    } if (("blur" === en)) {
        var pd_6 = (i0.ɵnov(_v, 6).onBlur($event) !== false);
        ad = (pd_6 && ad);
    } if (("keyup" === en)) {
        var pd_7 = (_co.keyUp.next($event) !== false);
        ad = (pd_7 && ad);
    } if (("keydown" === en)) {
        var pd_8 = (_co.keyDown.next($event) !== false);
        ad = (pd_8 && ad);
    } return ad; }, null, null)), i0.ɵdid(1, 16384, null, 0, i1.DefaultValueAccessor, [i0.Renderer2, i0.ElementRef, [2, i1.COMPOSITION_BUFFER_MODE]], null, null), i0.ɵprd(1024, null, i1.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i1.DefaultValueAccessor]), i0.ɵdid(3, 671744, null, 0, i1.FormControlName, [[3, i1.ControlContainer], [8, null], [8, null], [6, i1.NG_VALUE_ACCESSOR], [2, i1.ɵangular_packages_forms_forms_q]], { name: [0, "name"] }, null), i0.ɵprd(2048, null, i1.NgControl, null, [i1.FormControlName]), i0.ɵdid(5, 16384, null, 0, i1.NgControlStatus, [[4, i1.NgControl]], null, null), i0.ɵdid(6, 278528, null, 0, i2.InputTextarea, [i0.ElementRef, [2, i1.NgModel]], null, null), i0.ɵdid(7, 4210688, null, 0, i3.SeleniumDirective, [i0.ElementRef, i0.Renderer2], { element: [0, "element"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_17 = _co.formControlName; _ck(_v, 3, 0, currVal_17); _ck(_v, 6, 0); var currVal_18 = _co.element; _ck(_v, 7, 0, currVal_18); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.element.placeholder; var currVal_1 = _co.isReadOnly(); var currVal_2 = _co.getFontWeight(); var currVal_3 = i0.ɵinlineInterpolate(1, "", _co.element.tabIndex, ""); var currVal_4 = i0.ɵnov(_v, 5).ngClassUntouched; var currVal_5 = i0.ɵnov(_v, 5).ngClassTouched; var currVal_6 = i0.ɵnov(_v, 5).ngClassPristine; var currVal_7 = i0.ɵnov(_v, 5).ngClassDirty; var currVal_8 = i0.ɵnov(_v, 5).ngClassValid; var currVal_9 = i0.ɵnov(_v, 5).ngClassInvalid; var currVal_10 = i0.ɵnov(_v, 5).ngClassPending; var currVal_11 = true; var currVal_12 = true; var currVal_13 = i0.ɵnov(_v, 6).autoResize; var currVal_14 = true; var currVal_15 = true; var currVal_16 = i0.ɵnov(_v, 6).filled; _ck(_v, 0, 1, [currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9, currVal_10, currVal_11, currVal_12, currVal_13, currVal_14, currVal_15, currVal_16]); }); }
function View_ElementInputTextareaComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, [["editor", 1]], null, 5, "div", [["class", "editor-container"]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], null, null, null, null)), i0.ɵdid(1, 212992, [[2, 4]], 0, i4.FroalaEditorDirective, [i0.ElementRef, i0.NgZone], { froalaEditor: [0, "froalaEditor"] }, null), i0.ɵprd(1024, null, i1.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i4.FroalaEditorDirective]), i0.ɵdid(3, 671744, null, 0, i1.FormControlName, [[3, i1.ControlContainer], [8, null], [8, null], [6, i1.NG_VALUE_ACCESSOR], [2, i1.ɵangular_packages_forms_forms_q]], { name: [0, "name"] }, null), i0.ɵprd(2048, null, i1.NgControl, null, [i1.FormControlName]), i0.ɵdid(5, 16384, null, 0, i1.NgControlStatus, [[4, i1.NgControl]], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_7 = _co.editorOptions; _ck(_v, 1, 0, currVal_7); var currVal_8 = _co.formControlName; _ck(_v, 3, 0, currVal_8); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 5).ngClassUntouched; var currVal_1 = i0.ɵnov(_v, 5).ngClassTouched; var currVal_2 = i0.ɵnov(_v, 5).ngClassPristine; var currVal_3 = i0.ɵnov(_v, 5).ngClassDirty; var currVal_4 = i0.ɵnov(_v, 5).ngClassValid; var currVal_5 = i0.ɵnov(_v, 5).ngClassInvalid; var currVal_6 = i0.ɵnov(_v, 5).ngClassPending; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); }); }
export function View_ElementInputTextareaComponent_0(_l) { return i0.ɵvid(2, [i0.ɵqud(671088640, 1, { inputElement: 0 }), i0.ɵqud(671088640, 2, { editor: 0 }), (_l()(), i0.ɵeld(2, 0, null, null, 7, "div", [["class", "container"]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "submit"], [null, "reset"]], function (_v, en, $event) { var ad = true; if (("submit" === en)) {
        var pd_0 = (i0.ɵnov(_v, 3).onSubmit($event) !== false);
        ad = (pd_0 && ad);
    } if (("reset" === en)) {
        var pd_1 = (i0.ɵnov(_v, 3).onReset() !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i0.ɵdid(3, 540672, null, 0, i1.FormGroupDirective, [[8, null], [8, null]], { form: [0, "form"] }, null), i0.ɵprd(2048, null, i1.ControlContainer, null, [i1.FormGroupDirective]), i0.ɵdid(5, 16384, null, 0, i1.NgControlStatusGroup, [[4, i1.ControlContainer]], null, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ElementInputTextareaComponent_1)), i0.ɵdid(7, 16384, null, 0, i5.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ElementInputTextareaComponent_2)), i0.ɵdid(9, 16384, null, 0, i5.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_7 = _co.formGroup; _ck(_v, 3, 0, currVal_7); var currVal_8 = !_co.element.isEditor; _ck(_v, 7, 0, currVal_8); var currVal_9 = (_co.element.isEditor && _co.editorOptions); _ck(_v, 9, 0, currVal_9); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 5).ngClassUntouched; var currVal_1 = i0.ɵnov(_v, 5).ngClassTouched; var currVal_2 = i0.ɵnov(_v, 5).ngClassPristine; var currVal_3 = i0.ɵnov(_v, 5).ngClassDirty; var currVal_4 = i0.ɵnov(_v, 5).ngClassValid; var currVal_5 = i0.ɵnov(_v, 5).ngClassInvalid; var currVal_6 = i0.ɵnov(_v, 5).ngClassPending; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); }); }
export function View_ElementInputTextareaComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "app-form-element-input-textarea", [], null, null, null, View_ElementInputTextareaComponent_0, RenderType_ElementInputTextareaComponent)), i0.ɵdid(1, 6012928, null, 1, i6.ElementInputTextareaComponent, [i7.FormService, i0.ChangeDetectorRef, i8.FormViewerService, i9.TranslateService], null, null), i0.ɵqud(603979776, 1, { insideNgContent: 0 })], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ElementInputTextareaComponentNgFactory = i0.ɵccf("app-form-element-input-textarea", i6.ElementInputTextareaComponent, View_ElementInputTextareaComponent_Host_0, { form: "form", formGroup: "formGroup", entity: "entity", element: "element", moduleElement: "moduleElement", selectedElement: "selectedElement", showDialogAddAddress: "showDialogAddAddress", actionParams: "actionParams", editMode: "editMode" }, { selectedElementChange: "selectedElementChange", showDialogAddAddressChange: "showDialogAddAddressChange", actionParamsChange: "actionParamsChange" }, []);
export { ElementInputTextareaComponentNgFactory as ElementInputTextareaComponentNgFactory };
