/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "primeng/components/dom/domhandler";
import * as i2 from "primeng/components/button/button";
import * as i3 from "primeng/components/tooltip/tooltip";
import * as i4 from "@ngx-translate/core";
import * as i5 from "../../../../../p-button/p-button.directive";
import * as i6 from "../../../../../../../../node_modules/primeng/components/progressspinner/progressspinner.ngfactory";
import * as i7 from "primeng/components/progressspinner/progressspinner";
import * as i8 from "@angular/common";
import * as i9 from "./open-file-module-column.component";
import * as i10 from "../../../../services/double-click.service";
import * as i11 from "../../../../../services/generic-crud.service";
var styles_OpenFileModuleColumnComponent = ["@keyframes ui-progress-spinner-color {\n      100%,\n      0% {\n        stroke: #111;\n      }\n    }"];
var RenderType_OpenFileModuleColumnComponent = i0.ɵcrt({ encapsulation: 0, styles: styles_OpenFileModuleColumnComponent, data: {} });
export { RenderType_OpenFileModuleColumnComponent as RenderType_OpenFileModuleColumnComponent };
function View_OpenFileModuleColumnComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 5, "button", [["iconUrl", "https://demoapi.hr-puzzle.eu/assets/hr-puzzle/images/icons/image.png"], ["pButton", ""], ["tooltipPosition", "top"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.doOpenModule() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵprd(4608, null, i1.DomHandler, i1.DomHandler, []), i0.ɵdid(2, 4341760, null, 0, i2.ButtonDirective, [i0.ElementRef], null, null), i0.ɵdid(3, 4341760, null, 0, i3.Tooltip, [i0.ElementRef, i0.NgZone], { tooltipPosition: [0, "tooltipPosition"], text: [1, "text"] }, null), i0.ɵpid(131072, i4.TranslatePipe, [i4.TranslateService, i0.ChangeDetectorRef]), i0.ɵdid(5, 4341760, null, 0, i5.PButtonDirective, [i0.ElementRef], { iconUrl: [0, "iconUrl"] }, null)], function (_ck, _v) { var currVal_0 = "top"; var currVal_1 = i0.ɵinlineInterpolate(1, "", i0.ɵunv(_v, 3, 1, i0.ɵnov(_v, 4).transform("COMMON.OPEN_FILE")), ""); _ck(_v, 3, 0, currVal_0, currVal_1); var currVal_2 = "https://demoapi.hr-puzzle.eu/assets/hr-puzzle/images/icons/image.png"; _ck(_v, 5, 0, currVal_2); }, null); }
function View_OpenFileModuleColumnComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 5, "button", [["iconUrl", "https://demoapi.hr-puzzle.eu/assets/hr-puzzle/images/icons/page.png"], ["pButton", ""], ["tooltipPosition", "top"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.doOpenModule() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵprd(4608, null, i1.DomHandler, i1.DomHandler, []), i0.ɵdid(2, 4341760, null, 0, i2.ButtonDirective, [i0.ElementRef], null, null), i0.ɵdid(3, 4341760, null, 0, i3.Tooltip, [i0.ElementRef, i0.NgZone], { tooltipPosition: [0, "tooltipPosition"], text: [1, "text"] }, null), i0.ɵpid(131072, i4.TranslatePipe, [i4.TranslateService, i0.ChangeDetectorRef]), i0.ɵdid(5, 4341760, null, 0, i5.PButtonDirective, [i0.ElementRef], { iconUrl: [0, "iconUrl"] }, null)], function (_ck, _v) { var currVal_0 = "top"; var currVal_1 = i0.ɵinlineInterpolate(1, "", i0.ɵunv(_v, 3, 1, i0.ɵnov(_v, 4).transform("COMMON.OPEN_FILE")), ""); _ck(_v, 3, 0, currVal_0, currVal_1); var currVal_2 = "https://demoapi.hr-puzzle.eu/assets/hr-puzzle/images/icons/page.png"; _ck(_v, 5, 0, currVal_2); }, null); }
function View_OpenFileModuleColumnComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 5, "button", [["iconUrl", "https://demoapi.hr-puzzle.eu/assets/hr-puzzle/images/icons/arrow_down.png"], ["pButton", ""], ["tooltipPosition", "top"], ["type", "button"]], [[4, "marginLeft", "px"]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.doDownload() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵprd(4608, null, i1.DomHandler, i1.DomHandler, []), i0.ɵdid(2, 4341760, null, 0, i2.ButtonDirective, [i0.ElementRef], null, null), i0.ɵdid(3, 4341760, null, 0, i3.Tooltip, [i0.ElementRef, i0.NgZone], { tooltipPosition: [0, "tooltipPosition"], text: [1, "text"] }, null), i0.ɵpid(131072, i4.TranslatePipe, [i4.TranslateService, i0.ChangeDetectorRef]), i0.ɵdid(5, 4341760, null, 0, i5.PButtonDirective, [i0.ElementRef], { iconUrl: [0, "iconUrl"] }, null)], function (_ck, _v) { var currVal_1 = "top"; var currVal_2 = i0.ɵinlineInterpolate(1, "", i0.ɵunv(_v, 3, 1, i0.ɵnov(_v, 4).transform("COMMON.DOWNLOAD")), ""); _ck(_v, 3, 0, currVal_1, currVal_2); var currVal_3 = "https://demoapi.hr-puzzle.eu/assets/hr-puzzle/images/icons/arrow_down.png"; _ck(_v, 5, 0, currVal_3); }, function (_ck, _v) { var currVal_0 = 4; _ck(_v, 0, 0, currVal_0); }); }
function View_OpenFileModuleColumnComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "p-progressSpinner", [["fill", "#EEEEEE"], ["strokeWidth", "6"]], null, null, null, i6.View_ProgressSpinner_0, i6.RenderType_ProgressSpinner)), i0.ɵdid(1, 49152, null, 0, i7.ProgressSpinner, [], { style: [0, "style"], strokeWidth: [1, "strokeWidth"], fill: [2, "fill"] }, null), i0.ɵpod(2, { width: 0, height: 1 })], function (_ck, _v) { var currVal_0 = _ck(_v, 2, 0, "12px", "12px"); var currVal_1 = "6"; var currVal_2 = "#EEEEEE"; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); }, null); }
export function View_OpenFileModuleColumnComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 10, "table", [], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 9, "tr", [], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 8, "td", [], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_OpenFileModuleColumnComponent_1)), i0.ɵdid(4, 16384, null, 0, i8.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_OpenFileModuleColumnComponent_2)), i0.ɵdid(6, 16384, null, 0, i8.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_OpenFileModuleColumnComponent_3)), i0.ɵdid(8, 16384, null, 0, i8.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_OpenFileModuleColumnComponent_4)), i0.ɵdid(10, 16384, null, 0, i8.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.entity && _co.entity.id) && _co.entity.fileType.includes("image")); _ck(_v, 4, 0, currVal_0); var currVal_1 = ((_co.entity && _co.entity.id) && _co.entity.fileType.includes("pdf")); _ck(_v, 6, 0, currVal_1); var currVal_2 = (_co.entity && _co.entity.id); _ck(_v, 8, 0, currVal_2); var currVal_3 = _co.isLoading; _ck(_v, 10, 0, currVal_3); }, null); }
export function View_OpenFileModuleColumnComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-open-file-module-column", [], null, null, null, View_OpenFileModuleColumnComponent_0, RenderType_OpenFileModuleColumnComponent)), i0.ɵdid(1, 49152, null, 0, i9.OpenFileModuleColumnComponent, [i0.ChangeDetectorRef, i0.ElementRef, i10.DoubleClickService, i11.GenericCrudService], null, null)], null, null); }
var OpenFileModuleColumnComponentNgFactory = i0.ɵccf("app-open-file-module-column", i9.OpenFileModuleColumnComponent, View_OpenFileModuleColumnComponent_Host_0, { node: "node", entity: "entity", component: "component", column: "column" }, {}, []);
export { OpenFileModuleColumnComponentNgFactory as OpenFileModuleColumnComponentNgFactory };
