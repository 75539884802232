import {forkJoin, Observable, of} from 'rxjs';
import {map} from 'rxjs/operators';
import {TranslateService} from '@ngx-translate/core';
import {ChangeDetectorRef, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {GenericCrudService} from '../../services/generic-crud.service';
import {File} from '../../models/file';
import {MessageGrowlService} from '../../../core/message/message-growl.service';
import {environment} from '../../../../environments';
import {ChangeDetectorRefHelper} from '../../helpers/change-detector-ref.helper';
import {FileUpload} from 'primeng/fileupload';

export abstract class AbstractUploadComponent {

  @ViewChild(FileUpload, {static: false}) public fileUpload: FileUpload = null;
  @Output() public uploadSuccess = new EventEmitter<File[]>();
  @Output() public fileSelect = new EventEmitter<File>();
  @Input() disabled = false;
  @Input() display = 'inline';
  @Input() fileClassName: string;
  @Input() set entity(entity: any) {
    if (entity && entity.id && this.fileClassName) {
      this.fileEntity = entity;
      this.loadFiles().subscribe();
    }
  }
  @Input() fileContext = 'default';
  @Input() filePath = '';

  @Input() mode = 'basic';
  @Input() name = 'file';
  @Input() accept = '';
  @Input() url = '';
  @Input() fileLimit = 30;

  public fileEntity = null;
  public selectedFiles: any[] = [];
  public isLoading = true;

  public abstract loadFiles(): Observable<any>;

  protected constructor(
    public cdr: ChangeDetectorRef,
    protected genericCrudService: GenericCrudService,
    protected message: MessageGrowlService,
    protected translate: TranslateService
  ) {
  }

  public isDirty(): boolean {
    return this.selectedFiles.length > 0;
  }

  public isImage(file: File): boolean {
    return /^image\//.test(file.fileType);
  }

  public getDownloadUrl(file: File): string {
      return file ? environment.downloadUrl + '/' + file.contentUrl : '';
  }

  public onImageClick(file: File): void {
    this.fileSelect.emit(file);
  }

  public onItemDownload(menuItem: { file: File }): void {
    const link = document.createElement('a');
    link.download = menuItem.file.name;
    link.href = this.getDownloadUrl(menuItem.file);
    link.target = '_blank';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    event.preventDefault();
    event.stopPropagation();
  }

  public onItemRemove(menuItem: { file: File }): void {
    if (menuItem.file.id) {
      this.genericCrudService.deleteEntity(`phoenix/files/${menuItem.file.id}?fileClassName=${menuItem.file.fqn}`)
        .subscribe(() => {
          this.onItemRemoved(menuItem);
        });
    }

    if (!menuItem.file.id) {
      this.onItemRemoved(menuItem);
    }

    event.preventDefault();
    event.stopPropagation();
  }

  public upload(): Observable<any> {
    const observables = [];

    if (this.selectedFiles.length === 0) {
      console.error('No files set for upload!');
      return of(null);
    }

    let i = 0;
    for (const file of this.selectedFiles) {
      const fileEntity = {
        name: file.name,
        file: file,
        fileType: file.type,
        fileSize: file.size,
        uploadedFileName: file.uploadedFileName,
        ownerId: this.fileEntity ? this.fileEntity.id : undefined,
        ownerFqn: this.fileEntity ? this.fileEntity.fqn : undefined,
        fileClassName: this.fileClassName,
        fileContext: this.fileContext,
        filePath: this.filePath,
        isSingleUpload: this.fileLimit === 1
      }

      observables.push(this.genericCrudService.upload('phoenix/files/upload', fileEntity));
      i++;
    }

    return forkJoin(observables)
      .pipe(
        map((files: File[] = []) => {
          this.loadFiles().subscribe();
          this.fileUpload.clear();

          this.selectedFiles = [];

          this.message.info(this.translate.instant('FILE.FILE_UPLOADED'));

          this.uploadSuccess.emit(files);

          ChangeDetectorRefHelper.detectChanges(this);
        }));
  }

  protected onItemRemoved(menuItem: {file: File}) {

  }
}
