import {Observable, of} from 'rxjs';
import {AbstractExecutionStep} from '../../../core/executor/abstract-execution-step';
import {ExecutionStepStatus} from '../../../core/executor/execution-step-status';
import {GenericElementAbstract} from '../../content-renderer/elements/generic-element-abstract.component';
import {GenericDialogModuleService} from '../../content-renderer/elements/generic-dialog/service/generic-dialog-module.service';
import {ModulesStateService} from '../../content-renderer/services/modules-state.service';
import {AbstractGenericGridComponent} from '../../content-renderer/elements/abstract-generic-grid.component';

export class ToolbarItemOpenModuleUseMasterEntityExecutionStep extends AbstractExecutionStep {

  public doExecute(): Observable<ExecutionStepStatus> {
    const payload = this.getPayload(),
      component = payload.getValue().component ? payload.getValue().component : payload.getValue();

    if (!(component instanceof GenericElementAbstract)) {
      return this.getFailObservable('You need to pass GenericElementAbstract as Payload value!');
    }

    return this.doActivate(component);
  }

  doActivate(component: GenericElementAbstract): Observable<ExecutionStepStatus> {
    const toolbarItem = this.getParamValue('toolbarItem');
    let context = component.getElementContext().getMasterElementContext()

    if (!context) {
      const moduleState = this.injector.get(ModulesStateService).getByComponent(component);

      for (const aComponent of moduleState.components) {
        if (aComponent.getElementContext().isMaster) {
          context = aComponent.getElementContext();
          break;
        }
      }
    }

    this.genericCrudService.getEntities(`superadmin/modules/${+toolbarItem.actionModuleId}`).subscribe((targetModule) => {
      this.injector.get(GenericDialogModuleService).showDialog(targetModule,{
        height: +toolbarItem.targetDialogHeight,
        width: +toolbarItem.targetDialogWidth,
        isModal: toolbarItem.isTargetDialogModal,
        maximized: toolbarItem.isTargetDialogMaximized,
        entity: context.component.entity,
        isAutocompleteModuleState: false,
        masterElementContext: context,
        onClose: (closeDialogCallerComponent: GenericElementAbstract) => {

          if (component instanceof AbstractGenericGridComponent) {
            component.onForceRefresh().subscribe()
          }

          return true;
        }
      });
    })

    return of({status: true, content: null});
  }
}
