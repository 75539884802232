import * as i0 from "@angular/core";
import * as i1 from "../../../../services/generic-crud.service";
export class GenericFullCalendarEventsService {
    constructor(genericCrudService) {
        this.genericCrudService = genericCrudService;
    }
    getEvents() {
        return [];
    }
}
GenericFullCalendarEventsService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function GenericFullCalendarEventsService_Factory() { return new GenericFullCalendarEventsService(i0.ɵɵinject(i1.GenericCrudService)); }, token: GenericFullCalendarEventsService, providedIn: "root" });
