
      <app-multi-upload
        #upload
        [mode]="'advanced'"
        [disabled]="disabled"
        [filePath]="element.filePath"
        [fileContext]="element.fileContext"
        (fileSelect)="onFileSelect($event)"
        [tabIndex]="element.tabIndex"
      ></app-multi-upload>
    