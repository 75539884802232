/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./generic-pdf-viewer.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../../node_modules/@syncfusion/ej2-angular-navigations/ej2-angular-navigations.ngfactory";
import * as i3 from "@syncfusion/ej2-angular-navigations";
import * as i4 from "@angular/common";
import * as i5 from "../../../../../../node_modules/@syncfusion/ej2-angular-pdfviewer/ej2-angular-pdfviewer.ngfactory";
import * as i6 from "@syncfusion/ej2-angular-pdfviewer";
import * as i7 from "../../../../core/executor/executor.service";
import * as i8 from "../../../../core/executor/service/executor-actions/executor-actions.service";
import * as i9 from "../../services/generic/generic-element-validation-execution-steps-factory";
import * as i10 from "../../../../core/executor/factory/execution-step-factory.service";
import * as i11 from "./generic-pdf-viewer.component";
import * as i12 from "../../services/component-highlight-stack.service";
import * as i13 from "../../services/entity-data-store.service";
import * as i14 from "../../services/modules-state.service";
import * as i15 from "../../../validators/services/entity-validator";
import * as i16 from "../../../services/generic-crud.service";
import * as i17 from "../../../../core/service/user-session.service";
import * as i18 from "../../../services/permission/permission.service";
import * as i19 from "../../services/elements-stack.service";
import * as i20 from "@syncfusion/ej2-pdfviewer";
var styles_GenericPdfViewerComponent = [i0.styles];
var RenderType_GenericPdfViewerComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_GenericPdfViewerComponent, data: {} });
export { RenderType_GenericPdfViewerComponent as RenderType_GenericPdfViewerComponent };
function View_GenericPdfViewerComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "material"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "input", [["class", "e-input-group e-pv-current-page-number"], ["id", "currentPage"], ["type", "text"], ["value", "0"]], null, [[null, "keypress"], [null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("keypress" === en)) {
        var pd_0 = (_co.onCurrentPageBoxKeypress($event) !== false);
        ad = (pd_0 && ad);
    } if (("click" === en)) {
        var pd_1 = (_co.onCurrentPageBoxClicked($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "div", [["style", "margin-left: 6px"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "span", [["class", "e-pv-total-page-number"], ["id", "totalPage"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["of 0"]))], null, null); }
function View_GenericPdfViewerComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 22, "div", [["class", "e-sample-resize-container e-tbar-section"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 16777216, null, null, 21, "ejs-toolbar", [["id", "topToolbar"]], null, null, null, i2.View_ToolbarComponent_0, i2.RenderType_ToolbarComponent)), i1.ɵdid(2, 6537216, [[2, 4], ["customToolbar", 4]], 1, i3.ToolbarComponent, [i1.ElementRef, i1.Renderer2, i1.ViewContainerRef, i1.Injector], null, null), i1.ɵqud(335544320, 4, { childItems: 0 }), (_l()(), i1.ɵeld(4, 0, null, null, 18, "e-items", [], null, null, null, null, null)), i1.ɵdid(5, 7553024, [[4, 4]], 1, i3.ItemsDirective, [], null, null), i1.ɵqud(603979776, 5, { children: 1 }), (_l()(), i1.ɵeld(7, 16777216, null, null, 2, "e-item", [["align", "Center"], ["id", "previousPage"], ["prefixIcon", "e-pv-previous-page-navigation-icon"], ["tooltipText", "Previous Page"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.previousClicked($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(8, 15417344, [[5, 4]], 1, i3.ItemDirective, [i1.ViewContainerRef], { align: [0, "align"], id: [1, "id"], prefixIcon: [2, "prefixIcon"], tooltipText: [3, "tooltipText"] }, { click: "click" }), i1.ɵqud(335544320, 6, { template: 0 }), (_l()(), i1.ɵeld(10, 16777216, null, null, 2, "e-item", [["align", "Center"], ["id", "nextPage"], ["prefixIcon", "e-pv-next-page-navigation-icon"], ["tooltipText", "Next Page"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.nextClicked($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(11, 15417344, [[5, 4]], 1, i3.ItemDirective, [i1.ViewContainerRef], { align: [0, "align"], id: [1, "id"], prefixIcon: [2, "prefixIcon"], tooltipText: [3, "tooltipText"] }, { click: "click" }), i1.ɵqud(335544320, 7, { template: 0 }), (_l()(), i1.ɵeld(13, 16777216, null, null, 3, "e-item", [["align", "Center"]], null, null, null, null, null)), i1.ɵdid(14, 15417344, [[5, 4]], 1, i3.ItemDirective, [i1.ViewContainerRef], { align: [0, "align"] }, null), i1.ɵqud(335544320, 8, { template: 0 }), (_l()(), i1.ɵand(0, [[8, 2], ["template", 2]], null, 0, null, View_GenericPdfViewerComponent_2)), (_l()(), i1.ɵeld(17, 16777216, null, null, 2, "e-item", [["align", "Right"], ["prefixIcon", "e-pv-print-document-icon"], ["tooltipText", "Print"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.printClicked($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(18, 15417344, [[5, 4]], 1, i3.ItemDirective, [i1.ViewContainerRef], { align: [0, "align"], prefixIcon: [1, "prefixIcon"], tooltipText: [2, "tooltipText"] }, { click: "click" }), i1.ɵqud(335544320, 9, { template: 0 }), (_l()(), i1.ɵeld(20, 16777216, null, null, 2, "e-item", [["align", "Right"], ["prefixIcon", "e-pv-download-document-icon"], ["tooltipText", "Download"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.downloadClicked($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(21, 15417344, [[5, 4]], 1, i3.ItemDirective, [i1.ViewContainerRef], { align: [0, "align"], prefixIcon: [1, "prefixIcon"], tooltipText: [2, "tooltipText"] }, { click: "click" }), i1.ɵqud(335544320, 10, { template: 0 })], function (_ck, _v) { _ck(_v, 2, 0); _ck(_v, 5, 0); var currVal_0 = "Center"; var currVal_1 = "previousPage"; var currVal_2 = "e-pv-previous-page-navigation-icon"; var currVal_3 = "Previous Page"; _ck(_v, 8, 0, currVal_0, currVal_1, currVal_2, currVal_3); var currVal_4 = "Center"; var currVal_5 = "nextPage"; var currVal_6 = "e-pv-next-page-navigation-icon"; var currVal_7 = "Next Page"; _ck(_v, 11, 0, currVal_4, currVal_5, currVal_6, currVal_7); var currVal_8 = "Center"; _ck(_v, 14, 0, currVal_8); var currVal_9 = "Right"; var currVal_10 = "e-pv-print-document-icon"; var currVal_11 = "Print"; _ck(_v, 18, 0, currVal_9, currVal_10, currVal_11); var currVal_12 = "Right"; var currVal_13 = "e-pv-download-document-icon"; var currVal_14 = "Download"; _ck(_v, 21, 0, currVal_12, currVal_13, currVal_14); }, null); }
export function View_GenericPdfViewerComponent_0(_l) { return i1.ɵvid(2, [i1.ɵqud(671088640, 1, { gridContainer: 0 }), i1.ɵqud(671088640, 2, { customToolbar: 0 }), i1.ɵqud(671088640, 3, { pdfViewerComponent: 0 }), (_l()(), i1.ɵeld(3, 0, null, null, 4, "div", [["class", "content-wrapper"], ["style", "height: 100%"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_GenericPdfViewerComponent_1)), i1.ɵdid(5, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(6, 16777216, null, null, 1, "ejs-pdfviewer", [["id", "pdfViewer"]], null, [[null, "pageChange"], [null, "documentLoad"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("pageChange" === en)) {
        var pd_0 = (_co.pageChanged($event) !== false);
        ad = (pd_0 && ad);
    } if (("documentLoad" === en)) {
        var pd_1 = (_co.documentLoaded($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i5.View_PdfViewerComponent_0, i5.RenderType_PdfViewerComponent)), i1.ɵdid(7, 6537216, [[3, 4], ["pdfViewerComponent", 4]], 0, i6.PdfViewerComponent, [i1.ElementRef, i1.Renderer2, i1.ViewContainerRef, i1.Injector], { documentPath: [0, "documentPath"], enableHandwrittenSignature: [1, "enableHandwrittenSignature"], enableNavigationToolbar: [2, "enableNavigationToolbar"], enableToolbar: [3, "enableToolbar"], serviceUrl: [4, "serviceUrl"] }, { documentLoad: "documentLoad", pageChange: "pageChange" }), (_l()(), i1.ɵted(-1, null, ["`\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.pdfToolbar == "mini"); _ck(_v, 5, 0, currVal_0); var currVal_1 = _co.document; var currVal_2 = true; var currVal_3 = (_co.pdfToolbar == "full"); var currVal_4 = (_co.pdfToolbar == "full"); var currVal_5 = _co.service; _ck(_v, 7, 0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5); }, null); }
export function View_GenericPdfViewerComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 16777216, null, null, 13, "app-pdf-viewer", [], null, [[null, "mouseover"], [null, "mouseleave"]], function (_v, en, $event) { var ad = true; if (("mouseover" === en)) {
        var pd_0 = (i1.ɵnov(_v, 3).onMouseOver($event) !== false);
        ad = (pd_0 && ad);
    } if (("mouseleave" === en)) {
        var pd_1 = (i1.ɵnov(_v, 3).onMouseLeave($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, View_GenericPdfViewerComponent_0, RenderType_GenericPdfViewerComponent)), i1.ɵprd(512, null, i7.ExecutorService, i7.ExecutorService, [i8.ExecutorActionsService]), i1.ɵprd(512, null, i9.GenericElementValidationExecutionStepsFactory, i9.GenericElementValidationExecutionStepsFactory, [i10.ExecutionStepFactoryService]), i1.ɵdid(3, 245760, null, 0, i11.GenericPdfViewerComponent, [i12.ComponentService, i1.ViewContainerRef, i13.EntityDataStoreService, i14.ModulesStateService, i7.ExecutorService, i9.GenericElementValidationExecutionStepsFactory, i15.EntityValidator, i16.GenericCrudService, i17.UserSessionService, i18.PermissionService, i1.ElementRef, i1.ChangeDetectorRef, i19.ElementsStackService], null, null), i1.ɵprd(256, null, "PdfViewerLinkAnnotation", i20.LinkAnnotation, []), i1.ɵprd(256, null, "PdfViewerBookmarkView", i20.BookmarkView, []), i1.ɵprd(256, null, "PdfViewerMagnification", i20.Magnification, []), i1.ɵprd(256, null, "PdfViewerThumbnailView", i20.ThumbnailView, []), i1.ɵprd(256, null, "PdfViewerToolbar", i20.Toolbar, []), i1.ɵprd(256, null, "PdfViewerNavigation", i20.Navigation, []), i1.ɵprd(256, null, "PdfViewerAnnotation", i20.Annotation, []), i1.ɵprd(256, null, "PdfViewerTextSearch", i20.TextSearch, []), i1.ɵprd(256, null, "PdfViewerTextSelection", i20.TextSelection, []), i1.ɵprd(256, null, "PdfViewerPrint", i20.Print, [])], function (_ck, _v) { _ck(_v, 3, 0); }, null); }
var GenericPdfViewerComponentNgFactory = i1.ɵccf("app-pdf-viewer", i11.GenericPdfViewerComponent, View_GenericPdfViewerComponent_Host_0, { masterElementContext: "masterElementContext", moduleElement: "moduleElement", element: "element", parentComponent: "parentComponent", isDialog: "isDialog", isPart: "isPart", toolbarItems: "toolbarItems", statusBarItems: "statusBarItems", selectedMasterEntity: "selectedMasterEntity", additional: "additional", pdfToolbar: "pdfToolbar", entity: "entity" }, { componentInstantiated: "componentInstantiated" }, []);
export { GenericPdfViewerComponentNgFactory as GenericPdfViewerComponentNgFactory };
