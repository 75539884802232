import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component, ElementRef,
  Input, ViewChild,
  ViewContainerRef
} from '@angular/core';
import {ComponentService} from '../../services/component-highlight-stack.service';
import {ModulesStateService} from 'app/shared/content-renderer/services/modules-state.service';
import {EntityDataChangeMeta, EntityDataStoreService} from '../../services/entity-data-store.service';
import {GenericCrudService} from '../../../services/generic-crud.service';
import {PermissionService} from '../../../services/permission/permission.service';
import {ExecutorService} from 'app/core/executor/executor.service';
// tslint:disable-next-line:max-line-length
import {GenericElementValidationExecutionStepsFactory} from 'app/shared/content-renderer/services/generic/generic-element-validation-execution-steps-factory';
import {EntityValidator, EntityValidatorStatus} from '../../../validators/services/entity-validator';
import {Element} from '../../../services/element/element';
import {ModuleElement} from '../../../services/module/module-element';
import {UserSessionService} from '../../../../core/service/user-session.service';
import {ElementSaveStatus, GenericElementAbstract} from '../generic-element-abstract.component';
import {Observable, of as observableOf} from 'rxjs';
import {ElementType} from '../../services/ElementContext';
import {ElementsStackService} from '../../services/elements-stack.service';
import { ToolbarComponent } from '@syncfusion/ej2-angular-navigations';
import { ClickEventArgs } from '@syncfusion/ej2-buttons';

import {
  AnnotationService,
  BookmarkViewService,
  LinkAnnotationService,
  MagnificationService,
  PageChangeEventArgs, LoadEventArgs,
  NavigationService, PdfViewerComponent, PrintService, TextSearchService, TextSelectionService,
  ThumbnailViewService, ToolbarService
} from '@syncfusion/ej2-angular-pdfviewer';

@Component({
  selector: 'app-pdf-viewer',
  styleUrls: ['./generic-pdf-viewer.component.scss'],
  templateUrl: './generic-pdf-viewer.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    LinkAnnotationService, BookmarkViewService, MagnificationService,
    ThumbnailViewService, ToolbarService, NavigationService, AnnotationService, TextSearchService, TextSelectionService, PrintService,
    GenericElementValidationExecutionStepsFactory,
    ExecutorService
  ]
})
export class GenericPdfViewerComponent extends GenericElementAbstract {

  @Input() element: Element;
  @Input() moduleElement: ModuleElement;
  @Input() pdfToolbar: string;

  @ViewChild('gridContainer', {static: false}) set gridContainer(container: ElementRef) {
    this._container = container;

    if (this._container && this._container.nativeElement) {
    }
  }
  @ViewChild('customToolbar', {static: false}) protected customToolbar: ToolbarComponent;
  @ViewChild('pdfViewerComponent', {static: false}) protected pdfViewerComponent: PdfViewerComponent;

  public _container: ElementRef = null;

  protected toolbarContextName = 'genericPdfViewer';
  public elementType: ElementType = ElementType.PdfViewer;
  public service = '/pdfviewer/api/pdfviewer';
  public document = null;

  @Input()
  get entity() {
    return this.entityObj;
  }

  set entity(entity) {
    this.entityObj = entity;
    if (this.entityObj && this.entityObj.contentUrl) {
      this.document = this.entityObj.contentUrl;
    } else {
      this.document = null;
    }
    this.cdr.detectChanges();
  }

  public src: any;
  private entityObj: any;

  constructor(
    protected componentService: ComponentService,
    protected viewContainerRef: ViewContainerRef,
    protected entityDataStore: EntityDataStoreService,
    protected modulesStateService: ModulesStateService,
    protected executorService: ExecutorService,
    protected genericElementValidationExecutionStepsFactory: GenericElementValidationExecutionStepsFactory,
    protected entityValidator: EntityValidator,
    protected genericCrudService: GenericCrudService,
    protected userSession: UserSessionService,
    protected permissionService: PermissionService,
    protected elementRef: ElementRef,
    public cdr: ChangeDetectorRef,
    protected elementsStackService: ElementsStackService
  ) {
    super(componentService, viewContainerRef, entityDataStore, modulesStateService,
      executorService, genericElementValidationExecutionStepsFactory,
      entityValidator, genericCrudService,
      userSession, permissionService, cdr
    );
  }

  onAfterSave(): Observable<any> {
    return observableOf(null);
  }

  onChange(changeMeta: EntityDataChangeMeta): Observable<any> {
    return observableOf(null);
  }

  public ngOnInit() {
    super.ngOnInit();
    this.elementContext = this.elementsStackService.createContext(this);
    this.elementsStackService.remove(this.elementContext).add(this.elementContext);
  }

  public ngOnDestroy() {
    super.ngOnDestroy();
  }

  doValidate(): Observable<EntityValidatorStatus> {
    return observableOf(null);
  }

  getSelectedEntity(): any {
  }

  hasChanges(checkEmbedded: boolean): boolean {
    return false;
  }

  onRefresh(): Observable<any> {
    return observableOf(null);
  }

  onSave(): Observable<ElementSaveStatus> {
    return observableOf(null);
  }

  recheckToolbarItems(): void {
  }

  public previousClicked(e: ClickEventArgs): void {
    this.pdfViewerComponent.navigation.goToPreviousPage();
  }

  public nextClicked(e: ClickEventArgs): void {
    this.pdfViewerComponent.navigation.goToNextPage();
  }

  public printClicked(e: ClickEventArgs): void {
    this.pdfViewerComponent.print.print();
  }

  public onCurrentPageBoxClicked(e: ClickEventArgs): void {
    (document.getElementById('currentPage') as HTMLInputElement).select();
  }

  public downloadClicked(e: ClickEventArgs): void {
    const fileName: string = (document.getElementById('fileUpload') as HTMLInputElement).value.split('\\').pop();
    if (fileName !== '') {
      this.pdfViewerComponent.fileName = fileName;
    }
    this.pdfViewerComponent.download();
  }

  public onCurrentPageBoxKeypress(e: KeyboardEvent): boolean {
    if ((e.which < 48 || e.which > 57) && e.which !== 8 && e.which !== 13) {
      e.preventDefault();
      return false;
    } else {
      // tslint:disable-next-line:radix
      const currentPageNumber: number = parseInt((document.getElementById('currentPage') as HTMLInputElement).value);
      if (e.which === 13) {
        if (currentPageNumber > 0 && currentPageNumber <= this.pdfViewerComponent.pageCount) {
          this.pdfViewerComponent.navigation.goToPage(currentPageNumber);
        } else {
          // tslint:disable-next-line:max-line-length
          (document.getElementById('currentPage') as HTMLInputElement).value = this.pdfViewerComponent.currentPageNumber.toString();
        }
      }
      return true;
    }
  }

  public pageChanged(e: PageChangeEventArgs): void {
    (document.getElementById('currentPage') as HTMLInputElement).value = this.pdfViewerComponent.currentPageNumber.toString();
    this.updatePageNavigation();
  }

  public documentLoaded(e: LoadEventArgs): void {
    document.getElementById('totalPage').textContent = 'of ' + this.pdfViewerComponent.pageCount;
    (document.getElementById('currentPage') as HTMLInputElement).value = this.pdfViewerComponent.currentPageNumber.toString();
    this.updatePageNavigation();
  }

  private updatePageNavigation(): void {
    if (this.pdfViewerComponent.currentPageNumber === 1) {
      this.customToolbar.enableItems(document.getElementById('previousPage'), false);
      this.customToolbar.enableItems(document.getElementById('nextPage'), true);
    } else if (this.pdfViewerComponent.currentPageNumber === this.pdfViewerComponent.pageCount) {
      this.customToolbar.enableItems(document.getElementById('previousPage'), true);
      this.customToolbar.enableItems(document.getElementById('nextPage'), false);
    } else {
      this.customToolbar.enableItems(document.getElementById('previousPage'), true);
      this.customToolbar.enableItems(document.getElementById('nextPage'), true);
    }
  }
}
