import {
  Component,
  OnInit,
  Input,
  OnDestroy,
  HostListener,
  ViewChild,
  ElementRef,
  ChangeDetectionStrategy,
  ChangeDetectorRef
} from '@angular/core';
import { Location } from '@angular/common';
import { Element } from '../../services/element/element';
import { ModuleElement } from '../../services/module/module-element';
import { ModuleElementSelectionCommunicationService } from '../services/module.element.selection.communication.service';
import { GenericElementAbstract } from '../elements/generic-element-abstract.component';
import {Observable, of, Subject, Subscription} from 'rxjs';
import { ComponentService } from '../services/component-highlight-stack.service';
import { LayoutService } from '../../services/layout-service';
import { LocationService } from '../../services/location.service';
import { ModuleElementPart } from '../../services/module/module-element-part';
import { ModuleElementPartCrudService } from '../../services/module/module-element-part.crud.service';
import { ModulesStateService } from '../services/modules-state.service';
import {ModuleState, ModuleStateContext} from 'app/shared/content-renderer/services/module-state';
import {ElementContext} from 'app/shared/content-renderer/services/ElementContext';
import {ActivatedRoute, Router} from '@angular/router';
import {ElementsSubscriptionService} from '../services/elements-subscription.service';
import {filter, map, takeUntil} from 'rxjs/operators';
import {ChangeDetectorRefHelper} from '../../helpers/change-detector-ref.helper';
import {ModuleNavigationService} from '../services/navigation/module-navigation.service';
import {ExecutorActionEvent} from '../../../core/executor/service/executor-actions/executor-action-event';
import {ExecutionStatus} from '../../../core/executor/execution-status';
import {ExecutionStatusError} from '../../../core/executor/execution-status-error';
import {FalseExecutionStep} from '../../services/execution-step/tests/false-execution-step';
import {ExecutorService} from '../../../core/executor/executor.service';
import {ExecutorActionsService} from '../../../core/executor/service/executor-actions/executor-actions.service';
import {TabState} from '../services/tab-state';
import {GenericCrudService} from '../../services/generic-crud.service';
import {AuthenticationService} from '../../../core/authentication/authentication.service';
import {UserSessionService} from '../../../core/service/user-session.service';
import {Branch} from '../../services/branch/branch';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-split-template',
  templateUrl: './split-template.component.html',
  styleUrls: ['./split-template.component.scss'],
  providers: [
    ExecutorService
  ]
})
export class SplitTemplateComponent implements OnInit, OnDestroy {
  @ViewChild('partContainer', {static: false}) set partContainer(partContainer: ElementRef) {
    this._partContainer = partContainer;

    this.setPartContainerHeightAndWidth();
  }

  @Input() set entity(entity: any) {
    this.selectedEntity = entity;
  }
  @Input() moduleElement: ModuleElement;
  @Input() isPreview ?= false;
  @Input() masterElementContext: ElementContext = null;
  @Input() masterField?: string;
  @Input() masterEntity?: any;
  @Input() additional?: any;
  @Input() masterEntityEditingField?: string;
  @Input() isPart = false;
  @Input() isDialog = false;
  @Input() masterFilterField?: string;
  @Input() masterFilterValue?: string;
  @Input() justAFilter?: any;

  protected _partContainer: ElementRef;

  public partContainerHeight = 10;
  public partContainerWidth = 10;
  public moduleElementParts: ModuleElementPart[] = [];
  public unsubscribe = new Subject<void>();

  public part: any = null;

  public genericComponent: GenericElementAbstract;
  public parentComponent: GenericElementAbstract;
  public selectedEntity: any = null;

  @HostListener('window:popstate', ['$event'])
  onPopState(event) {
    // this.handleParts();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.layoutService.onLayoutSizeChanged();
  }

  constructor(
    private moduleElementSelectionService: ModuleElementSelectionCommunicationService,
    private componentService: ComponentService,
    private layoutService: LayoutService,
    private locationService: LocationService,
    private elementRef: ElementRef,
    private moduleElementPartCrudService: ModuleElementPartCrudService,
    private modulesStateService: ModulesStateService,
    private route: ActivatedRoute,
    private router: Router,
    private location: Location,
    private elementsSubscriptionService: ElementsSubscriptionService,
    private moduleNavigation: ModuleNavigationService,
    private executorService: ExecutorService,
    private executorActionsService: ExecutorActionsService,
    public authenticationService: AuthenticationService,
    public cdr: ChangeDetectorRef,
    public genericCrudService: GenericCrudService,
    private userSession: UserSessionService
  ) {

  }

  ngOnInit() {
    if (this.moduleElement) {
      this.executorActionsService
        .registerModuleElementActions(this.moduleElement)
        .subscribe();

      this.moduleElementPartCrudService
        .getActiveParts(this.moduleElement.id)
        .debounceTime(100)
        .switchMap((parts: ModuleElementPart[]) => {
          this.moduleElementParts = parts.filter(moduleElementPart => {
            return moduleElementPart.isGranted && moduleElementPart.isGranted['view'];
          });

          return this.executeAction(ExecutorActionEvent.BeforeActivePartsLoad, this)
        })
        .switchMap((status: ExecutionStatus) => {
          return this.route.params
            .pipe(
              map((params) => {
                return params;
              })
            )
        })
        .pipe(
          takeUntil(this.unsubscribe),
        )
        .subscribe((params: any) => {
          this.handleParts(params);
        })

      this.modulesStateService.badgeStateChanged$.pipe(
        takeUntil(this.unsubscribe),
      ).subscribe((tabState: TabState) => {
        for (const moduleElementPart of this.moduleElementParts) {
          if (moduleElementPart._embedded && moduleElementPart._embedded.module && moduleElementPart._embedded.module.id === tabState.moduleId) {
            moduleElementPart.badgeCount = tabState.count;
          }
        }
      })
    }

    this.layoutService.layoutSizeChanged$
      .pipe(
        takeUntil(this.unsubscribe),
      )
      .subscribe(() => {
        this.setPartContainerHeightAndWidth();
      })
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  public onSplitLayoutChanged(event) {
    this.layoutService.onLayoutSizeChanged();
  }

  protected handleParts(params): void {
    const mainModuleState = this.modulesStateService.getCurrent();

    if (this.hasParts()) {
      let part = this.moduleElementParts[0];
      let partModuleId = params ? params.partModuleId : null;

      if (!partModuleId && this.locationService.hasParam('part-module')) {
        partModuleId = this.locationService.getParam('part-module');
      }

      if (partModuleId && this.partExists(+partModuleId)) {
        part = this.moduleElementParts.find((modulePart) => {
          return modulePart && modulePart.module && modulePart.module.id === +partModuleId;
        });
      }

      if (part && part.module && part.module.id) {
        this.onPartTabChange(part);
      }

      for (const moduleElementPart of this.moduleElementParts) {
        if (moduleElementPart.showBadge && moduleElementPart.badgeApi) {
          this.genericCrudService.get(this.parseApiParameters(moduleElementPart.badgeApi), {onlyCount: 1})
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(items => {
              moduleElementPart.badgeCount = items.total;
            });
        }
      }
    } else if (!mainModuleState.isDialog) {
      this.moduleNavigation.getModuleRouter().replaceState(mainModuleState.url);
    }
  }

  public partExists(partModuleId: number): boolean {
    return this.moduleElementParts.findIndex((modulePart) => { return modulePart.module.id === partModuleId; }) !== -1;
  }

  public leftToolbarExists(element: Element) {
    return element.leftToolbarItems && element.leftToolbarItems.length > 0;
  }

  public rightToolbarExists(element: Element) {
    return element.rightToolbarItems && element.rightToolbarItems.length > 0;
  }

  public onGenericComponentInstantiated(genericComponent) {
    this.genericComponent = genericComponent;
  }

  public getToolbarExtraParams() {
    const extraParams = {};

    if (this.genericComponent) {
      extraParams[this.genericComponent.getToolbarContextName()] = this.genericComponent;
    }

    return extraParams;
  }

  public onPartTabChange(part) {
    const mainModuleState = this.modulesStateService.getCurrent();

    if (mainModuleState && part.module) {
      mainModuleState.addPart(new ModuleState(part.module.id, part.module, '', {}, false, this.entity, true));
    }

    this.part = part;

    this.elementsSubscriptionService.destroyPartsByCurrentModule().subscribe();

    this.replaceRoute(part);
  }

  private parseApiParameters(api: string): string {
    let parsedApi = api;

    if (api.indexOf('__CURENT_USER__')) {
      parsedApi = parsedApi.replace('__CURENT_USER__', this.authenticationService.currentUser.id.toString());
    }

    if (api.indexOf('__CURRENT_BRANCH_OFFICE_ID__') && this.userSession.get(Branch.LOCAL_STORAGE_NAME).id) {
      parsedApi = parsedApi.replace('__CURRENT_BRANCH_OFFICE_ID__', this.userSession.get(Branch.LOCAL_STORAGE_NAME).id.toString());
    }

    return parsedApi;
  }

  private hasParts() {
    return this.moduleElementParts && this.moduleElementParts.length > 0;
  }

  private setPartContainerHeightAndWidth() {
    if (this._partContainer) {
      this.partContainerHeight = this.elementRef.nativeElement.clientHeight;
      this.partContainerWidth = this.elementRef.nativeElement.clientWidth;
    }

    ChangeDetectorRefHelper.detectChanges(this);
  }

  private replaceRoute(part): void {
    const mainModuleState = this.modulesStateService.getCurrent();

    mainModuleState.url = this.moduleNavigation.getModuleRouter().replaceRoutePart(part.module);

    ChangeDetectorRefHelper.detectChanges(this);
  }

  private executeAction(actionEvent: ExecutorActionEvent, payload: any): Observable<ExecutionStatus> {

    if (!this.moduleElement) {
      return of(new ExecutionStatusError({status: false, content: 'ModuleElement not found!'}, new FalseExecutionStep()));
    }

    return this.executorService
      .fire(this.moduleElement.id, actionEvent, payload).pipe(
        map((status: ExecutionStatus) => {
          return status;
        }));
  }
}
