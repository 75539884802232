/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../components/upload/multi-upload/multi-upload.component.ngfactory";
import * as i2 from "../../components/upload/multi-upload/multi-upload.component";
import * as i3 from "../../services/generic-crud.service";
import * as i4 from "../../../core/message/message-growl.service";
import * as i5 from "@ngx-translate/core";
import * as i6 from "./element-input-file-upload.component";
import * as i7 from "../form.service";
import * as i8 from "../form-viewer.service";
var styles_ElementInputFileUploadComponent = ["[_nghost-%COMP%] {\n          height: 100%;\n          width: 100%;\n        }"];
var RenderType_ElementInputFileUploadComponent = i0.ɵcrt({ encapsulation: 0, styles: styles_ElementInputFileUploadComponent, data: {} });
export { RenderType_ElementInputFileUploadComponent as RenderType_ElementInputFileUploadComponent };
export function View_ElementInputFileUploadComponent_0(_l) { return i0.ɵvid(2, [i0.ɵqud(671088640, 1, { inputElement: 0 }), i0.ɵqud(671088640, 2, { upload: 0 }), (_l()(), i0.ɵeld(2, 0, null, null, 1, "app-multi-upload", [], [[8, "tabIndex", 0]], [[null, "fileSelect"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("fileSelect" === en)) {
        var pd_0 = (_co.onFileSelect($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i1.View_MultiUploadComponent_0, i1.RenderType_MultiUploadComponent)), i0.ɵdid(3, 49152, [[2, 4], ["upload", 4]], 0, i2.MultiUploadComponent, [i0.ChangeDetectorRef, i3.GenericCrudService, i4.MessageGrowlService, i5.TranslateService], { disabled: [0, "disabled"], fileContext: [1, "fileContext"], filePath: [2, "filePath"], mode: [3, "mode"], fileLimit: [4, "fileLimit"] }, { fileSelect: "fileSelect" })], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.disabled; var currVal_2 = _co.element.fileContext; var currVal_3 = _co.element.filePath; var currVal_4 = "advanced"; var currVal_5 = 1; _ck(_v, 3, 0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.element.tabIndex; _ck(_v, 2, 0, currVal_0); }); }
export function View_ElementInputFileUploadComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "app-form-element-input-file-upload", [], null, null, null, View_ElementInputFileUploadComponent_0, RenderType_ElementInputFileUploadComponent)), i0.ɵdid(1, 6012928, null, 1, i6.ElementInputFileUploadComponent, [i7.FormService, i0.ChangeDetectorRef, i8.FormViewerService, i3.GenericCrudService, i0.ElementRef, i5.TranslateService], null, null), i0.ɵqud(603979776, 1, { insideNgContent: 0 })], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ElementInputFileUploadComponentNgFactory = i0.ɵccf("app-form-element-input-file-upload", i6.ElementInputFileUploadComponent, View_ElementInputFileUploadComponent_Host_0, { form: "form", formGroup: "formGroup", entity: "entity", element: "element", moduleElement: "moduleElement", selectedElement: "selectedElement", showDialogAddAddress: "showDialogAddAddress", actionParams: "actionParams", editMode: "editMode" }, { selectedElementChange: "selectedElementChange", showDialogAddAddressChange: "showDialogAddAddressChange", actionParamsChange: "actionParamsChange" }, []);
export { ElementInputFileUploadComponentNgFactory as ElementInputFileUploadComponentNgFactory };
