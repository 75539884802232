import {of as observableOf, Observable} from 'rxjs';
import {Component, Input} from '@angular/core';
import {ExecutorService} from '../../../../../core/executor/executor.service';
import {ElementSaveStatus} from '../../generic-element-abstract.component';
import {FieldMetadataGrid} from '../../../../services/module/module-element-field-metadata-grid';
import {ModuleElement} from '../../../../services/module/module-element';
import {Element} from '../../../../services/element/element';
import {GenericElementValidationExecutionStepsFactory} from '../../../services/generic/generic-element-validation-execution-steps-factory';
import {GenericElementFilterService} from '../../../services/generic/filter/generic-element-filter.service';
import {EntityStatus} from '../../../../services/entity/entity-status';
import {ChangeDetectorRefHelper} from '../../../../helpers/change-detector-ref.helper';
import {
  MEMO_EMBEDDED_ENTITIES,
  MemoFieldDefinitionValueComponent, MemoTypeFieldDefinition, MemoTypeFieldDefinitionType
} from '../memo-field-definition-value/memo-field-definition-value.component';
import {map, takeUntil} from 'rxjs/operators';
import {Guid} from 'guid-typescript';
import {ElementContext, ElementType} from '../../../services/ElementContext';
import {AbstractGenericGridComponent} from '../../abstract-generic-grid.component';

@Component({
  selector: 'app-custom-memo-field-dms-search',
  styleUrls: ['../memo-field-definition-value/memo-field-definition-value.component.scss'],
  templateUrl: '../memo-field-definition-value/memo-field-definition-value.component.html',
  providers: [
    ExecutorService,
    GenericElementValidationExecutionStepsFactory,
    GenericElementFilterService
  ]
})
export class MemoFieldDmsSearchComponent extends MemoFieldDefinitionValueComponent {
  @Input() element: Element;
  @Input() fields: Array<FieldMetadataGrid>;
  @Input() toolbarItems: any[] = [];
  @Input() statusBarItems: any[] = [];
  @Input() moduleElement: ModuleElement;
  @Input() masterEntity: any = null;
  @Input() masterField: any = null;
  @Input() isPart = false;

  public configuration = {
    ownerApi: 'dms/dmsfiles',
    fieldDefinitionValuesApi: 'dms/memofielddefinitionvalues',
    memoTypeFieldDefinitionsApi: 'dms/memotypefielddefinitions',
    memoTypeApi: 'dms/memotypes',
    memoTypeDefinedOutside: false,
    memoTypeRelation: 'memoType',
    embeddedEntities: MEMO_EMBEDDED_ENTITIES,
    usesExternalRefresh: false,
    search: true,
  }

  public ngOnInit() {
    super.ngOnInit();

    this.onRefresh()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe();
  }

  public onMemoTypeChanged(memoType: unknown) {
    this.memoType = memoType;
    this.onRefresh()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe();
  }

  public onSearchChange(event: any): void {
    if (this.selectedSearch === 'all') {
      this.memoType = null;
    }

    const component = this.getGridComponent();

    if (component instanceof AbstractGenericGridComponent) {
      component.clearEntities();
    }

    this.onRefresh()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe();
  }

  public onSearch() {
    const component = this.getGridComponent(),
      memoTypeFieldDefinitions = this.visibleMemoTypeFieldDefinitions
      .filter((aMemoTypeFieldDefinition) => aMemoTypeFieldDefinition.searchValue);

    if (component instanceof AbstractGenericGridComponent) {
      if (!component.embeddedFields.includes('fieldDefinitionValues')) {
        component.embeddedFields.push('fieldDefinitionValues');
      }
      component.staticFilters = [];
      if (memoTypeFieldDefinitions.length > 0) {
        const filterValues = [];
        for (const memoTypeFieldDefinition of memoTypeFieldDefinitions) {
          filterValues.push({
            value: memoTypeFieldDefinition.searchValue,
            fieldDefinition: memoTypeFieldDefinition.id
          })
        }
        component.staticFilters.push({
          field: `fieldDefinitionValues.id`,
          value: `dmsFieldDefinitionValue:${JSON.stringify(filterValues)}`
        });
      }

      component.loadEntities()
        .pipe(
          takeUntil(component.unsubscribe)
        )
        .subscribe()
    }
  }

  public onFieldDefinitionValueEdit(event, entity: MemoTypeFieldDefinition): void {
    const dataType = entity.fieldDefinitionTemplate.dataType.code;

    let value = null;

    switch (dataType) {
      case MemoTypeFieldDefinitionType.CODE_DROPDOWN:
        value = event.value;
        break;
      case MemoTypeFieldDefinitionType.CODE_DATETIME:
      case MemoTypeFieldDefinitionType.CODE_CHECKBOX:
        value = event;
        break;
      case MemoTypeFieldDefinitionType.CODE_TEXT:
        value = event.target.value;
        break;
    }

    entity.searchValue = value;
  }

  public onRefresh(): Observable<any> {
    this.isLoadingData = true;

    return this.loadMemoTypeFieldDefinitions(this.memoType)
      .pipe(
        map((response) => {
          this.visibleMemoTypeFieldDefinitions = response;

          this.isLoadingData = false;
          this.toolbarItemCheckService.check(this);

          ChangeDetectorRefHelper.detectChanges(this);

          return {status: true, content: null};
        })
      )
  }

  public getSelectedEntity(): any {
    return this.selectedMasterEntity || null;
  }

  public recheckToolbarItems(): void {
    this.toolbarItemCheckService.check(null);
  }

  public onSave(): Observable<ElementSaveStatus> {
    return observableOf(null);
  }

  private getGridComponent() {
    const context: ElementContext = this.elementsStackService.findByComponentDatamodelAndType('DmsBundle\\Entity\\DmsFile', ElementType.Grid);

    return context.component;
  }
}
