<ng-container *ngIf="!api">
  <div class="error-container">
    <div class="error-text">
      <div class="error-message">
        <b><span class="text-red">ERROR</span></b>
      </div>
      <div class="error-detail">
        <span>Modul View is not defined!</span>
      </div>
    </div>
  </div>
</ng-container>

<div class="ui-g" *ngIf="api">
  <div class="ui-g-1">
    <label for="view">View</label>
  </div>
  <div class="ui-g-2">
    <app-generic-dropdown
      id="view"
      [api]="api"
      (optionChanged)="onViewChange($event)"
    ></app-generic-dropdown>
  </div>

  <div class="ui-g-9"></div>

  <div class="ui-g-12">
    <shared-dynamic-table
      [columns]="columns"
      [rows]="[]"
      [totalCount]="totalCount"
      [rowsCount]="9999"
      [entities]="entities"
      [selectFirstEntityWhenNothingIsSelected]="moduleElement.selectFirst"
      [showFilters]="false"
      [paginator]="false"
    ></shared-dynamic-table>
  </div>
</div>
