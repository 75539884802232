<div class="ui-grid ui-grid-responsive ui-fluid">
  <p-fileUpload
    chooseLabel="{{ 'FILE.CHOOSE' | translate }}"
    uploadLabel="{{ 'FILE.UPLOAD' | translate }}"
    cancelLabel="{{ 'FILE.CANCEL' | translate }}"
    invalidFileLimitMessageDetail="{{ 'FILE.LIMIT_MESSAGE_DETAIL' | translate }}"
    invalidFileLimitMessageSummary="{{ 'FILE.LIMIT_MESSAGE_SUMMARY' | translate }}"
    mode="{{mode}}"
    name="{{name}}"
    url="{{url}}"
    accept="{{accept}}"
    multiple="true"
    maxFileSize="1000000000000"
    customUpload="true"
    [fileLimit]="fileLimit"
    (onClear)="onClear()"
    (uploadHandler)="onUpload($event)"
    (onSelect)="onSelect($event)"
  >
    <ng-template pTemplate="toolbar">
      <div *ngIf="filePath" [style.marginTop.px]="10">Path: {{ filePath }}</div>
    </ng-template>
    <ng-template let-i="index" let-file pTemplate="file">
      <div class="ui-g">
        <div class="ui-g-4">
          <img alt="{{file.name}}" [src]="file.objectURL" *ngIf="fileUpload.isImage(file)" [width]="100"/>
        </div>
        <div class="ui-g-5">
          {{ file.name }}
        </div>
        <div class="ui-g-2">
          {{ fileUpload.formatSize(file.size) }}
        </div>
        <div class="ui-g-1">
          <button type="button" icon="pi pi-times" pButton (click)="fileUpload.remove($event,i)"
                  [disabled]="fileUpload.uploading"></button>
        </div>
      </div>
    </ng-template>
<!--    <ng-template pTemplate="content">-->
<!--      <ng-template [ngIf]="!isLoading">-->
<!--        <div *ngFor="let item of items" class="ui-g">-->
<!--          <div class="ui-g-4">-->
<!--            <img alt="{{item.file.name}}" (click)="onImageClick(item.file)" [src]="getDownloadUrl(item.file)" *ngIf="isImage(item.file)" [width]="100"/>-->
<!--          </div>-->
<!--          <div class="ui-g-4">-->
<!--            {{ item.file.name }}-->
<!--          </div>-->
<!--          <div class="ui-g-2">-->
<!--            {{ fileUpload ? fileUpload.formatSize(item.file.fileSize) : '' }}-->
<!--          </div>-->
<!--          <div class="ui-g-1">-->
<!--            <button type="button" icon="pi pi-download" pButton (click)="onItemDownload(item)"></button>-->
<!--          </div>-->
<!--          <div class="ui-g-1">-->
<!--            <button type="button" icon="pi pi-times" pButton (click)="onItemRemove(item)"></button>-->
<!--          </div>-->
<!--        </div>-->
<!--      </ng-template>-->
<!--      <ng-template [ngIf]="isLoading">-->
<!--        <app-generic-loading></app-generic-loading>-->
<!--      </ng-template>-->
<!--    </ng-template>-->
  </p-fileUpload>
</div>


<p-dialog
  *ngIf="isUploadVisible"
  [(visible)]="isUploadVisible"
  [modal]="true"
  [style]="{width: '40vw'}"
  [maximizable]="false"
  [baseZIndex]="10000"
  [draggable]="false"
  [resizable]="false"
>
  <p-fileUpload
    chooseLabel="{{ 'FILE.CHOOSE' | translate }}"
    uploadLabel="{{ 'FILE.UPLOAD' | translate }}"
    cancelLabel="{{ 'FILE.CANCEL' | translate }}"
    invalidFileLimitMessageDetail="{{ 'FILE.LIMIT_MESSAGE_DETAIL' | translate }}"
    invalidFileLimitMessageSummary="{{ 'FILE.LIMIT_MESSAGE_SUMMARY' | translate }}"
    mode="{{mode}}"
    name="{{name}}"
    url="{{url}}"
    accept="{{accept}}"
    multiple="true"
    maxFileSize="1000000000000"
    customUpload="true"
    [fileLimit]="fileLimit"
    (onClear)="onClear()"
    (uploadHandler)="onUpload($event)"
    (onSelect)="onSelect($event)"
  >
    <ng-template pTemplate="toolbar">
      <div *ngIf="filePath" [style.marginTop.px]="10">Path: {{ filePath }}</div>
    </ng-template>
    <ng-template let-i="index" let-file pTemplate="file">
      <div class="ui-g">
        <div class="ui-g-4">
          <img alt="{{file.name}}" [src]="file.objectURL" *ngIf="fileUpload.isImage(file)" [width]="100"/>
        </div>
        <div class="ui-g-6">
          {{ file.name }}
        </div>
        <div class="ui-g-1">
          {{ fileUpload.formatSize(file.size) }}
        </div>
        <div class="ui-g-1">
          <button type="button" icon="pi pi-times" pButton (click)="fileUpload.remove($event,i)"
                  [disabled]="fileUpload.uploading"></button>
        </div>
      </div>
    </ng-template>
    <ng-template pTemplate="content">
      <div *ngFor="let item of items" class="ui-g">
        <div class="ui-g-4">
          <img alt="{{item.file.name}}" [src]="getDownloadUrl(item.file)" *ngIf="isImage(item.file)" [width]="100"/>
        </div>
        <div class="ui-g-6">
          {{ item.file.name }}
        </div>
        <div class="ui-g-1">
          {{ fileUpload ? fileUpload.formatSize(item.file.fileSize) : '' }}
        </div>
        <div class="ui-g-1">
          <button type="button" icon="pi pi-download" pButton (click)="onItemDownload(item)"></button>
          <button type="button" icon="pi pi-times" pButton (click)="onItemRemove(item)"></button>
        </div>
      </div>
    </ng-template>
  </p-fileUpload>
</p-dialog>
