import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';
import {GenericElementAbstract} from '../elements/generic-element-abstract.component';
import {ModuleState, ModuleStateContext} from './module-state';
import {WizardElementDetails} from '../elements/custom/wizard/service/wizard.service';
import {ElementType} from './ElementContext';
import {TabState} from './tab-state';

@Injectable()
export class ModulesStateService {

  private currentModuleState: ModuleState;

  private states: ModuleState[] = [];

  private moduleStateChanged = new Subject<ModuleState>();
  public badgeStateChanged = new Subject<TabState>();

  moduleStateChanged$ = this.moduleStateChanged.asObservable();

  badgeStateChanged$ = this.badgeStateChanged.asObservable();

  public exists(moduleState: ModuleState): boolean {
    let moduleStateFound = false;

    for (const existingModuleState of this.states) {
      if (existingModuleState.id === moduleState.id) {
        moduleStateFound = true;
        break;
      }
    }

    return moduleStateFound;
  }

  public existsById(id: number): boolean {
    let moduleStateFound = false;

    for (const existingModuleState of this.states) {
      if (existingModuleState.id === id) {
        moduleStateFound = true;
        break;
      }
    }

    return moduleStateFound;
  }

  public add(moduleState: ModuleState): this {

    if (this.exists(moduleState)) {
      this.replace(moduleState);
    } else {
      this.states.push(moduleState);
    }

    this.setCurrent(moduleState);

    this.moduleStateChanged.next(moduleState);

    return this;
  }

  public remove(moduleState: ModuleState): this {
    this.states.forEach((existingModuleState, index) => {
      if (existingModuleState.id === moduleState.id) {
        this.states.splice(index, 1);
      }
    });

    this.setCurrent(this.getLast());

    this.moduleStateChanged.next(moduleState);

    return this;
  }

  public hide(moduleState: ModuleState): this {
    if (!moduleState) {
      throw new Error(`No module state with id of ${moduleState.id}? Something is wrong...`);
    }

    moduleState.isTabVisible = false;

    this.moduleStateChanged.next(moduleState);

    return this;
  }

  public show(moduleState: ModuleState): this {
    if (!moduleState) {
      throw new Error(`No module state with id of ${moduleState.id}? Something is wrong...`);
    }

    moduleState.isTabVisible = true;

    this.moduleStateChanged.next(moduleState);

    return this;
  }

  public replace(moduleState: ModuleState): this {
    const index = this.states.findIndex(existingModuleState => existingModuleState.id === moduleState.id);

    this.states[index] = moduleState;

    return this;
  }

  public getAll(): ModuleState[] {
    return this.states;
  }

  public getLast(): ModuleState {
    return this.states[this.states.length - 1];
  }

  public getById(id: number): ModuleState {
    let moduleState = this.getLast();

    for (const existingModuleState of this.states) {
      if (existingModuleState.id === id) {
        moduleState = existingModuleState;
        break;
      }
    }

    return moduleState;
  }

  public hideById(id: number): this {
    const moduleState = this.getById(id);

    this.hide(moduleState);

    return this;
  }

  public showById(id: number): this {
    const moduleState = this.getById(id);

    this.show(moduleState);

    return this;
  }

  public getTabVisibleModules(productId: number): ModuleState[] {
    return this.states.filter((moduleState) => {
      return moduleState.isTabVisible && moduleState.productId === productId;
    });
  }

  public clear(): this {
    this.states = [];
    return this;
  }

  public setCurrent(moduleState: ModuleState): this {
    this.currentModuleState = moduleState;
    return this;
  }

  public getCurrent(): ModuleState | null {
    return this.currentModuleState || null;
  }

  public getByComponent(component: GenericElementAbstract, searchInWizard: boolean = true): ModuleState|null {
    let foundState = null;

    for (const state of this.states) {
      const components = state.getAllComponents();

      for (const aComponent of components) {
        if (aComponent && aComponent.moduleElement && component && component.moduleElement &&
          aComponent.moduleElement.id === component.moduleElement.id
        ) {
          foundState = state;
          break;
        }

        if (searchInWizard && state.hasContext(ModuleStateContext.Wizard) && aComponent.getElementContext() &&
          aComponent.getElementContext().type === ElementType.Wizard
        ) {
          foundState = this.getInWizardComponent(component, aComponent);
        }
      }
    }

    return foundState;
  }

  public getPreviousByComponent(component: GenericElementAbstract, searchInWizard: boolean = true): ModuleState|null {
    const current = this.getByComponent(component);

    if (current !== null) {
      const index = this.states.findIndex(existingModuleState => existingModuleState.id === current.id);

      if (index > 0) {
        return this.states[index - 1];
      }
    }

    return null;
  }

  private getInWizardComponent(findingByComponent: GenericElementAbstract, wizardComponent: any): ModuleState|null {
    const details: WizardElementDetails[] = wizardComponent.wizardService.wizardElementDetails;

    let foundState = null;

    for (const wizardDetails of details) {
      const state = wizardDetails.moduleState,
        components = state.getComponents();

      for (const component of components) {
        if (component && component.moduleElement && findingByComponent.moduleElement &&
          component.moduleElement.id === findingByComponent.moduleElement.id
        ) {
          foundState = state;
          break;
        }
      }
    }

    return foundState;
  }

}
