/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./generic-full-calendar.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../../node_modules/primeng/components/fullcalendar/fullcalendar.ngfactory";
import * as i3 from "primeng/components/fullcalendar/fullcalendar";
import * as i4 from "@angular/common";
import * as i5 from "../../services/cancel-components-changes.service";
import * as i6 from "@ngx-translate/core";
import * as i7 from "primeng/components/common/confirmationservice";
import * as i8 from "../../services/entity-data-store.service";
import * as i9 from "../../../../core/executor/factory/execution-step-factory.service";
import * as i10 from "../../services/modules-state.service";
import * as i11 from "../generic-toolbar/services/check/toolbar-item-check.service";
import * as i12 from "../generic-dialog/service/generic-dialog-module.service";
import * as i13 from "../../../../core/executor/executor.service";
import * as i14 from "../../../../core/executor/service/executor-actions/executor-actions.service";
import * as i15 from "../../services/generic/generic-element-validation-execution-steps-factory";
import * as i16 from "./generic-full-calendar.component";
import * as i17 from "../../services/component-highlight-stack.service";
import * as i18 from "../../../validators/services/entity-validator";
import * as i19 from "../../../services/generic-crud.service";
import * as i20 from "../../../../core/service/user-session.service";
import * as i21 from "../../../services/permission/permission.service";
import * as i22 from "./service/generic-full-calendar-events.service";
var styles_GenericFullCalendarComponent = [i0.styles];
var RenderType_GenericFullCalendarComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_GenericFullCalendarComponent, data: {} });
export { RenderType_GenericFullCalendarComponent as RenderType_GenericFullCalendarComponent };
function View_GenericFullCalendarComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [], [[8, "className", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.highlight() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "p-fullCalendar", [], null, null, null, i2.View_FullCalendar_0, i2.RenderType_FullCalendar)), i1.ɵdid(2, 8634368, [[1, 4], ["fullCalendar", 4]], 0, i3.FullCalendar, [i1.ElementRef], { events: [0, "events"], options: [1, "options"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.events; var currVal_2 = _co.options; _ck(_v, 2, 0, currVal_1, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.isHighlighted() ? "highlighted full-calendar-container" : "full-calendar-container"); _ck(_v, 0, 0, currVal_0); }); }
export function View_GenericFullCalendarComponent_0(_l) { return i1.ɵvid(2, [i1.ɵqud(671088640, 1, { calendar: 0 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_GenericFullCalendarComponent_1)), i1.ɵdid(2, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.element.datamodel; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_GenericFullCalendarComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 16777216, null, null, 4, "app-generic-full-calendar", [], null, [[null, "mouseover"], [null, "mouseleave"]], function (_v, en, $event) { var ad = true; if (("mouseover" === en)) {
        var pd_0 = (i1.ɵnov(_v, 4).onMouseOver($event) !== false);
        ad = (pd_0 && ad);
    } if (("mouseleave" === en)) {
        var pd_1 = (i1.ɵnov(_v, 4).onMouseLeave($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, View_GenericFullCalendarComponent_0, RenderType_GenericFullCalendarComponent)), i1.ɵprd(4608, null, i5.CancelComponentChangesService, i5.CancelComponentChangesService, [i6.TranslateService, i7.ConfirmationService, i8.EntityDataStoreService, i9.ExecutionStepFactoryService, i10.ModulesStateService, i11.ToolbarItemCheckService, i12.GenericDialogModuleService]), i1.ɵprd(512, null, i13.ExecutorService, i13.ExecutorService, [i14.ExecutorActionsService]), i1.ɵprd(512, null, i15.GenericElementValidationExecutionStepsFactory, i15.GenericElementValidationExecutionStepsFactory, [i9.ExecutionStepFactoryService]), i1.ɵdid(4, 245760, null, 0, i16.GenericFullCalendarComponent, [i17.ComponentService, i1.ViewContainerRef, i8.EntityDataStoreService, i10.ModulesStateService, i13.ExecutorService, i15.GenericElementValidationExecutionStepsFactory, i18.EntityValidator, i19.GenericCrudService, i20.UserSessionService, i21.PermissionService, i1.ChangeDetectorRef, i22.GenericFullCalendarEventsService], null, null)], function (_ck, _v) { _ck(_v, 4, 0); }, null); }
var GenericFullCalendarComponentNgFactory = i1.ɵccf("app-generic-full-calendar", i16.GenericFullCalendarComponent, View_GenericFullCalendarComponent_Host_0, { masterElementContext: "masterElementContext", moduleElement: "moduleElement", element: "element", parentComponent: "parentComponent", isDialog: "isDialog", isPart: "isPart", toolbarItems: "toolbarItems", statusBarItems: "statusBarItems", selectedMasterEntity: "selectedMasterEntity", additional: "additional", masterEntity: "masterEntity", masterField: "masterField", entity: "entity" }, { componentInstantiated: "componentInstantiated" }, []);
export { GenericFullCalendarComponentNgFactory as GenericFullCalendarComponentNgFactory };
