import { of as observableOf } from 'rxjs';
import { ChangeDetectorRefHelper } from '../../../../helpers/change-detector-ref.helper';
import { MEMO_EMBEDDED_ENTITIES, MemoFieldDefinitionValueComponent, MemoTypeFieldDefinitionType } from '../memo-field-definition-value/memo-field-definition-value.component';
import { map, takeUntil } from 'rxjs/operators';
import { AbstractGenericGridComponent } from '../../abstract-generic-grid.component';
export class MemoFieldDmsSearchComponent extends MemoFieldDefinitionValueComponent {
    constructor() {
        super(...arguments);
        this.toolbarItems = [];
        this.statusBarItems = [];
        this.masterEntity = null;
        this.masterField = null;
        this.isPart = false;
        this.configuration = {
            ownerApi: 'dms/dmsfiles',
            fieldDefinitionValuesApi: 'dms/memofielddefinitionvalues',
            memoTypeFieldDefinitionsApi: 'dms/memotypefielddefinitions',
            memoTypeApi: 'dms/memotypes',
            memoTypeDefinedOutside: false,
            memoTypeRelation: 'memoType',
            embeddedEntities: MEMO_EMBEDDED_ENTITIES,
            usesExternalRefresh: false,
            search: true,
        };
    }
    ngOnInit() {
        super.ngOnInit();
        this.onRefresh()
            .pipe(takeUntil(this.unsubscribe))
            .subscribe();
    }
    onMemoTypeChanged(memoType) {
        this.memoType = memoType;
        this.onRefresh()
            .pipe(takeUntil(this.unsubscribe))
            .subscribe();
    }
    onSearchChange(event) {
        if (this.selectedSearch === 'all') {
            this.memoType = null;
        }
        const component = this.getGridComponent();
        if (component instanceof AbstractGenericGridComponent) {
            component.clearEntities();
        }
        this.onRefresh()
            .pipe(takeUntil(this.unsubscribe))
            .subscribe();
    }
    onSearch() {
        const component = this.getGridComponent(), memoTypeFieldDefinitions = this.visibleMemoTypeFieldDefinitions
            .filter((aMemoTypeFieldDefinition) => aMemoTypeFieldDefinition.searchValue);
        if (component instanceof AbstractGenericGridComponent) {
            if (!component.embeddedFields.includes('fieldDefinitionValues')) {
                component.embeddedFields.push('fieldDefinitionValues');
            }
            component.staticFilters = [];
            if (memoTypeFieldDefinitions.length > 0) {
                const filterValues = [];
                for (const memoTypeFieldDefinition of memoTypeFieldDefinitions) {
                    filterValues.push({
                        value: memoTypeFieldDefinition.searchValue,
                        fieldDefinition: memoTypeFieldDefinition.id
                    });
                }
                component.staticFilters.push({
                    field: `fieldDefinitionValues.id`,
                    value: `dmsFieldDefinitionValue:${JSON.stringify(filterValues)}`
                });
            }
            component.loadEntities()
                .pipe(takeUntil(component.unsubscribe))
                .subscribe();
        }
    }
    onFieldDefinitionValueEdit(event, entity) {
        const dataType = entity.fieldDefinitionTemplate.dataType.code;
        let value = null;
        switch (dataType) {
            case MemoTypeFieldDefinitionType.CODE_DROPDOWN:
                value = event.value;
                break;
            case MemoTypeFieldDefinitionType.CODE_DATETIME:
            case MemoTypeFieldDefinitionType.CODE_CHECKBOX:
                value = event;
                break;
            case MemoTypeFieldDefinitionType.CODE_TEXT:
                value = event.target.value;
                break;
        }
        entity.searchValue = value;
    }
    onRefresh() {
        this.isLoadingData = true;
        return this.loadMemoTypeFieldDefinitions(this.memoType)
            .pipe(map((response) => {
            this.visibleMemoTypeFieldDefinitions = response;
            this.isLoadingData = false;
            this.toolbarItemCheckService.check(this);
            ChangeDetectorRefHelper.detectChanges(this);
            return { status: true, content: null };
        }));
    }
    getSelectedEntity() {
        return this.selectedMasterEntity || null;
    }
    recheckToolbarItems() {
        this.toolbarItemCheckService.check(null);
    }
    onSave() {
        return observableOf(null);
    }
    getGridComponent() {
        const context = this.elementsStackService.findByComponentDatamodelAndType('DmsBundle\\Entity\\DmsFile', 1 /* Grid */);
        return context.component;
    }
}
