export interface FroalaToolbarConfigurationInterface {
  label: string;
  value: string;
  toolbarButtons: string[];
  customButtons: { icon: string, name: string, labelKey: string }[];
}

export class FroalaToolbarConfiguration {
  public static configuration: FroalaToolbarConfigurationInterface[] = [
    { value: 'default', label: 'Default', toolbarButtons: [
        'fullscreen', 'bold', 'italic', 'underline',
        '|', 'fontFamily', 'fontSize', 'color',
        '|', 'paragraphFormat', 'align', 'formatOL', 'formatUL',
        '-', 'embedly', 'insertTable',
        '|', 'insertHR'], customButtons: [] },
    { value: 'defaultWithModuleButton', label: 'Module Button',  toolbarButtons: [
        'fullscreen', 'bold', 'italic', 'underline',
        '|', 'fontFamily', 'fontSize', 'color',
        '|', 'paragraphFormat', 'align', 'formatOL', 'formatUL',
        '-', 'embedly', 'insertTable',
        '|', 'insertHR', 'moduleButton'
      ], customButtons: [{name: 'moduleButton', icon: 'info', labelKey: 'CUSTOM_BUTTON.OPEN_MODULE'}] }
  ];

  public static getConfiguration(key: string): FroalaToolbarConfigurationInterface {
    for (const configuration of FroalaToolbarConfiguration.configuration) {
      if (configuration.value === key) {
        return configuration;
      }
    }

    return null;
  }
}
