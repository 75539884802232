import {ExecutorActionEvent} from './executor-actions/executor-action-event';
import {GenerateWorkplaceMedicalExaminationStep} from '../../../shared/services/execution-step/customer/workplace/generate-workplace-medical-examinations-step';
import {GenerateWorkplaceProtectiveEquipmentsStep} from '../../../shared/services/execution-step/customer/workplace/generate-workplace-protective-equipments-step';
import {GenerateWorkplaceTimeModelsStep} from '../../../shared/services/execution-step/customer/workplace/generate-workplace-time-models-step';
import {GenerateWorkplaceWorkEquipmentsStep} from '../../../shared/services/execution-step/customer/workplace/generate-workplace-work-equipments-step';
import {GenerateSelectedWorkingTimeModelDaysToSlavesStep} from '../../../shared/services/execution-step/customer/generate-selected-working-time-model-days-to-slaves-step';
import {DisplayFailedValidationMessageStep} from '../../../shared/services/execution-step/customer/display-failed-validation-message-step';
import {CleanupDeletedEntitiesStep} from '../../../shared/services/execution-step/grid/cleanup-deleted-entities-step';
import {InlineEditInquiryDynamicTableStep} from '../../../shared/services/execution-step/inquiry/inline-edit-inquiry-dynamic-table-step';
import {SaveInquiryDynamicTableStep} from '../../../shared/services/execution-step/inquiry/save-inquiry-dynamic-table-step';
import {GenerateInquiryDynamicTableStep} from '../../../shared/services/execution-step/inquiry/generate-inquiry-dynamic-table.step';
import {SetValueFromDialogStep} from '../../../shared/services/execution-step/dialog-for-autocomplete/set-value-from-dialog-step';
import {SetCollectiveAgreementToInquiryFromDialogStep} from '../../../shared/services/execution-step/inquiry/dialog-for-autocomplete/set-collective-agreement-to-inquiry-from-dialog-step';
import {FilterInquiryAutocompletesInGridStep} from '../../../shared/services/execution-step/inquiry/filter-inquiry-autocompletes-in-grid.step';
import {SetContactPersonToInquiryFromDialogStep} from '../../../shared/services/execution-step/inquiry/dialog-for-autocomplete/set-contact-person-to-inquiry-from-dialog-step';
import {FilterAddressAutocompleteInContactPersonFormStep} from '../../../shared/services/execution-step/inquiry/dialog-for-autocomplete/filter-address-autocomplete-in-contact-person-form-step';
import {RegisterInquiryDetailsJobStep} from '../../../shared/services/execution-step/inquiry/register-inquiry-details-job-step';
import {PrecalculationDetailsToggleColumnsVisibilityStep} from '../../../shared/services/execution-step/customer/condition/precalculation-details-toggle-columns-visibility-step';

import {SalaryTypeDisableFieldsStep} from '../../../shared/services/execution-step/collective-agreement/salary-type-disable-fields-step';
import {PrecalculationDetailsDisableFieldsStep} from '../../../shared/services/execution-step/collective-agreement/precalculation-details-disable-fields-step';
import {RegisterInquiryCustomerEntityForFormJobStep} from '../../../shared/services/execution-step/inquiry/register-inquiry-customer-entity-for-form-job-step';
import {FilterGridByCustomerStep} from '../../../shared/services/execution-step/order/filter-grid-by-customer.step';
import {WizardNextElementExecutionStep} from '../../../shared/services/execution-step/wizard/wizard-next-element-execution-step';
import {WizardPreviousElementExecutionStep} from '../../../shared/services/execution-step/wizard/wizard-previous-element-execution-step';
import {WizardFirstElementExecutionStep} from '../../../shared/services/execution-step/wizard/wizard-first-element-execution-step';
import {WizardLastElementExecutionStep} from '../../../shared/services/execution-step/wizard/wizard-last-element-execution-step';
import {WizardPrecalculationBeforeElementOneExecutionStep} from '../../../shared/services/execution-step/wizard/inquiry/inquiry-position/wizard-precalculation-before-element-one-execution-step';
import {WizardPrecalculationBeforeElementTwoExecutionStep} from '../../../shared/services/execution-step/wizard/inquiry/inquiry-position/wizard-precalculation-before-element-two-execution-step';
import {WizardPrecalculationFinishExecutionStep} from '../../../shared/services/execution-step/wizard/inquiry/inquiry-position/wizard-precalculation-finish-execution-step';
import {OfferGtcTextDisableFieldsExecutionStep} from '../../../shared/services/execution-step/offer/offer-gtc-text-disable-fields-execution-step';
import {SetInvoicePossibilityToOfferFromDialogExecutionStep} from '../../../shared/services/execution-step/offer/dialog-for-autocomplete/set-invoice-possibility-to-offer-from-dialog-execution-step';
import {SetSelectedWorkingTimeModelToInquiryPositionExecutionStep} from '../../../shared/services/execution-step/inquiry/inquiry-position/set-selected-working-time-model-to-inquiry-position-execution-step';
import {SetModuleStateAsDetailsExecutionStep} from '../../../shared/services/execution-step/set-module-state-as-details-execution-step';
import {SetActiveFilterStep} from '../../../shared/services/execution-step/precalculation/grid/set-active-filter-step';
import {SetupContactPersonExpanderComponentExecutionStep} from '../../../shared/services/execution-step/customer/address/setup-contact-person-expander-component-execution-step';
import {SetCustomerCollectiveContractToInquiryFromDialogStep} from '../../../shared/services/execution-step/inquiry/dialog-for-autocomplete/set-customer-collective-contract-to-inquiry-from-dialog-step';
import {WizardPrecalculationElementOneNextExecutionStep} from '../../../shared/services/execution-step/wizard/inquiry/inquiry-position/wizard-precalculation-element-one-next-execution-step';
import {RegisterInquiryPositionGridToolbarItemsColorStep} from '../../../shared/services/execution-step/inquiry/inquiry-position/register-inquiry-position-grid-toolbar-button-color-strategy.step';
import {FilterSalaryTypesByIsActiveAndCollectiveAgreementStep} from '../../../shared/services/execution-step/collective-agreement/filter-salary-types-by-is-active-and-collective-agreement.step';
import {ComponentOpenModuleExecutionStep} from '../../../shared/services/execution-step/component-open-module-execution-step';
import {PrecalculationDetailsColorEntitiesStep} from '../../../shared/services/execution-step/customer/condition/precalculation-details-color-entities-step';
import {OpenAddressPhoneWindowExecutionStep} from '../../../shared/services/execution-step/customer/address/open-address-phone-window-execution-step';
import {OpenAddressMobilePhoneWindowExecutionStep} from '../../../shared/services/execution-step/customer/address/open-address-mobile-phone-window-execution-step';
import {OpenAddressSecondaryMobilePhoneWindowExecutionStep} from '../../../shared/services/execution-step/customer/address/open-address-secondary-mobile-phone-window-execution-step';
import {OpenAddressFaxPhoneWindowExecutionStep} from '../../../shared/services/execution-step/customer/address/open-address-fax-phone-window-execution-step';
import {OpenCustomerDetailsModuleExecutionStep} from '../../../shared/services/execution-step/customer/open-customer-details-module-execution-step';
import {OpenLeasedEmployeeDetailsModuleExecutionStep} from '../../../shared/services/execution-step/leased-employee/open-leased-employee-details-module-execution-step';
import {WorkHourCalculateTimesStep} from '../../../shared/services/execution-step/work-hour/work-hour-calculate-times-step';
import {InquiryDetailsColorDynamicTableEntitiesExecutionStep} from '../../../shared/services/execution-step/inquiry/inquiry-details-color-dynamic-table-entities-execution-step';
import {FilterReportGridByUserPermissionExecutionStep} from '../../../shared/services/execution-step/report/filter-report-grid-by-user-permission-execution-step';
import {FilterReportExecutionGridByReportExecutionStep} from '../../../shared/services/execution-step/report/filter-report-execution-grid-by-report-execution-step';
import {WizardSaveModuleExecutionStep} from '../../../shared/services/execution-step/wizard/wizard-save-module-execution-step';
import {WizardSavePrecalculationBeforeElementTwoExecutionStep} from '../../../shared/services/execution-step/wizard/precalculation/wizard-save-precalculation-before-element-two-execution-step';
import {WizardSavePrecalculationExecutionStep} from '../../../shared/services/execution-step/wizard/precalculation/wizard-save-precalculation-execution-step';
import {WizardSavePrecalculationFinishExecutionStep} from '../../../shared/services/execution-step/wizard/precalculation/wizard-save-precalculation-finish-execution-step';
import {LoadWorkplaceSelectedMedicalExaminationsStep} from '../../../shared/services/execution-step/customer/workplace/load-workplace-selected-medical-examinations-step';
import {LoadWorkplaceSelectedProtectiveEquipmentsStep} from '../../../shared/services/execution-step/customer/workplace/load-workplace-selected-protective-equipments-step';
import {LoadWorkplaceSelectedWorkEquipmentsStep} from '../../../shared/services/execution-step/customer/workplace/load-workplace-selected-work-equipments-step';
import {InitSelectableStep} from '../../../shared/services/execution-step/init-selectable.step';
import {ReplaceEntitiesWithSelectedStep} from '../../../shared/services/execution-step/replace-entities-with-selected.step';
import {ComponentFocusInlineEditorExecutionStep} from '../../../shared/services/execution-step/component-focus-inline-editor-execution.step';
import {CopyEntityStep} from '../../../shared/services/execution-step/copy-entity/copy-entity-step';
import {SetupLeasedEmployeePlanningComponentExecutionStep} from '../../../shared/services/execution-step/leased-employee/setup-leased-employee-planning-component-execution.step';
import {PrecalculationDetailsRecalculateGeneralArticleStep} from '../../../shared/services/execution-step/customer/condition/general-article/precalculation-details-recalculate-general-article.step';
import {PrecalculationDetailsRecalculateOtherArticleStep} from '../../../shared/services/execution-step/customer/condition/other-article/precalculation-details-recalculate-other-article.step';
import {WorkHourDisableFieldsExecutionStep} from '../../../shared/services/execution-step/work-hour/work-hour-disable-fields-execution-step';
import {SetupMemoFieldDefinitionValueComponentExecutionStep} from '../../../shared/services/execution-step/memo/setup-memo-field-definition-value-component-execution.step';
import {RegisterMemoDetailsJobExecutionStep} from '../../../shared/services/execution-step/memo/register-memo-details-job-execution.step';
import {ToolbarItemCollectiveAgreementActivateExecutionStep} from '../../../shared/services/execution-step/collective-agreement/toolbar-item-collective-agreement-activate-execution.step';
import {OpenCollectiveAgreementDetailsModuleExecutionStep} from '../../../shared/services/execution-step/collective-agreement/open-collective-agreement-details-module-execution.step';
import {ComponentOpenDetailsViewExecutionStep} from '../../../shared/services/execution-step/component-open-details-view-execution-step';
import {WorkHourFrontendCalculateTimesStep} from '../../../shared/services/execution-step/work-hour/work-hour-frontend-calculate-times-step';
import {CloseModuleDialogExecutionStep} from '../../../shared/services/execution-step/module/close-module-dialog-execution.step';
import {DisableCloseModuleDialogOnNewDialogExecutionStep} from '../../../shared/services/execution-step/module/disable-close-module-dialog-on-new-dialog-execution.step';
import {EnableCloseModuleDialogOnNewDialogExecutionStep} from '../../../shared/services/execution-step/module/enable-close-module-dialog-on-new-dialog-execution.step';
import {SetupLeasedEmployeeSearchExecutionStep} from '../../../shared/services/execution-step/leased-employee/setup-leased-employee-search-execution.step';
import {WizardPrecalculationFinishInCustomerDetailsExecutionStep} from '../../../shared/services/execution-step/wizard/customer/wizard-precalculation-finish-in-customer-details-execution-step';
import {SetupDispoLeasedEmployeeSearchTabOneExecutionStep} from '../../../shared/services/execution-step/leased-employee/setup-dispo-leased-employee-search-tab-oneexecution.step';
import {CurriculumTreeAddEntryPositionToAddedEntityExecutionStep} from '../../../shared/services/execution-step/leased-employee/curriculum-tree-add-entry-position-to-added-entity-execution.step';
import {ToolbarItemContractOfEmploymentUserDataChangeExecutionStep} from '../../../shared/services/execution-step/contract-of-employment/toolbar-item-contract-of-employment-user-data-change-execution.step';
import {ComponentRefreshExecutionStep} from '../../../shared/services/execution-step/component-refresh-execution-step';
import {ComponentSaveExecutionStep} from '../../../shared/services/execution-step/component-save-execution-step';
import {ComponentAddNewEntityExecutionStep} from '../../../shared/services/execution-step/component-add-new-entity-execution-step';
import {ContextMenuWorkHourCalculateSalaryExecutionStep} from '../../../shared/services/execution-step/work-hour/context-menu/context-menu-work-hour-calculate-salary-execution-step';
import {ComponentCacheChangedEntitiesExecutionStep} from '../../../shared/services/execution-step/component-cache-changed-entities-execution.step';
import {ComponentClearCachedEntitiesExecutionStep} from '../../../shared/services/execution-step/component-clear-cached-entities-execution.step';
import {ComponentMergeCachedEntitiesExecutionStep} from '../../../shared/services/execution-step/component-merge-cached-entities-execution.step';
import {ContextMenuWorkHourOpenSubAssignmentInfoExecutionStep} from '../../../shared/services/execution-step/work-hour/context-menu/context-menu-work-hour-open-sub-assignment-info-execution-step';
import {RemoveIsMemoTypeTextDefinedOnLoadRuntimeFlagExecutionStep} from '../../../shared/services/execution-step/memo/remove-is-memo-type-text-defined-on-load-runtime-flag-execution-step';
import {PrecalculationDetailsRecalculateArticlesStep} from '../../../shared/services/execution-step/customer/condition/precalculation-details-recalculate-articles.step';
import {LeasedEmployeeAbsenceCalculateTimesStep} from '../../../shared/services/execution-step/leased-employee-absence/leased-employee-absence-calculate-times-step';
import {WorkHourMonthlyEntryColorEntitiesExecutionStep} from '../../../shared/services/execution-step/work-hour/work-hour-monthly-entry-color-entities-execution.step';
import {WorkHourMonthlyEntryToolbarItemWageSettledExecutionStep} from '../../../shared/services/execution-step/work-hour/work-hour-monthly-entry-toolbar-item-wage-settled-execution.step';
import {WorkHourMonthlyEntryCalculateWageExecutionStep} from '../../../shared/services/execution-step/work-hour/work-hour-monthly-entry-calculate-wage-execution.step';
import {WorkHourMonthlyEntryCalculateInvoiceExecutionStep} from '../../../shared/services/execution-step/work-hour/work-hour-monthly-entry-calculate-invoice-execution.step';
import {WorkHourMonthlyEntryToolbarItemInvoiceSettledExecutionStep} from '../../../shared/services/execution-step/work-hour/work-hour-monthly-entry-toolbar-item-invoice-settled-execution.step';
import {WorkHourMonthlyEntrySetupNewEntityStep} from '../../../shared/services/execution-step/work-hour/work-hour-monthly-entry-setup-new-entity-step';
import {InvoiceToolbarItemInvoiceNumberGenerateExecutionStep} from '../../../shared/services/execution-step/invoice/invoice-toolbar-item-invoice-number-generate-execution.step';
import {ContextMenuWorkHourOpenMonthlyAndSubAssignmentInfoExecutionStep} from '../../../shared/services/execution-step/work-hour/context-menu/context-menu-work-hour-open-monthly-and-sub-assignment-info-execution.step';
import {WorkHourEntryPrecalculationArticlesToggleColumnVisibilityExecutionStep} from '../../../shared/services/execution-step/work-hour/work-hour-entry-precalculation-articles-toggle-column-visibility-execution.step';
import {InvoiceToolbarItemInvoiceRecalculateExecutionStep} from '../../../shared/services/execution-step/invoice/invoice-toolbar-item-invoice-recalculate-execution.step';
import {ContextMenuWorkHourSetCostCenterExecutionStep} from '../../../shared/services/execution-step/work-hour/context-menu/context-menu-work-hour-set-cost-center-execution-step';
import {MasterComponentRefreshExecutionStep} from '../../../shared/services/execution-step/master-component-refresh-execution-step';
import {SetInvoicePossibilityToOrderFromDialogExecutionStep} from '../../../shared/services/execution-step/offer/dialog-for-autocomplete/set-invoice-possibility-to-order-from-dialog-execution-step';
import {SetContactPersonToOfferFromDialogExecutionStep} from '../../../shared/services/execution-step/offer/dialog-for-autocomplete/set-contact-person-to-offer-from-dialog-execution-step';
import {SetContactPersonToOrderFromDialogExecutionStep} from '../../../shared/services/execution-step/offer/dialog-for-autocomplete/set-contact-person-to-order-from-dialog-execution-step';
import {SelectedWorkingModelDayColumnSwitchStep} from '../../../shared/services/execution-step/customer/selected-working-model/selected-working-model-day-column-switch-step';
import {RunCronJobExecutionStep} from '../../../shared/services/execution-step/cron-job/run-cron-job-execution.step';
import {OnLeasedEmployeeJobEntityChangeExecutionStep} from '../../../shared/services/execution-step/leased-employee/on-leased-employee-job-entity-change-execution.step';
import {OnLeasedEmployeeSpokenLanguageEntityChangeExecutionStep} from '../../../shared/services/execution-step/leased-employee/on-leased-employee-spoken-language-entity-change-execution.step';
import {OpenTodoOwnerModuleExecutionStep} from '../../../shared/services/execution-step/todo/open-todo-owner-module-execution.step';
import {SetupOrganisationModulePartComponentExecutionStep} from '../../../shared/services/execution-step/organisation/setup-organisation-module-part-component-execution.step';
import {CopyPrecalculationStep} from '../../../shared/services/execution-step/precalculation/copy-precalculation-step';
import {SetDefaultTextToMasterFormElementExecutionStep} from '../../../shared/services/execution-step/set-default-text-to-master-form-element-execution.step';
import {SuperadminExportDataToSelectedServerExecutionStep} from '../../../shared/services/execution-step/superadmin-import-export/superadmin-export-data-to-selected-server-execution-step';
import {SetTicketResponsibleUser} from '../../../shared/services/execution-step/ticket/SetTicketResponsibleUser';
import {ToolbarItemOpenModuleUseMasterEntityExecutionStep} from '../../../shared/services/execution-step/toolbar-item-open-module-use-master-entity-execution-step';
import {ComponentFileGridSetMasterFilterBeforeLoadExecutionStep} from '../../../shared/services/execution-step/component-file-grid-set-master-filter-before-load-execution-step';
import {CloseTicketFromTicketAction} from '../../../shared/services/execution-step/ticket/CloseTicketFromTicketAction';
import {BeforePhoenixActivePartsLoadExecutionStep} from '../../../shared/services/execution-step/before-phoenix-active-parts-load-execution.step';
import {BeforeTicketActivePartsLoadExecutionStep} from '../../../shared/services/execution-step/before-ticket-active-parts-load-execution.step';
import {SetupQuestionnaireFieldDefinitionValueComponentExecutionStep} from '../../../shared/services/execution-step/workflow/setup-questionnaire-field-definition-value-component-execution.step';
import {LockSlaveGridStep} from '../../../shared/services/execution-step/precalculation/lock-slave-grid-step';

export class ExecutorRegistry {

    public events = [
        ExecutorActionEvent.Click,
        ExecutorActionEvent.DoubleClick,
        ExecutorActionEvent.Init,
        ExecutorActionEvent.Destroy,
        ExecutorActionEvent.BeforeLoad,
        ExecutorActionEvent.Load,
        ExecutorActionEvent.EntityValueChanged,
        ExecutorActionEvent.EntityChanged,
        ExecutorActionEvent.EntitiesChanged,
        ExecutorActionEvent.EntitiesRefresh,
        ExecutorActionEvent.EntityValidated,
        ExecutorActionEvent.MasterEntitiesChanged,
        ExecutorActionEvent.MasterEntityChanged,
        ExecutorActionEvent.MasterEntityValueChanged,
        ExecutorActionEvent.SlaveEntitiesChanged,
        ExecutorActionEvent.SlaveEntityChanged,
        ExecutorActionEvent.SlaveEntityValueChanged,
        ExecutorActionEvent.DynamicTableCellEdit,
        ExecutorActionEvent.DynamicTableSave,
        ExecutorActionEvent.DynamicTreeCellEdit,
        ExecutorActionEvent.DynamicTreeSave,
        ExecutorActionEvent.DynamicTreeEntitiesChanged,
        ExecutorActionEvent.FormRendered,
        ExecutorActionEvent.AddNew,
        ExecutorActionEvent.AfterNewEntityAdded,
        ExecutorActionEvent.Edit,
        ExecutorActionEvent.BeforeInlineEditInit,
        ExecutorActionEvent.InlineEditCellLeave,
        ExecutorActionEvent.ComponentSaved,
        ExecutorActionEvent.EntityImported,
        ExecutorActionEvent.BeforeCancel,
        ExecutorActionEvent.AfterCancel,
        ExecutorActionEvent.BeforeSimpleSearch,
        ExecutorActionEvent.TodoOwnerIconClick,
        ExecutorActionEvent.BeforeActivePartsLoad
    ];

    public steps = {
        'GenerateWorkplaceMedicalExaminationStep': GenerateWorkplaceMedicalExaminationStep,
        'LoadWorkplaceSelectedMedicalExaminationStep': LoadWorkplaceSelectedMedicalExaminationsStep,
        'GenerateWorkplaceProtectiveEquipmentsStep': GenerateWorkplaceProtectiveEquipmentsStep,
        'LoadWorkplaceSelectedProtectiveEquipmentsStep': LoadWorkplaceSelectedProtectiveEquipmentsStep,
        'GenerateWorkplaceTimeModelsStep': GenerateWorkplaceTimeModelsStep,
        'GenerateWorkplaceWorkEquipmentsStep': GenerateWorkplaceWorkEquipmentsStep,
        'LoadWorkplaceSelectedWorkEquipmentsStep': LoadWorkplaceSelectedWorkEquipmentsStep,
        'GenerateSelectedWorkingTimeModelDaysToSlavesStep': GenerateSelectedWorkingTimeModelDaysToSlavesStep,
        'WorkHourCalculateTimesStep': WorkHourCalculateTimesStep,
        'LeasedEmployeeAbsenceCalculateTimesStep': LeasedEmployeeAbsenceCalculateTimesStep,
        'WorkHourFrontendCalculateTimesStep': WorkHourFrontendCalculateTimesStep,
        'CopyEntityStep': CopyEntityStep,
        'CopyPrecalculationStep': CopyPrecalculationStep,
        'CloseModuleDialogExecutionStep': CloseModuleDialogExecutionStep,
        'DisableCloseModuleDialogOnNewDialogExecutionStep': DisableCloseModuleDialogOnNewDialogExecutionStep,
        'EnableCloseModuleDialogOnNewDialogExecutionStep': EnableCloseModuleDialogOnNewDialogExecutionStep,
        'DisplayFailedValidationMessageStep': DisplayFailedValidationMessageStep,
        'SelectedWorkingModelDayColumnSwitchStep': SelectedWorkingModelDayColumnSwitchStep,
        'CleanupDeletedEntitiesStep': CleanupDeletedEntitiesStep,
        'GenerateInquiryDynamicTableStep': GenerateInquiryDynamicTableStep,
        'InquiryDetailsColorDynamicTableEntitiesExecutionStep': InquiryDetailsColorDynamicTableEntitiesExecutionStep,
        'InitSelectableStep': InitSelectableStep,
        'ReplaceEntitiesWithSelectedStep': ReplaceEntitiesWithSelectedStep,
        'FilterInquiryAutocompletesInGridStep': FilterInquiryAutocompletesInGridStep,
        'FilterAddressAutocompleteInContactPersonFormStep': FilterAddressAutocompleteInContactPersonFormStep,
        'InlineEditInquiryDynamicTableStep': InlineEditInquiryDynamicTableStep,
        'SaveInquiryDynamicTableStep': SaveInquiryDynamicTableStep,
        'SetCollectiveAgreementToInquiryFromDialogStep': SetCollectiveAgreementToInquiryFromDialogStep,
        'SetCustomerCollectiveContractToInquiryFromDialogStep': SetCustomerCollectiveContractToInquiryFromDialogStep,
        'SetContactPersonToInquiryFromDialogStep': SetContactPersonToInquiryFromDialogStep,
        'SetValueFromDialogStep': SetValueFromDialogStep,
        'RegisterInquiryDetailsJobStep': RegisterInquiryDetailsJobStep,
        'RegisterInquiryCustomerEntityForFormJobStep': RegisterInquiryCustomerEntityForFormJobStep,
        'RegisterInquiryPositionGridToolbarItemsColorStep': RegisterInquiryPositionGridToolbarItemsColorStep,
        'RegisterMemoDetailsJobExecutionStep': RegisterMemoDetailsJobExecutionStep,
        'PrecalculationDetailsToggleColumnsVisibilityStep': PrecalculationDetailsToggleColumnsVisibilityStep,
        'PrecalculationDetailsRecalculateGeneralArticleStep': PrecalculationDetailsRecalculateGeneralArticleStep,
        'PrecalculationDetailsRecalculateOtherArticleStep': PrecalculationDetailsRecalculateOtherArticleStep,
        'PrecalculationDetailsRecalculateArticlesStep': PrecalculationDetailsRecalculateArticlesStep,
        'PrecalculationDetailsColorEntitiesStep': PrecalculationDetailsColorEntitiesStep,
        'PrecalculationDetailsDisableFieldsStep': PrecalculationDetailsDisableFieldsStep,
        'SalaryTypeDisableFieldsStep': SalaryTypeDisableFieldsStep,
        'FilterSalaryTypesByIsActiveAndCollectiveAgreementStep': FilterSalaryTypesByIsActiveAndCollectiveAgreementStep,
        'FilterGridByCustomerStep': FilterGridByCustomerStep,
        'SetActiveFilterStep': SetActiveFilterStep,
        'WizardNextElementExecutionStep': WizardNextElementExecutionStep,
        'WizardPreviousElementExecutionStep': WizardPreviousElementExecutionStep,
        'WizardFirstElementExecutionStep': WizardFirstElementExecutionStep,
        'WizardLastElementExecutionStep': WizardLastElementExecutionStep,
        'WizardSaveModuleExecutionStep': WizardSaveModuleExecutionStep,
        'WizardPrecalculationBeforeElementOneExecutionStep': WizardPrecalculationBeforeElementOneExecutionStep,
        'WizardPrecalculationElementOneNextExecutionStep': WizardPrecalculationElementOneNextExecutionStep,
        'WizardPrecalculationBeforeElementTwoExecutionStep': WizardPrecalculationBeforeElementTwoExecutionStep,
        'WizardPrecalculationFinishExecutionStep': WizardPrecalculationFinishExecutionStep,
        'WizardPrecalculationFinishInCustomerDetailsExecutionStep': WizardPrecalculationFinishInCustomerDetailsExecutionStep,
        'OfferGtcTextDisableFieldsExecutionStep': OfferGtcTextDisableFieldsExecutionStep,
        'WorkHourDisableFieldsExecutionStep': WorkHourDisableFieldsExecutionStep,
        'ContextMenuWorkHourCalculateSalaryExecutionStep': ContextMenuWorkHourCalculateSalaryExecutionStep,
        'ContextMenuWorkHourSetCostCenterExecutionStep': ContextMenuWorkHourSetCostCenterExecutionStep,
        'ContextMenuWorkHourOpenSubAssignmentInfoExecutionStep': ContextMenuWorkHourOpenSubAssignmentInfoExecutionStep,
        'ContextMenuWorkHourOpenMonthlyAndSubAssignmentInfoExecutionStep': ContextMenuWorkHourOpenMonthlyAndSubAssignmentInfoExecutionStep,
        'SetInvoicePossibilityToOfferFromDialogExecutionStep': SetInvoicePossibilityToOfferFromDialogExecutionStep,
        'SetInvoicePossibilityToOrderFromDialogExecutionStep': SetInvoicePossibilityToOrderFromDialogExecutionStep,
        'SetContactPersonToOfferFromDialogExecutionStep': SetContactPersonToOfferFromDialogExecutionStep,
        'SetContactPersonToOrderFromDialogExecutionStep': SetContactPersonToOrderFromDialogExecutionStep,
        'SetSelectedWorkingTimeModelToInquiryPositionExecutionStep': SetSelectedWorkingTimeModelToInquiryPositionExecutionStep,
        'SetModuleStateAsDetailsExecutionStep': SetModuleStateAsDetailsExecutionStep,
        'OpenModuleExecutionStep': ComponentOpenModuleExecutionStep,
        'OpenDetailsViewExecutionStep': ComponentOpenDetailsViewExecutionStep,
        'SetupContactPersonExpanderComponentExecutionStep': SetupContactPersonExpanderComponentExecutionStep,
        'OpenAddressPhoneWindowExecutionStep': OpenAddressPhoneWindowExecutionStep,
        'OpenAddressMobilePhoneWindowExecutionStep': OpenAddressMobilePhoneWindowExecutionStep,
        'OpenAddressSecondaryMobilePhoneWindowExecutionStep': OpenAddressSecondaryMobilePhoneWindowExecutionStep,
        'OpenAddressFaxPhoneWindowExecutionStep': OpenAddressFaxPhoneWindowExecutionStep,
        'OpenCustomerDetailsModuleExecutionStep': OpenCustomerDetailsModuleExecutionStep,
        'OpenLeasedEmployeeDetailsModuleExecutionStep': OpenLeasedEmployeeDetailsModuleExecutionStep,
        'OpenCollectiveAgreementDetailsModuleExecutionStep': OpenCollectiveAgreementDetailsModuleExecutionStep,
        'SetupLeasedEmployeePlanningComponentExecutionStep': SetupLeasedEmployeePlanningComponentExecutionStep,
        'SetupLeasedEmployeeSearchExecutionStep': SetupLeasedEmployeeSearchExecutionStep,
        'SetupDispoLeasedEmployeeSearchTabOneExecutionStep': SetupDispoLeasedEmployeeSearchTabOneExecutionStep,
        'CurriculumTreeAddEntryPositionToAddedEntityExecutionStep': CurriculumTreeAddEntryPositionToAddedEntityExecutionStep,
        'FilterReportGridByUserPermissionExecutionStep': FilterReportGridByUserPermissionExecutionStep,
        'FilterReportExecutionGridByReportExecutionStep': FilterReportExecutionGridByReportExecutionStep,
        'WizardSavePrecalculationExecutionStep': WizardSavePrecalculationExecutionStep,
        'WizardSavePrecalculationBeforeElementTwoExecutionStep': WizardSavePrecalculationBeforeElementTwoExecutionStep,
        'WizardSavePrecalculationFinishExecutionStep': WizardSavePrecalculationFinishExecutionStep,
        'ComponentFocusInlineEditorExecutionStep': ComponentFocusInlineEditorExecutionStep,
        'SetupMemoFieldDefinitionValueComponentExecutionStep': SetupMemoFieldDefinitionValueComponentExecutionStep,
        'SetupQuestionnaireFieldDefinitionValueComponentExecutionStep': SetupQuestionnaireFieldDefinitionValueComponentExecutionStep,
        'RemoveIsMemoTypeTextDefinedOnLoadRuntimeFlagExecutionStep': RemoveIsMemoTypeTextDefinedOnLoadRuntimeFlagExecutionStep,
        'ToolbarItemCollectiveAgreementActivateExecutionStep' : ToolbarItemCollectiveAgreementActivateExecutionStep,
        'ToolbarItemContractOfEmploymentUserDataChangeExecutionStep' : ToolbarItemContractOfEmploymentUserDataChangeExecutionStep,
        'ComponentRefreshExecutionStep': ComponentRefreshExecutionStep,
        'MasterComponentRefreshExecutionStep': MasterComponentRefreshExecutionStep,
        'ComponentSaveExecutionStep': ComponentSaveExecutionStep,
        'ComponentAddNewEntityExecutionStep': ComponentAddNewEntityExecutionStep,
        'ComponentCacheChangedEntitiesExecutionStep': ComponentCacheChangedEntitiesExecutionStep,
        'ComponentClearCachedEntitiesExecutionStep': ComponentClearCachedEntitiesExecutionStep,
        'ComponentMergeCachedEntitiesExecutionStep': ComponentMergeCachedEntitiesExecutionStep,
        'WorkHourMonthlyEntryGridColorEntitiesStep': WorkHourMonthlyEntryColorEntitiesExecutionStep,
        'WorkHourMonthlyEntrySetupNewEntityStep': WorkHourMonthlyEntrySetupNewEntityStep,
        'WorkHourMonthlyEntryToolbarItemWageSettledExecutionStep': WorkHourMonthlyEntryToolbarItemWageSettledExecutionStep,
        'WorkHourMonthlyEntryToolbarItemInvoiceSettledExecutionStep': WorkHourMonthlyEntryToolbarItemInvoiceSettledExecutionStep,
        'WorkHourMonthlyEntryCalculateInvoiceExecutionStep': WorkHourMonthlyEntryCalculateInvoiceExecutionStep,
        'WorkHourMonthlyEntryCalculateWageExecutionStep': WorkHourMonthlyEntryCalculateWageExecutionStep,
        'WorkHourEntryPrecalculationArticlesToggleColumnVisibilityExecutionStep': WorkHourEntryPrecalculationArticlesToggleColumnVisibilityExecutionStep,
        'InvoiceToolbarItemInvoiceNumberGenerateExecutionStep': InvoiceToolbarItemInvoiceNumberGenerateExecutionStep,
        'InvoiceToolbarItemInvoiceRecalculateExecutionStep': InvoiceToolbarItemInvoiceRecalculateExecutionStep,
        'SuperadminExportDataToSelectedServerExecutionStep': SuperadminExportDataToSelectedServerExecutionStep,
        'RunCronJobExecutionStep': RunCronJobExecutionStep,
        'OnLeasedEmployeeJobEntityChangeExecutionStep': OnLeasedEmployeeJobEntityChangeExecutionStep,
        'OnLeasedEmployeeSpokenLanguageEntityChangeExecutionStep': OnLeasedEmployeeSpokenLanguageEntityChangeExecutionStep,
        'OpenTodoOwnerModuleExecutionStep': OpenTodoOwnerModuleExecutionStep,
        'SetupOrganisationModulePartComponentExecutionStep': SetupOrganisationModulePartComponentExecutionStep,
        'SetDefaultTextToMasterFormElementExecutionStep': SetDefaultTextToMasterFormElementExecutionStep,
        'SetTicketResponsibleUser' : SetTicketResponsibleUser,
        'ToolbarItemOpenModuleUseMasterEntityExecutionStep': ToolbarItemOpenModuleUseMasterEntityExecutionStep,
        'ComponentFileGridSetMasterFilterBeforeLoadExecutionStep' : ComponentFileGridSetMasterFilterBeforeLoadExecutionStep,
        'CloseTicketFromTicketAction' : CloseTicketFromTicketAction,
        'BeforePhoenixActivePartsLoadExecutionStep': BeforePhoenixActivePartsLoadExecutionStep,
        'BeforeTicketActivePartsLoadExecutionStep': BeforeTicketActivePartsLoadExecutionStep,
        'LockSlaveGridStep': LockSlaveGridStep
    };

}
