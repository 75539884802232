/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./generic-dms-view.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../components/generic-dropdown/generic-dropdown.component.ngfactory";
import * as i3 from "../../../components/generic-dropdown/generic-dropdown.component";
import * as i4 from "../../../services/generic-crud.service";
import * as i5 from "../../../dynamic-table/dynamic-table.component.ngfactory";
import * as i6 from "../../../dynamic-table/dynamic-table.component";
import * as i7 from "@angular/common";
import * as i8 from "../../../../core/executor/executor.service";
import * as i9 from "../../../../core/executor/service/executor-actions/executor-actions.service";
import * as i10 from "../../services/generic/generic-element-validation-execution-steps-factory";
import * as i11 from "../../../../core/executor/factory/execution-step-factory.service";
import * as i12 from "./generic-dms-view.component";
import * as i13 from "../../services/component-highlight-stack.service";
import * as i14 from "../../services/entity-data-store.service";
import * as i15 from "../../services/modules-state.service";
import * as i16 from "../../../validators/services/entity-validator";
import * as i17 from "../../../../core/service/user-session.service";
import * as i18 from "../../../services/permission/permission.service";
import * as i19 from "../../services/elements-stack.service";
var styles_GenericDmsViewComponent = [i0.styles];
var RenderType_GenericDmsViewComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_GenericDmsViewComponent, data: {} });
export { RenderType_GenericDmsViewComponent as RenderType_GenericDmsViewComponent };
function View_GenericDmsViewComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 8, "div", [["class", "error-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 7, "div", [["class", "error-text"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 3, "div", [["class", "error-message"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 2, "b", [], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "span", [["class", "text-red"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["ERROR"])), (_l()(), i1.ɵeld(7, 0, null, null, 2, "div", [["class", "error-detail"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Modul View is not defined!"]))], null, null); }
function View_GenericDmsViewComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 10, "div", [["class", "ui-g"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "ui-g-1"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "label", [["for", "view"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["View"])), (_l()(), i1.ɵeld(4, 0, null, null, 2, "div", [["class", "ui-g-2"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "app-generic-dropdown", [["id", "view"]], null, [[null, "optionChanged"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("optionChanged" === en)) {
        var pd_0 = (_co.onViewChange($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_GenericDropdownComponent_0, i2.RenderType_GenericDropdownComponent)), i1.ɵdid(6, 114688, null, 0, i3.GenericDropdownComponent, [i4.GenericCrudService], { api: [0, "api"] }, { optionChanged: "optionChanged" }), (_l()(), i1.ɵeld(7, 0, null, null, 0, "div", [["class", "ui-g-9"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 2, "div", [["class", "ui-g-12"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 1, "shared-dynamic-table", [], null, [["window", "keyup"]], function (_v, en, $event) { var ad = true; if (("window:keyup" === en)) {
        var pd_0 = (i1.ɵnov(_v, 10).onKeyUp($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i5.View_DynamicTableComponent_0, i5.RenderType_DynamicTableComponent)), i1.ɵdid(10, 245760, null, 0, i6.DynamicTableComponent, [i1.ElementRef, i1.ChangeDetectorRef], { selectFirstEntityWhenNothingIsSelected: [0, "selectFirstEntityWhenNothingIsSelected"], columns: [1, "columns"], rows: [2, "rows"], entities: [3, "entities"], totalCount: [4, "totalCount"], rowsCount: [5, "rowsCount"], paginator: [6, "paginator"], showFilters: [7, "showFilters"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.api; _ck(_v, 6, 0, currVal_0); var currVal_1 = _co.moduleElement.selectFirst; var currVal_2 = _co.columns; var currVal_3 = i1.ɵEMPTY_ARRAY; var currVal_4 = _co.entities; var currVal_5 = _co.totalCount; var currVal_6 = 9999; var currVal_7 = false; var currVal_8 = false; _ck(_v, 10, 0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8); }, null); }
export function View_GenericDmsViewComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_GenericDmsViewComponent_1)), i1.ɵdid(1, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_GenericDmsViewComponent_2)), i1.ɵdid(3, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.api; _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.api; _ck(_v, 3, 0, currVal_1); }, null); }
export function View_GenericDmsViewComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 16777216, null, null, 3, "app-dms-view", [], null, [[null, "mouseover"], [null, "mouseleave"]], function (_v, en, $event) { var ad = true; if (("mouseover" === en)) {
        var pd_0 = (i1.ɵnov(_v, 3).onMouseOver($event) !== false);
        ad = (pd_0 && ad);
    } if (("mouseleave" === en)) {
        var pd_1 = (i1.ɵnov(_v, 3).onMouseLeave($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, View_GenericDmsViewComponent_0, RenderType_GenericDmsViewComponent)), i1.ɵprd(512, null, i8.ExecutorService, i8.ExecutorService, [i9.ExecutorActionsService]), i1.ɵprd(512, null, i10.GenericElementValidationExecutionStepsFactory, i10.GenericElementValidationExecutionStepsFactory, [i11.ExecutionStepFactoryService]), i1.ɵdid(3, 245760, null, 0, i12.GenericDmsViewComponent, [i13.ComponentService, i1.ViewContainerRef, i14.EntityDataStoreService, i15.ModulesStateService, i8.ExecutorService, i10.GenericElementValidationExecutionStepsFactory, i16.EntityValidator, i4.GenericCrudService, i17.UserSessionService, i18.PermissionService, i1.ElementRef, i1.ChangeDetectorRef, i19.ElementsStackService], null, null)], function (_ck, _v) { _ck(_v, 3, 0); }, null); }
var GenericDmsViewComponentNgFactory = i1.ɵccf("app-dms-view", i12.GenericDmsViewComponent, View_GenericDmsViewComponent_Host_0, { masterElementContext: "masterElementContext", moduleElement: "moduleElement", element: "element", parentComponent: "parentComponent", isDialog: "isDialog", isPart: "isPart", toolbarItems: "toolbarItems", statusBarItems: "statusBarItems", selectedMasterEntity: "selectedMasterEntity", additional: "additional", entity: "entity" }, { componentInstantiated: "componentInstantiated" }, []);
export { GenericDmsViewComponentNgFactory as GenericDmsViewComponentNgFactory };
