
<div class="content-wrapper" style="height: 100%">
  <div class='e-sample-resize-container e-tbar-section' *ngIf="pdfToolbar == 'mini'">
    <ejs-toolbar id='topToolbar' #customToolbar>
      <e-items>
        <e-item prefixIcon='e-pv-previous-page-navigation-icon' id='previousPage' tooltipText='Previous Page' (click)='previousClicked($event)'
                align='Center'></e-item>
        <e-item prefixIcon='e-pv-next-page-navigation-icon' id='nextPage' tooltipText='Next Page' (click)='nextClicked($event)' align='Center'></e-item>
        <e-item align='Center'>
          <ng-template #template>
            <div class='material'><input type='text' class='e-input-group e-pv-current-page-number' id='currentPage' value="0"
                                 (keypress)='onCurrentPageBoxKeypress($event)' (click)='onCurrentPageBoxClicked($event)' /></div>
            <div style='margin-left: 6px'><span class='e-pv-total-page-number' id='totalPage'>of 0</span></div>
          </ng-template>
        </e-item>
        <e-item prefixIcon='e-pv-print-document-icon' tooltipText='Print' (click)='printClicked($event)' align='Right'></e-item>
        <e-item prefixIcon='e-pv-download-document-icon' tooltipText='Download' (click)='downloadClicked($event)' align='Right'></e-item>
      </e-items>
    </ejs-toolbar>
  </div>
  <ejs-pdfviewer
    #pdfViewerComponent
    id="pdfViewer"
    [enableHandwrittenSignature]="true"
    [serviceUrl]='service'
    [documentPath]='document'
    [enableToolbar]="pdfToolbar == 'full'"
    [enableNavigationToolbar]="pdfToolbar == 'full'"
    (pageChange)='pageChanged($event)'
    (documentLoad)='documentLoaded($event)'
  ></ejs-pdfviewer>
</div>`
