import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit, ViewChild, ViewContainerRef} from '@angular/core';
import {GenericFullCalendarEventsService} from './service/generic-full-calendar-events.service';
import {Element} from '../../../services/element/element';
import {ModuleElement} from '../../../services/module/module-element';
import {CancelComponentChangesService} from '../../services/cancel-components-changes.service';
import {ExecutorService} from '../../../../core/executor/executor.service';
import {ElementSaveStatus, GenericElementAbstract} from '../generic-element-abstract.component';
import {ComponentService} from '../../services/component-highlight-stack.service';
import {EntityDataChangeMeta, EntityDataStoreService} from '../../services/entity-data-store.service';
import {ModulesStateService} from '../../services/modules-state.service';
import {GenericElementValidationExecutionStepsFactory} from '../../services/generic/generic-element-validation-execution-steps-factory';
import {EntityValidator, EntityValidatorStatus} from '../../../validators/services/entity-validator';
import {GenericCrudService} from '../../../services/generic-crud.service';
import {UserSessionService} from '../../../../core/service/user-session.service';
import {PermissionService} from '../../../services/permission/permission.service';
import {Observable} from 'rxjs/Rx';
import {FullCalendar} from 'primeng/fullcalendar';

@Component({
  selector: 'app-generic-full-calendar',
  styleUrls: ['./generic-full-calendar.component.scss'],
  templateUrl: './generic-full-calendar.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    CancelComponentChangesService,
    ExecutorService,
    GenericElementValidationExecutionStepsFactory
  ]
})
export class GenericFullCalendarComponent extends GenericElementAbstract implements OnInit {
  @Input() element: Element;
  @Input() toolbarItems: any[] = [];
  @Input() statusBarItems: any[] = [];
  @Input() moduleElement: ModuleElement;
  @Input() masterEntity: any = null;
  @Input() masterField: any = null;
  @Input() isPart = false;
  @Input() entity: any = null;

  @ViewChild('fullCalendar', {static: false}) private calendar: FullCalendar;

  public events: any[];

  public options: any;

  protected toolbarContextName = 'fullCalendar';

  constructor(
    protected componentService: ComponentService,
    protected viewContainerRef: ViewContainerRef,
    protected entityDataStoreService: EntityDataStoreService,
    protected modulesStateService: ModulesStateService,
    protected executorService: ExecutorService,
    protected genericElementValidationExecutionStepsFactory: GenericElementValidationExecutionStepsFactory,
    protected entityValidator: EntityValidator,
    protected genericCrudService: GenericCrudService,
    protected userSession: UserSessionService,
    protected permissionService: PermissionService,
    public cdr: ChangeDetectorRef,
    public eventsService: GenericFullCalendarEventsService
  ) {
    super(componentService, viewContainerRef, entityDataStoreService, modulesStateService, executorService, genericElementValidationExecutionStepsFactory, entityValidator, genericCrudService, userSession, permissionService, cdr);
  }

  ngOnInit(): void {
    this.events = [
      {
        'title': 'All Day Event',
        'start': '2021-03-01'
      },
      {
        'title': 'Long Event',
        'start': '2021-03-07',
        'end': '2021-03-10'
      },
      {
        'title': 'Repeating Event',
        'start': '2021-03-09T16:00:00'
      },
      {
        'title': 'Repeating Event',
        'start': '2021-03-16T16:00:00'
      },
      {
        'title': 'Conference',
        'start': '2021-03-11',
        'end': '2021-03-13'
      }
    ];

    this.options = {
      plugins: [dayGridPlugin, timeGridPlugin, interactionPlugin],
      defaultDate: '2021-03-01',
      header: {
        left: 'prev,next',
        center: 'title',
        right: 'month,agendaWeek,agendaDay'
      }
    };
  }

  doValidate(): Observable<EntityValidatorStatus> {
    return undefined;
  }

  getSelectedEntity(): any {
  }

  hasChanges(checkEmbedded: boolean): boolean {
    return false;
  }

  onAfterSave(): Observable<any> {
    return undefined;
  }

  onChange(changeMeta: EntityDataChangeMeta): Observable<any> {
    return undefined;
  }

  onRefresh(): Observable<any> {
    return undefined;
  }

  onSave(): Observable<ElementSaveStatus> {
    return undefined;
  }

  recheckToolbarItems(): void {
  }
}
