import {GenericCrudService} from '../../../../services/generic-crud.service';
import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class GenericFullCalendarEventsService {

  constructor(private genericCrudService: GenericCrudService) {}

  getEvents() {
    return [];
  }
}
