import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component, ElementRef,
  Input, ViewChild,
  ViewContainerRef
} from '@angular/core';
import {ComponentService} from '../../services/component-highlight-stack.service';
import {ModulesStateService} from 'app/shared/content-renderer/services/modules-state.service';
import {EntityDataChangeMeta, EntityDataStoreService} from '../../services/entity-data-store.service';
import {GenericCrudService} from '../../../services/generic-crud.service';
import {PermissionService} from '../../../services/permission/permission.service';
import {ExecutorService} from 'app/core/executor/executor.service';
// tslint:disable-next-line:max-line-length
import {GenericElementValidationExecutionStepsFactory} from 'app/shared/content-renderer/services/generic/generic-element-validation-execution-steps-factory';
import {EntityValidator, EntityValidatorStatus} from '../../../validators/services/entity-validator';
import {Element} from '../../../services/element/element';
import {ModuleElement} from '../../../services/module/module-element';
import {UserSessionService} from '../../../../core/service/user-session.service';
import {ElementSaveStatus, GenericElementAbstract} from '../generic-element-abstract.component';
import {Observable, of as observableOf} from 'rxjs';
import {ElementType} from '../../services/ElementContext';
import {ElementsStackService} from '../../services/elements-stack.service';
import {TableColumn} from '../../../dynamic-table/shared/table-column';
import {Entity} from '../../../helpers/entity';
import {ChangeDetectorRefHelper} from '../../../helpers/change-detector-ref.helper';
import {map, switchMap} from 'rxjs/operators';
import {forkJoin} from 'rxjs';

@Component({
  selector: 'app-dms-view',
  styleUrls: ['./generic-dms-view.component.scss'],
  templateUrl: './generic-dms-view.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    GenericElementValidationExecutionStepsFactory,
    ExecutorService
  ]
})
export class GenericDmsViewComponent extends GenericElementAbstract {

  @Input() element: Element;
  @Input() moduleElement: ModuleElement;
  @Input() entity = null;
  public moduleView = null;
  public api = '';

  public columns: TableColumn[] = [];
  public totalCount = 0;
  public entities = [];

  protected toolbarContextName = 'dmsView';
  public elementType: ElementType = ElementType.DmsView;

  constructor(
    protected componentService: ComponentService,
    protected viewContainerRef: ViewContainerRef,
    protected entityDataStore: EntityDataStoreService,
    protected modulesStateService: ModulesStateService,
    protected executorService: ExecutorService,
    protected genericElementValidationExecutionStepsFactory: GenericElementValidationExecutionStepsFactory,
    protected entityValidator: EntityValidator,
    protected genericCrudService: GenericCrudService,
    protected userSession: UserSessionService,
    protected permissionService: PermissionService,
    protected elementRef: ElementRef,
    public cdr: ChangeDetectorRef,
    protected elementsStackService: ElementsStackService
  ) {
    super(componentService, viewContainerRef, entityDataStore, modulesStateService,
      executorService, genericElementValidationExecutionStepsFactory,
      entityValidator, genericCrudService,
      userSession, permissionService, cdr
    );
  }

  onAfterSave(): Observable<any> {
    return observableOf(null);
  }

  onChange(changeMeta: EntityDataChangeMeta): Observable<any> {
    return observableOf(null);
  }

  public ngOnInit() {
    super.ngOnInit();
    this.elementContext = this.elementsStackService.createContext(this);
    this.elementsStackService.remove(this.elementContext).add(this.elementContext);

    this.moduleView = Entity.getValueInEmbedded(this.moduleElement, 'element.moduleView')
    this.api = `dms/views?modulView=${this.moduleView.id}`
  }

  public ngOnDestroy() {
    super.ngOnDestroy();
  }

  doValidate(): Observable<EntityValidatorStatus> {
    return observableOf(null);
  }

  getSelectedEntity(): any {
  }

  hasChanges(checkEmbedded: boolean): boolean {
    return false;
  }

  onRefresh(): Observable<any> {
    return observableOf(null);
  }

  onSave(): Observable<ElementSaveStatus> {
    return observableOf(null);
  }

  recheckToolbarItems(): void {
  }

  public onViewChange(view: {id: number}): void {
    forkJoin([
      this.genericCrudService.getEntities(`dms/documentsviews?view=${view.id}`),
      this.genericCrudService.getEntities(`dms/viewfields?view=${view.id}`)
        .pipe(map((aViews: any[]) => {
          return aViews.map((aView) => {
            return Entity.getValueInEmbedded(aView, 'memoField')
          })
        })),
    ]).pipe(switchMap((aViews: any[]) => {
      const views = [...aViews[0], ...aViews[1]]

      const memoTypeIds = views.map((aView) => {
        return Entity.getValueInEmbedded(aView, 'memoType.id')
      });

      return this.genericCrudService.getEntities(`dms/dmsfiles?memoType=${memoTypeIds.join(',')}&embedded=fieldDefinitionValues`)
        .pipe(map((entities: any[]) => {
          this.entities = entities;
          return views;
        }))
    })).subscribe((views) => {
      this.columns = views.map((aView) => {
        return {
          key: aView.name,
          header: aView.name,
          renderer: (entity, column) => {
            const fieldDefinitionValues = Entity.getValue(entity, 'fieldDefinitionValues')

            for (const fieldDefinitionValue of fieldDefinitionValues) {
              if (Entity.getValue(fieldDefinitionValue, 'fieldDefinition.name') === column.key) {
                return fieldDefinitionValue.value || '---';
              }
            }

            return '---';
          }
        }
      });
      ChangeDetectorRefHelper.detectChanges(this);
    });
  }
}
