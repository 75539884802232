import {FormGroup} from '@angular/forms';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import {Subscription} from 'rxjs';

import {TranslateService} from '@ngx-translate/core';
import {FormService} from './../form.service';
import {FormViewerService} from './../form-viewer.service';

import {DatamodelCrudService} from './../../services/datamodel/datamodel.crud.service';
import {Datamodel} from './../../services/datamodel/datamodel';

import {
  Element,
  ElementAction,
  ElementButtonDefault,
  ElementInput,
  ElementInputDate,
  ElementInputText,
  ElementInputTextarea,
  Form
} from '../models/index';

import {ModuleElement} from '../../services/module/module-element';

import {FormViewerComponent} from './../form-viewer.component';
import {AbstractElementComponent} from './abstract-element.component';
import {FormAction, FormElementAction} from '../models/form';
import {ElementInputDropdown} from '../models/element-input-dropdown';
import {ElementInputAutocomplete} from '../models/element-input-autocomplete';
import {ComponentSelectedOptionAware} from 'app/shared/form-viewer/element/abstract-element.component';

import {ElementActionTypeDebug, ElementActionTypeHideFormElement, ElementActionTypeShowFormElement} from './element-action-types';
import {Module} from '../../services/module/module';
import {EntityStatus} from '../../services/entity/entity-status';
import {ElementInputAutocompleteComponent} from './element-input-autocomplete.component';
import {ElementInputDropdownComponent} from './element-input-dropdown.component';
import {Constants} from '../../../constants';
import {Entity} from '../../helpers/entity';
import {ExecutionStepFactoryService} from '../../../core/executor/factory/execution-step-factory.service';
import {ExecutionStepPayload} from '../../../core/executor/execution-step-payload';
import {ExecutorService} from '../../../core/executor/executor.service';
import {ElementInputComponent} from './element-input.component';
import {ElementsStackService} from '../../content-renderer/services/elements-stack.service';
import {ElementContext, ElementType} from '../../content-renderer/services/ElementContext';
import {AuthenticationService} from '../../../core/authentication/authentication.service';
import {LocalStorageDataService} from '../../services/local-storage-data.service';
import {Branch} from '../../services/branch/branch';
import {EntityDirtyStoreService} from '../../content-renderer/services/entity-dirty-store.service';
import {environment} from "../../../../environments";
import {DateHelper} from "../../helpers/date.helper";
import {ComponentSaveExecutionStep} from "../../services/execution-step/component-save-execution-step";
import {BlankValidationExecutionStep} from "../../services/execution-step/validation/blank-validation-execution-step";
import {ComponentValidationExecutionStep} from "../../services/execution-step/validation/component-validation-execution-step";
import {ExecutionStepBuilderService} from "../../../core/executor/builder/execution-step-builder.service";
import {ExecutionStatus} from "../../../core/executor/execution-status";
import {ToastComponentsRegistry} from "../../../core/service/toast.service";
import {ExecutorActionEvent} from "../../../core/executor/service/executor-actions/executor-action-event";
import {MessageGrowlService} from "../../../core/message/message-growl.service";
import {MessageService} from "../../../core/message/message.service";
import {ModulesStateService} from '../../content-renderer/services/modules-state.service';
import {ChangeDetectorRefHelper} from '../../helpers/change-detector-ref.helper';
import {UserSessionService} from '../../../core/service/user-session.service';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-form-element',
  templateUrl: './element.component.html',
  styles: [`
    .editor-element-selected,
    .editor-element-selected > .input-element {
      background: rgba(100, 100, 100, .5);
    }

    .element-hidden {
      display: none;
    }
  `]
})
export class ElementComponent extends AbstractElementComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() form: Form;
  @Input() formGroup: FormGroup;
  @Input() entity: any;
  @Input() element: Element;

  /**
   * @description Module element the form is bound to.
   * @type {ModuleElement}
   * @memberOf ElementComponent
   */
  @Input() moduleElement: ModuleElement;

  private selectedElementObj: Element;

  public formControlName: string;

  @Input() get selectedElement(): Element {
    return this.selectedElementObj;
  }

  @Output() selectedElementChange: EventEmitter<Element> = new EventEmitter();
  set selectedElement(val: Element) {
    this.selectedElementObj = val;
    this.selectedElementChange.emit(this.selectedElementObj);
  }

  // @todo make this more generic
  private showDialogAddAddressObj: boolean;

  @Input() get showDialogAddAddress(): boolean {
    return this.showDialogAddAddressObj;
  }

  @Output() showDialogAddAddressChange: EventEmitter<boolean> = new EventEmitter();
  set showDialogAddAddress(val: boolean) {
    this.showDialogAddAddressObj = val;
    this.showDialogAddAddressChange.emit(this.showDialogAddAddressObj);
  }

  private actionParamsObj: any;

  @Input() get actionParams(): any {
    return this.actionParamsObj;
  }

  @Output() actionParamsChange: EventEmitter<any> = new EventEmitter();
  set actionParams(val: any) {
    this.actionParamsObj = val;
    this.actionParamsChange.emit(this.actionParamsObj);
  }

  // @todo check if this is safe cause user could set this attribute with tools like firebug or google chrome dev tools
  @Input() editMode: boolean;

  protected elementSubscriptions: Subscription[] = [];
  protected subscriptions: Subscription[] = [];

  selectedOption: any = null;

  constructor(
    protected formService: FormService,
    public cdr: ChangeDetectorRef,
    protected formViewerService: FormViewerService,
    protected translateService?: TranslateService,
    protected datamodelCrudService?: DatamodelCrudService
  ) {
    super(formService, cdr);
  }

  public onComponentInit() {

  }

  public onComponentChanges() {
  }

  public getSelectedOption(value: any) {}

  protected selectedOptionChanged(newOption: any): boolean {
      if (!this.selectedOption) {
          return true;
      }

      return (newOption.entity && this.selectedOption && this.selectedOption.entity)
          ? newOption.entity.id != this.selectedOption.entity.id : newOption.value != this.selectedOption.value;
  }

  public onComponentDestroy() {
    // prevent memory leak when component destroyed
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  public onComponentAfterViewInit() {
  }

  public onFormAction(action: FormAction) {

  }

  public onFormElementAction(action: FormElementAction) {
    // (GU) :: now this part should probably be refactored to use strategy pattern!
    this.handleAction(action);
  }

  handleAction(action: ElementAction, event?) {
    if (action && action.className) {
      switch (action.className) {
        case 'ElementActionTypeHideFormElement':
          const formElHide = this.getElementByObjectHashId(action.params['formElement'].value);
          if (action.params['formElement'].value && formElHide && !formElHide.isHidden) {
            formElHide.isHidden = true;
          }

          this.formService.validate(this.form);
          this.markElementForCheck(formElHide);
          break;
        case 'ElementActionFocusFormElement':
          let fieldObjectHashId = this.getActionParamValue(action,'formElement'),
            element = this.getElementByObjectHashId(fieldObjectHashId),
            formControlFieldName = FormViewerComponent.getFormControlName(fieldObjectHashId, element.datamodelField),
            toFieldComponent = this.formService.getComponent(formControlFieldName);

          if (toFieldComponent instanceof ElementInputComponent) {
            toFieldComponent.focusInput();
          }
          break;
        case 'ElementActionTypeShowFormElement':
          const formElShow = this.getElementByObjectHashId(action.params['formElement'].value);

          if (action.params['formElement'].value && formElShow && formElShow.isHidden) {
            formElShow.isHidden = false;
          }

          this.formService.validate(this.form);
          this.markElementForCheck(formElShow);
          break;
        case 'ElementActionTypeOpenLink':
          const datamodelFieldOpenLink: string = this.element['datamodelField']
            && this.element['datamodelField'].replace(/\./g, '-p-') || '';
          const formControlNameOpenLink = FormViewerComponent.getFormControlName(this.element.objectHashId, datamodelFieldOpenLink);
          const formElOpenLink = this.formGroup.get(formControlNameOpenLink);

          const formVal = formElOpenLink.value || '';

          const href = `${action.params['linkHref'].value}`.replace('[[inputValue]]', formVal);

          const a = document.createElement('a');
          a.target = '_blank';
          a.href = href;
          a.click();
          a.remove();

          break;
        case 'ElementActionDialogAddAddress':
          this.showDialogAddAddress = true;
          this.actionParams = action.params;
          break;
        case 'ElementActionTypeDebug':
          if (action.params['consoleDebug'].value === true) {
            console.log('debug-2');
          } else {
            alert('Debug!-2');
          }
          break;
        case 'ElementActionFormColorFromDatamodelSource':
          const datamodelField: string = this.element['datamodelField'] && this.element['datamodelField'].replace(/\./g, '-p-') || '';
          const formField = this.formGroup.get(FormViewerComponent.getFormControlName(this.element.objectHashId, datamodelField));

          let dm: Datamodel;

          if (this.element['datamodel'] && this.datamodelCrudService && this.formViewerService && formField && formField.value) {
            if (this.element['datamodel']['id']) {
              dm = this.element['datamodel'];
            } else {
              dm = new Datamodel(+this.element['datamodel']);
            }
            this.subscriptions.push(
              this.datamodelCrudService.getDatamodelRecords(dm).subscribe((dmRecords: any[]) => {
                let color = undefined;
                const formFieldValue = (typeof formField.value.id != 'undefined') ? formField.value.id : formField.value;

                for (const record of dmRecords) {
                  if (record.id == formFieldValue) {
                    color = record.color;
                    if (record.color.charAt(0) !== '#') {
                      /* tslint:disable:no-bitwise */
                      color = '#' + ('000000' + (record.color >>> 0).toString(16)).slice(-6);
                      /* tslint:enable:no-bitwise */
                    }

                    const elementsStackService = this.formService.getInjector().get(ElementsStackService, null);

                    if (elementsStackService) {
                      const formContext: ElementContext = elementsStackService.findByIdAndType(this.moduleElement.id, ElementType.Form);

                      if (formContext && formContext.component instanceof FormViewerComponent) {
                        formContext.component.formBackground = color;
                        ChangeDetectorRefHelper.detectChanges(formContext.component);
                      }
                    }
                    break;
                  }
                }
              })
            );
          }
          break;
        case 'ElementActionFilterDatamodelFromSelect':
            if (action.params['formElement'] && action.params['formElement'].value) {
                let value = this.getValue(),
                  fromProperty = action.params['sourceDatamodelField'].value;

                let optionAwareComponent = this.getSelectedOptionAwareComponent(this.element.datamodelField + '_h_r_f_e_' + this.element.objectHashId);

                if (null !== optionAwareComponent &&
                  optionAwareComponent.selectedOption &&
                  optionAwareComponent.selectedOption.entity
                ) {
                  value = this.formService.getEntityHydrator().getEntityPropertyValue(optionAwareComponent.selectedOption.entity, fromProperty)
                }

                // nothing yet selected, take value directly from entity
                if (!value) {
                  if (this.selectedOption && this.selectedOption.entity && fromProperty) {
                      value = this.formService.getEntityHydrator()
                        .getEntityPropertyValue(this.selectedOption.entity, fromProperty);

                  } else if (fromProperty && this.entity) {
                    value = this.formService.getEntityHydrator()
                      .getEntityPropertyValue(this.entity, fromProperty);

                    if (!value) {
                      value = this.formService.getEntityHydrator()
                        .getEntityPropertyValue(this.entity, this.element.datamodelField + '.' + fromProperty);
                    }
                  }
                } else if (value && value.entity) {
                  value = this.selectedOption.entity[action.params['sourceDatamodelField'].value];
                }

                let formFieldElement = this.getElementByObjectHashId(action.params['formElement'].value);

                if (formFieldElement instanceof ElementInputAutocomplete ||
                  formFieldElement instanceof ElementInputDropdown
                ) {
                  formFieldElement.datamodelFieldFilter = action.params['datamodelField'].value;
                  formFieldElement.datamodelFieldFilterValue = value;
                }
            }

          break;
          case 'ElementActionSetFieldFromFieldDatamodel':
              if (action.params['formElement'] && action.params['formElement'].value && action.params['datamodel'] && action.params['datamodelField']) {
                  let formFieldElementObjectHashId = action.params['formElement'].value,
                    datamodelId = action.params['datamodel'].value,
                    destinationField = action.params['datamodelField'].value,
                    formFieldElement = this.getElementByObjectHashId(formFieldElementObjectHashId);

                  let datamodelFieldName: string = formFieldElement['datamodelField'] && formFieldElement['datamodelField'].replace(/\./g, '-p-') || '';

                  if (this.selectedOption && this.selectedOption.entity) {

                      let value = this.selectedOption.entity[destinationField];

                      // tree like destinationField name
                      if (!value) {
                        value = this.formService.getEntityHydrator().getEntityPropertyValue(this.selectedOption.entity, destinationField);
                      }

                      if(value !== undefined) {
                          let formControlFieldName = FormViewerComponent.getFormControlName(formFieldElementObjectHashId, datamodelFieldName),
                            formControlField = this.formGroup.get(formControlFieldName);

                          if (formFieldElement && formControlField) {

                              // element-input-dropdown.component sets values as { id: value }
                              if (formFieldElement instanceof ElementInputDropdown) {
                                value = { id: value };
                              }

                              formFieldElement.setValue(value);
                              formControlField.setValue(value);

                              const toFieldComponent = this.formService.getComponent(formControlFieldName);
                              if (toFieldComponent) {
                                toFieldComponent.setValue(value, false);
                              }

                              if (action.params['actionPropagation'] && action.params['actionPropagation'].value) {
                                this.formService.handleElementsAction('onInputValueChanged', formFieldElement);
                              }
                          }
                      }

                  } else {
                      let value = this.getValue() && this.getValue().id ? this.getValue().id : this.getValue();

                    if (value == parseInt(value, 10)) {
                      this.subscriptions.push(
                        this.datamodelCrudService.getDatamodelRecord(datamodelId, value).subscribe((dmRecord: any) => {
                          if (dmRecord[destinationField]) {
                            let formControlField = this.formGroup.get(formFieldElement['datamodelField'] + '_h_r_f_e_' + action.params['formElement'].value);
                            if (formFieldElement && formControlField) {

                              // element-input-dropdown.component sets values as { id: value }
                              if (formFieldElement instanceof ElementInputDropdown) {
                                value = {id: value};
                              }

                              formFieldElement.setValue(dmRecord[destinationField]);
                              formControlField.setValue(dmRecord[destinationField]);

                              const toFieldComponent = this.formService.getComponent(formFieldElement['datamodelField']
                                + '_h_r_f_e_' + action.params['formElement'].value);
                              if (toFieldComponent) {
                                toFieldComponent.setValue(dmRecord[destinationField], false);
                              }
                            }
                          }
                        })
                      );
                    }
                  }
              }
                break;
        case 'ElementActionSetBooleanValueBasedOnValue':
          if (action.params['targetFormElement'] && action.params['targetFormElement'].value) {
            const triggerChange = this.getActionParamValue(action, 'triggerChange') || false,
              elementFieldObjectHashId = this.getActionParamValue(action, 'targetFormElement'),
              element = this.getElementByObjectHashId(elementFieldObjectHashId),
              elementControlFieldName = FormViewerComponent.getFormControlName(elementFieldObjectHashId, element.datamodelField),
              elementControlField = this.formGroup.get(elementControlFieldName),
              elementFieldComponent = this.formService.getComponent(elementControlFieldName);

            let value = (action.params['alwaysTrue'] && action.params['alwaysTrue'].value) ? true : this.getValue();

            value = (action.params['invertValue'] && action.params['invertValue'].value) ? !value : value;

            if (elementFieldComponent && elementControlField) {
              element.setValue(value);
              elementFieldComponent.setValue(value, triggerChange);
              elementControlField.setValue(value);
            }
          }
          break;
          case 'ElementActionOpenModule':
            const aModule = this.getActionParamValue(action, 'module');
            const openTarget = this.getActionParamValue(action, 'openIn');
            const asMasterEntity = this.getActionParamValue(action, 'asMasterEntity');
            const part = this.getActionParamValue(action, 'part');

            let entity = this.entity;
            if(asMasterEntity){
              entity = this.formService.getEntityHydrator().getEntityPropertyValue(entity, asMasterEntity, false, true);
            }

            if (aModule) {
              this.subscriptions.push(
                this.formService.getGenericCrudService().getEntities(`superadmin/modules/${aModule.id}`).subscribe((targetModule) => {

                if (openTarget === ModuleElement.DOUBLE_CLICK_TARGET_NEW_TAB) {
                  this.formService.getDoubleClickService().openModuleInNewTab(targetModule, entity, this.moduleElement, null, part);
                } else if (openTarget === ModuleElement.DOUBLE_CLICK_TARGET_CURRENT_TAB) {
                  this.formService.getDoubleClickService().openModuleInCurrentTab(targetModule, entity, this.moduleElement, part);
                } else if (openTarget === ModuleElement.DOUBLE_CLICK_TARGET_DIALOG) {
                  const dialogHeight = this.getActionParamValue(action, 'dialogHeight') || 600;
                  const dialogWidth = this.getActionParamValue(action, 'dialogWidth') || 800;
                  const isAutocompleteView = this.getActionParamValue(action, 'isAutocompleteView') || false;
                  const onClose = this.getActionParamValue(action, 'onClose') || null;

                  this.formService.openDialog(targetModule, this, onClose, {
                    height: dialogHeight,
                    width: dialogWidth,
                    isAutocompleteModuleState: isAutocompleteView,
                    masterElementContext: this.getFormComponent() ? this.getFormComponent().getElementContext() : null,
                    additional: event
                  });
                }

                  ChangeDetectorRefHelper.detectChanges(this.getFormComponent());
                })
              );
            }
          break;
          case 'PrintTemplate':
            const templateId = this.getActionParamValue(action, 'template'),
              fileType = this.getActionParamValue(action, 'fileType') || 'docx',
              userDefinedField = this.getActionParamValue(action, 'userDefinedField'),
              userDefinedValue = this.getActionParamValue(action, 'userDefinedValue');

            let data = {'placeholders':[]};
            if(userDefinedField){
              data.placeholders.push({'name': userDefinedField, 'value': userDefinedValue});
            }
            const executionStepFactoryService = this.formService.getInjector().get(ExecutionStepFactoryService),
              executorService = new ExecutorService();
            let component = this.getFormComponent();
            if (templateId) {
              const steps = [
                (component.ignoreValidations())
                  ? executionStepFactoryService.create(BlankValidationExecutionStep, new ExecutionStepPayload(component))
                  : executionStepFactoryService.create(ComponentValidationExecutionStep, new ExecutionStepPayload(component)),
                executionStepFactoryService.create(ComponentSaveExecutionStep, new ExecutionStepPayload(component))
              ];
              executorService
                .setSteps(steps)
                .execute()
                .subscribe((status: ExecutionStatus) => {
                  if (!status.isSuccess()) {
                    const messageGrowlService = this.formService.getInjector().get(MessageGrowlService);
                    messageGrowlService.error(
                      this.translateService.instant(status.getStepContent()),
                      this.translateService.instant('COMMON.ERROR')
                    );
                  } else {
                    this.formService.getGenericCrudService().customPost(`${Constants.APP_API_ROUTE}/export/entity/${this.form.datamodel.id}/file/${component.entity.id}/template/${templateId}/${fileType}`, data).subscribe((fileResponse) => {
                      const a = document.createElement('a');
                      document.body.appendChild(a);
                      a.style.setProperty('display', 'none');
                      a.href = environment.baseUrl + '/' + fileResponse.file;
                      a.target = '_blank';
                      a.download = fileResponse.fileName;
                      a.click();
                    });
                  }
                });
            }
          break;
          case 'ElementActionSetFieldsFromAssociationField':
            if (action.params['mappingConfig'].value && this.element['datamodel'] && this.element['datamodel']['id']) {
              let mappings = action.params['mappingConfig'].value.split(',');

              if (this.selectedOption && this.selectedOption.entity) {
                  this.fillFields(action, mappings, this.selectedOption.entity);
              } else {
                  let value = null;

                  if (this.getValue()) {
                    value = this.getValue().id || this.getValue();
                  }

                  let datamodelId = this.element['datamodel']['id'];

                  if (value == parseInt(value, 10)) {
                    this.subscriptions.push(
                      this.datamodelCrudService.getDatamodelRecord(datamodelId, value).subscribe((dmRecord: any) => {
                          this.fillFields(action, mappings, dmRecord);
                      })
                    );
                  }
              }
            }
            break;
          case 'ElementActionGetCollectiveAgreementValidTo':
            if(this.getActionParamValue(action, 'collectiveAgreement')) {
              const collectiveAgreementField = this.getActionParamValue(action, 'collectiveAgreement') || null,
                formElement = this.getActionParamValue(action, 'formElement') || null,
                customerField = this.getActionParamValue(action, 'customer') || null;
              let collectiveAgreement = this.formService.getEntityHydrator().getEntityPropertyValue(this.entity, collectiveAgreementField),
                customer = this.formService.getEntityHydrator().getEntityPropertyValue(this.entity, customerField);

              if (collectiveAgreement && customer) {
                collectiveAgreement = collectiveAgreement['id'] || collectiveAgreement;
                customer = customer['id'] || customer;

                let element = this.element;

                if (formElement) {
                  element = this.getElementByObjectHashId(formElement);
                }

                const formControlFieldName = FormViewerComponent.getFormControlName(element.objectHashId, element.datamodelField),
                  formControlField = this.formGroup.get(formControlFieldName),
                  toFieldComponent = this.formService.getComponent(formControlFieldName);

                this.formService.getGenericCrudService().getEntities(`phoenix/collectiveagreements/${collectiveAgreement}/customer/${customer}/validto`).subscribe((validTo) => {
                  if (element instanceof ElementInputDate) {
                    let pattern = /(\d{2})\.(\d{2})\.(\d{4})/;
                    if (validTo.indexOf('.') === -1) {
                      pattern = /(\d{2})(\d{2})(\d{4})/;
                    }
                    let date = new Date(validTo.replace(pattern, '$3-$2-$1'));
                    toFieldComponent.setValue(date, false);
                    formControlField.setValue(date);
                  } else {
                    toFieldComponent.setValue(validTo, false);
                    formControlField.setValue(validTo);
                  }
                });
              }
            }
            break;
          case 'ElementActionGetCollectiveContractNextRaiseDate':
            if(this.getActionParamValue(action, 'collectiveAgreement')) {
              const collectiveAgreementField = this.getActionParamValue(action, 'collectiveAgreement') || null,
                formElement = this.getActionParamValue(action, 'formElement') || null,
                customerField = this.getActionParamValue(action, 'customer') || null;
              let collectiveAgreement = this.formService.getEntityHydrator().getEntityPropertyValue(this.entity, collectiveAgreementField),
                customer = this.formService.getEntityHydrator().getEntityPropertyValue(this.entity, customerField);

              if (collectiveAgreement && customer) {
                collectiveAgreement = collectiveAgreement['id'] || collectiveAgreement;
                customer = customer['id'] || customer;

                let element = this.element;

                if (formElement) {
                  element = this.getElementByObjectHashId(formElement);
                }

                const formControlFieldName = FormViewerComponent.getFormControlName(element.objectHashId, element.datamodelField),
                  formControlField = this.formGroup.get(formControlFieldName),
                  toFieldComponent = this.formService.getComponent(formControlFieldName);

                this.formService.getGenericCrudService().getEntities(`phoenix/collectiveagreements/${collectiveAgreement}/customer/${customer}/nextrisedate`).subscribe((validTo) => {
                  if (element instanceof ElementInputDate) {
                    let pattern = /(\d{2})\.(\d{2})\.(\d{4})/;
                    if (validTo.indexOf('.') === -1) {
                      pattern = /(\d{2})(\d{2})(\d{4})/;
                    }
                    let date = new Date(validTo.replace(pattern, '$3-$2-$1'));
                    toFieldComponent.setValue(date, false);
                    formControlField.setValue(date);
                  } else {
                    toFieldComponent.setValue(validTo, false);
                    formControlField.setValue(validTo);
                  }
                });
              }
            }
            break;
          case 'ElementActionPostAddressCheck':
            this.handlePostAddressFieldsAction(action);
            break;
          case 'ElementActionTakeValueFromAnotherFields':
            this.handleTakeValueFromAnotherFieldsAction(action);
            break;
          case 'ElementActionSetValueToAnotherField':
            this.handleSetValueToAnotherFieldAction(action);
            break;
          case 'SetDateToField':
            if (action.params['formElement'].value) {
              const elementFieldObjectHashId = this.getActionParamValue(action, 'formElement'),
                dateElement = this.getElementByObjectHashId(elementFieldObjectHashId),
                elementControlFieldName = FormViewerComponent.getFormControlName(elementFieldObjectHashId, dateElement.datamodelField),
                elementControlField = this.formGroup.get(elementControlFieldName),
                elementFieldComponent = this.formService.getComponent(elementControlFieldName);

              const day = this.getActionParamValue(action, 'day'),
                month = this.getActionParamValue(action, 'month'),
                year = this.getActionParamValue(action, 'year');

              const date = new Date();

              const currentDay = date.getDate(),
                currentMonth = date.getMonth(),
                currentYear = date.getFullYear(),
                newDate = new Date();

              newDate.setFullYear(currentYear + year, currentMonth + month, currentDay + day);

              if (dateElement instanceof ElementInputDate) {
                elementFieldComponent.setValue(newDate, false);
                elementControlField.setValue(newDate);
              }
            }
          break;
          case 'SetPhoneNumberToAnotherField':
            if (action.params['formElement'].value) {
              const elementFieldObjectHashId = this.getActionParamValue(action, 'formElement'),
                element = this.getElementByObjectHashId(elementFieldObjectHashId),
                elementControlFieldName = FormViewerComponent.getFormControlName(elementFieldObjectHashId, element.datamodelField),
                elementControlField = this.formGroup.get(elementControlFieldName),
                elementFieldComponent = this.formService.getComponent(elementControlFieldName);

              const firstPhoneField = this.getActionParamValue(action, 'firstPhone'),
                secondPhoneField = this.getActionParamValue(action, 'secondPhone'),
                thirdPhoneField = this.getActionParamValue(action, 'thirdPhone');
              const firstPhone = this.formService.getEntityHydrator().getEntityPropertyValue(this.entity, firstPhoneField),
                secondPhone = this.formService.getEntityHydrator().getEntityPropertyValue(this.entity, secondPhoneField),
                thirdPhone = this.formService.getEntityHydrator().getEntityPropertyValue(this.entity, thirdPhoneField);

              if(firstPhone){
                elementFieldComponent.setValue(firstPhone, false);
                elementControlField.setValue(firstPhone);
              }else if(secondPhone){
                elementFieldComponent.setValue(secondPhone, false);
                elementControlField.setValue(secondPhone);
              }else if(thirdPhone){
                elementFieldComponent.setValue(thirdPhone, false);
                elementControlField.setValue(thirdPhone);
              }else {
                elementFieldComponent.setValue('', false);
                elementControlField.setValue('');
              }
            }
          break;
          case 'ModifyDateAndSetToAnotherField':
            if (action.params['formElement'].value) {
              const elementFieldObjectHashId = this.getActionParamValue(action, 'formElement'),
                element = this.getElementByObjectHashId(elementFieldObjectHashId),
                elementControlFieldName = FormViewerComponent.getFormControlName(elementFieldObjectHashId, element.datamodelField),
                elementControlField = this.formGroup.get(elementControlFieldName),
                elementFieldComponent = this.formService.getComponent(elementControlFieldName);

              const day = this.getActionParamValue(action, 'day'),
                month = this.getActionParamValue(action, 'month'),
                year = this.getActionParamValue(action, 'year');

              if (this.element instanceof ElementInputDate){
                let date = this.element.value;
                if (typeof date === 'string') {
                  date = new Date(date);
                }
                if (date instanceof Date) {
                  const currentDay = date.getDate(),
                    currentMonth = date.getMonth(),
                    currentYear = date.getFullYear(),
                    newDate = new Date();

                  newDate.setFullYear(currentYear + year, currentMonth + month, currentDay + day);

                  if (element instanceof ElementInputDate) {
                    elementFieldComponent.setValue(newDate, false);
                    elementControlField.setValue(newDate);
                  } else {
                    elementFieldComponent.setValue(newDate.toString(), false);
                    elementControlField.setValue(newDate.toString());
                  }

                  this.markElementForCheck(element);
                }
              }
            }
            break;
          case 'SetWeekDayToAnotherField':
            if (action.params['formElement'].value) {
              const elementFieldObjectHashId = this.getActionParamValue(action, 'formElement'),
                element = this.getElementByObjectHashId(elementFieldObjectHashId),
                elementControlFieldName = FormViewerComponent.getFormControlName(elementFieldObjectHashId, element.datamodelField),
                elementControlField = this.formGroup.get(elementControlFieldName),
                elementFieldComponent = this.formService.getComponent(elementControlFieldName);

              if(this.element instanceof ElementInputDate){
                let date = this.element.value;
                if(typeof date == 'string'){
                  date = new Date(date);
                }
                if(date instanceof Date){
                  let weekDay = date.getDay();

                  if(element instanceof ElementInputText || element instanceof ElementInputTextarea){
                    elementFieldComponent.setValue(this.translateService.instant("COMMON.DATE.DAY_NAMES_BY_NUMBER." + weekDay), false);
                    elementControlField.setValue(this.translateService.instant("COMMON.DATE.DAY_NAMES_BY_NUMBER." + weekDay));
                  }

                  this.markElementForCheck(element);
                }
              }
            }
            break;
          case 'ModifyGDPRConsentValidToDate':
            if (action.params['fromFormElement'].value && action.params['toFormElement'].value) {
              const fromElementFieldObjectHashId = this.getActionParamValue(action, 'fromFormElement'),
                fromDateElement = this.getElementByObjectHashId(fromElementFieldObjectHashId);

              const toElementFieldObjectHashId = this.getActionParamValue(action, 'toFormElement'),
                toDateElement = this.getElementByObjectHashId(toElementFieldObjectHashId),
                toElementControlFieldName = FormViewerComponent.getFormControlName(toElementFieldObjectHashId, toDateElement.datamodelField),
                toElementFieldComponent = this.formService.getComponent(toElementControlFieldName);

              const isGdprConsentApplication = Entity.getValue(this.entity, 'isGdprConsentApplication'),
                isGdprConsentEvidence = Entity.getValue(this.entity, 'isGdprConsentEvidence');

              if (fromDateElement instanceof ElementInputDate && fromDateElement.value) {
                const dateFrom = fromDateElement.value instanceof Date ? fromDateElement.value : new Date(fromDateElement.value);
                if (dateFrom instanceof Date) {
                  const currentDay = dateFrom.getDate(),
                    currentMonth = dateFrom.getMonth(),
                    currentYear = dateFrom.getFullYear(),
                    newDate = new Date();

                  if (isGdprConsentApplication) {
                    newDate.setFullYear(currentYear, currentMonth + 6, currentDay);
                  } else if (isGdprConsentEvidence) {
                    newDate.setFullYear(currentYear + 5, currentMonth, currentDay);
                  }

                  if (toElementFieldComponent && toDateElement instanceof ElementInputDate) {
                    toElementFieldComponent.setValue(newDate, false);
                  }

                  this.markElementForCheck(toDateElement);
                }
              }
            }
          break;
          case 'SpecialActionForPaymentDate':
            if (action.params['formElement'].value) {
              const elementFieldObjectHashId = this.getActionParamValue(action, 'formElement'),
                element = this.getElementByObjectHashId(elementFieldObjectHashId),
                elementControlFieldName = FormViewerComponent.getFormControlName(elementFieldObjectHashId, element.datamodelField),
                elementControlField = this.formGroup.get(elementControlFieldName),
                elementFieldComponent = this.formService.getComponent(elementControlFieldName);

              if(this.element instanceof ElementInputDate){
                let date = this.element.value;
                if(date instanceof Date){
                  const WEDNESDAY = 2,
                    FRIDAY = 4;
                  let currentDay = date.getDate(),
                    currentWeekDay = date.getDay(),
                    currentMonth = date.getMonth(),
                    currentYear = date.getFullYear(),
                    newDate = new Date();

                  if(currentWeekDay <= WEDNESDAY){
                    currentDay += WEDNESDAY - currentWeekDay;
                  }else if(currentWeekDay > WEDNESDAY && currentWeekDay <= FRIDAY){
                    currentDay += FRIDAY - currentWeekDay;
                  }else if(currentWeekDay > FRIDAY){
                    currentDay += (WEDNESDAY + 7) - currentWeekDay;
                  }

                  newDate.setFullYear(currentYear, currentMonth, currentDay);

                  elementFieldComponent.setValue(newDate, false);
                  elementControlField.setValue(newDate);

                  this.markElementForCheck(element);
                }
              }
            }
            break;
          case 'SpecialActionForHandlingFee':
            if (action.params['formElement'].value) {
              const elementFieldObjectHashId = this.getActionParamValue(action, 'formElement'),
                element = this.getElementByObjectHashId(elementFieldObjectHashId),
                elementControlFieldName = FormViewerComponent.getFormControlName(elementFieldObjectHashId, element.datamodelField),
                elementControlField = this.formGroup.get(elementControlFieldName),
                elementFieldComponent = this.formService.getComponent(elementControlFieldName);

              let advancePeriod = this.formService.getEntityHydrator().getEntityPropertyValue(this.entity, 'advancePeriod'),
                advancePeriodId = null,
                leasedEmployee = this.formService.getEntityHydrator().getEntityPropertyValue(this.entity, 'leasedEmployee'),
                leasedEmployeeId = null;

              if(typeof leasedEmployee === 'object'){
                leasedEmployeeId = leasedEmployee['id'];
              }else{
                leasedEmployeeId = leasedEmployee;
              }

              if(typeof advancePeriod === 'object'){
                advancePeriodId = advancePeriod['id'];
              }else{
                advancePeriodId = advancePeriod;
              }

              if(advancePeriod && leasedEmployee){
                let url = 'phoenix/advances/advancecosts';
                if(this.entity.id){
                  url += `/${this.entity.id}`;
                }
                url += `/leasedemployees/${leasedEmployeeId}/advanceperiods/${advancePeriodId}`;
                this.subscriptions.push(
                  this.formService.getGenericCrudService().get(url).subscribe((handlingFee) => {
                    elementFieldComponent.setValue(handlingFee, true);
                    elementControlField.setValue(handlingFee);
                  })
                );

              }
            }
            break;
        case 'SpecialActionForSelectingWorkingTimeDay':
          if (action.params['formElement'].value) {
            const selectedDateField = this.getActionParamValue(action, 'selectedDateField'),
              selectedWorkingTimeModelField = this.getActionParamValue(action, 'selectedWorkingTimeModelField'),
              elementFieldObjectHashId = this.getActionParamValue(action, 'formElement'),
              element = this.getElementByObjectHashId(elementFieldObjectHashId),
              elementControlFieldName = FormViewerComponent.getFormControlName(elementFieldObjectHashId, element.datamodelField),
              elementControlField = this.formGroup.get(elementControlFieldName),
              elementFieldComponent = this.formService.getComponent(elementControlFieldName);

            let date = this.formService.getEntityHydrator().getEntityPropertyValue(this.entity, selectedDateField),
              selectedWorkingTimeModel = this.formService.getEntityHydrator().getEntityPropertyValue(this.entity, selectedWorkingTimeModelField);

            if(typeof selectedWorkingTimeModel === 'object'){
              selectedWorkingTimeModel = selectedWorkingTimeModel['id'] ? selectedWorkingTimeModel['id'] : null;
            }

            if (typeof date === 'string') {
              date = new Date(date);
            }

            if (element instanceof ElementInputAutocomplete && date instanceof Date && selectedWorkingTimeModel){
              let weekDay = date.getDay(),
                weekDayShort = this.translateService.instant("COMMON.DATE.DAY_NAMES_MIN_BY_NUMBER." + weekDay);

              this.subscriptions.push(
                this.formService.getGenericCrudService().getEntityBy('phoenix/selectedworkingtimemodeldays', 'notes', weekDayShort, {"selectedWorkingTimeModel": selectedWorkingTimeModel}).subscribe((entry) => {

                  if (entry && entry['id']) {
                    const selectedOption = {
                      label: elementFieldComponent.figureOutLabel(entry),
                      value: entry[EntityStatus.ENTITY_DRAFT_FLAG],
                      entity: entry
                    };

                    elementFieldComponent
                      .addOption(selectedOption)
                      .setValue(selectedOption, false)
                      .getSelectedOption(selectedOption);
                  }

                  this.markElementForCheck(element);
                })
              );
            }
          }
          break;
        case 'SetCustomerAdvisor':
          if (action.params['formElement'].value && this.entity && !this.entity['id']) {
            const elementFieldObjectHashId = this.getActionParamValue(action, 'formElement'),
              element = this.getElementByObjectHashId(elementFieldObjectHashId),
              elementControlFieldName = FormViewerComponent.getFormControlName(elementFieldObjectHashId, element.datamodelField),
              elementControlField = this.formGroup.get(elementControlFieldName),
              elementFieldComponent = this.formService.getComponent(elementControlFieldName);
            let customerAdvisor = null;

            const authService = this.formService.getInjector().get(AuthenticationService, null),
              localStorageDataService = this.formService.getInjector().get(LocalStorageDataService, null),
              user = authService.currentUser,
              branchOffice = localStorageDataService.getItem(Branch.LOCAL_STORAGE_NAME);

            let urlParams = {
              'hasAssignmentManager': true,
              'branchOffices': 'manyIn:'+branchOffice.id,
              'embedded':'branchOffices'
            };
            this.subscriptions.push(
              this.formService.getGenericCrudService().getEntities('phoenix/users', '', urlParams).subscribe((entities) => {
                for (const aEntity of entities) {
                  if (aEntity['username'] === user['username']) {
                    customerAdvisor = aEntity;
                    break;
                  }
                }
                if (!customerAdvisor) {
                  customerAdvisor = entities ? entities[0] : null;
                }
                if (customerAdvisor && customerAdvisor['id']) {
                  const selectedOption = {
                    label: elementFieldComponent.figureOutLabel(customerAdvisor),
                    value: customerAdvisor[EntityStatus.ENTITY_DRAFT_FLAG],
                    entity: customerAdvisor
                  };

                  elementFieldComponent
                    .addOption(selectedOption)
                    .setValue(selectedOption, false)
                    .getSelectedOption(selectedOption);
                }
              })
            );
          }
          break;
        case 'SetCollectiveAgreementByEmploymentType':
          if (this.getActionParamValue(action, 'collectiveAgreement')
            && this.getActionParamValue(action, 'employmentTypeField')
            && this.element && this.element.getEntity()
            && this.formService.getInjector().get(EntityDirtyStoreService).isDirty(this.element.getEntity())
          ) {
            if (action.command === 'onEntityChange' && this.element.getEntity() && this.element.getEntity().id) {
              break;
            }
            const employmentType = this.formService.getEntityHydrator().getEntityPropertyValue(
              this.element.getEntity(),
              this.getActionParamValue(action, 'employmentTypeField'),
              false,
              true
              );
              let collectiveAgreement = null;

            if (employmentType && employmentType['isEmployee']) {
              collectiveAgreement = this.formService.getUserSession().get(Constants.COLLECTIVE_AGREEMENT_EMPLOYEES);
            } else if (employmentType && employmentType['isWorker']) {
              collectiveAgreement = this.formService.getUserSession().get(Constants.COLLECTIVE_AGREEMENT_HIRED_WORKER);
            }
            const fieldObjectHashId = this.getActionParamValue(action, 'collectiveAgreement'),
              element = this.getElementByObjectHashId(fieldObjectHashId),
              controlFieldName = FormViewerComponent.getFormControlName(fieldObjectHashId, element.datamodelField),
              controlField = this.formGroup.get(controlFieldName),
              fieldComponent = this.formService.getComponent(controlFieldName);

              if (fieldComponent && fieldComponent instanceof ElementInputAutocompleteComponent && collectiveAgreement) {
                const selectedOption = {
                  label: fieldComponent.figureOutLabel(collectiveAgreement),
                  value: collectiveAgreement[EntityStatus.ENTITY_DRAFT_FLAG],
                  entity: collectiveAgreement
                };

                this.element.getEntity()._embedded[element.datamodelField] = collectiveAgreement;
                element.setValue(collectiveAgreement);
                controlField.setValue(collectiveAgreement);
                fieldComponent
                  .addOption(selectedOption)
                  .setValue(selectedOption)
                  .getSelectedOption(selectedOption);
              }else{
                element.setValue(collectiveAgreement);
                controlField.setValue(collectiveAgreement);
                fieldComponent.setValue(collectiveAgreement);
              }
          }
          break;
          case 'WageCalculation':
            if (action.params['wageAmount'].value && this.entity && this.formService.getInjector().get(EntityDirtyStoreService).isDirty(this.entity)) {
              let collectiveAgreement = null,
                wageUnit = null,
                hasOverPayment = null,
                wageAmount = null;
              if (this.element && this.element.getEntity()) {
                collectiveAgreement = this.formService.getEntityHydrator().getEntityPropertyValue(this.element.getEntity(), 'collectiveAgreement', false, true);
                hasOverPayment = this.formService.getEntityHydrator().getEntityPropertyValue(this.element.getEntity(), 'hasOverPayment');
              }

              let data = {conditions: {}},
                salaryClassification = this.getActionParamValue(action, 'salaryClassification'),
                wageGroupYears = this.getActionParamValue(action, 'wageGroupYears'),
                setMinimalWageAmount = this.getActionParamValue(action, 'setMinimalWageAmount'),
                wageGroupYearsValue = wageGroupYears ? this.formService.getEntityHydrator().getEntityPropertyValue(this.entity, wageGroupYears) : null,
                salaryClassificationValue = salaryClassification ? this.formService.getEntityHydrator().getEntityPropertyValue(this.entity, salaryClassification) : null;

              if(typeof wageGroupYearsValue != 'undefined'){
                data.conditions['Berufsjahr'] = wageGroupYearsValue;
              }
              if(typeof salaryClassificationValue != 'undefined'){
                data.conditions['Beschäftigungsgruppe'] = salaryClassificationValue;
              }
              if(collectiveAgreement && collectiveAgreement.id && (setMinimalWageAmount || !hasOverPayment)){
                this.formService.getGenericCrudService().customPost('phoenix/collectiveagreements/'+collectiveAgreement.id+'/wages', data).subscribe((elements) => {
                  if(elements && elements['salary']) {
                    wageAmount = elements['salary'].amount;
                    wageUnit = elements['salary'].isHourly ? 'STD' : null;
                    wageUnit = elements['salary'].isMonthly ? 'MON' : wageUnit;
                    let monthlyHours = Entity.getValue(this.entity, 'employmentModeHours');
                    if(monthlyHours != 38.5 && wageUnit === 'MON'){
                      wageAmount = (wageAmount / 38.5) * monthlyHours;
                    }
                  }else{
                    wageAmount = null;
                  }
                  const wageAmountFieldObjectHashId = this.getActionParamValue(action, 'wageAmount'),
                    wageAmountElement = this.getElementByObjectHashId(wageAmountFieldObjectHashId),
                    wageAmountControlFieldName = FormViewerComponent.getFormControlName(wageAmountFieldObjectHashId, wageAmountElement.datamodelField),
                    wageAmountControlField = this.formGroup.get(wageAmountControlFieldName),
                    wageAmountFieldComponent = this.formService.getComponent(wageAmountControlFieldName);

                    wageAmountElement['inputFormat']['number']['minValue'] = setMinimalWageAmount ? wageAmount : null;

                    if (!hasOverPayment || wageAmount > this.entity['rewardingSalary']) {
                      wageAmountElement.setValue(wageAmount);
                      wageAmountControlField.setValue(wageAmount);

                      if (wageAmountFieldComponent) {
                        wageAmountFieldComponent.setValue(wageAmount, false);
                      }
                      const wageUnitFieldObjectHashId = this.getActionParamValue(action, 'wageUnit'),
                        wageUnitElement = this.getElementByObjectHashId(wageUnitFieldObjectHashId),
                        wageUnitControlFieldName = FormViewerComponent.getFormControlName(wageUnitFieldObjectHashId, wageUnitElement.datamodelField),
                        wageUnitFieldComponent = this.formService.getComponent(wageUnitControlFieldName);

                    if (wageUnitFieldComponent && wageUnit) {
                      this.formService.getGenericCrudService().getEntityBy('phoenix/units', 'code', wageUnit).subscribe((element) => {

                        const selectedOption = {
                          label: wageUnitFieldComponent.figureOutLabel(element),
                          value: { id: element.id },
                          entity: element,
                          tmpIsDeleted: element.tmpIsDeleted || false
                        };

                        wageUnitFieldComponent
                          .addOption(selectedOption)
                          .setValue(selectedOption, false);
                      });
                    }
                  }
                });
              }
            }
            break;
          case 'ElementActionHideFormElementBasedOnValue':
              if (action.params['formElement'].value && action.params['fieldValue'].value) {
                  let value = this.getValue();
                  let selectedValueEntity = null;

                  if (this.element && this.element.getEntity()
                    && this.element.getEntity()._embedded
                    && this.element.getEntity()._embedded[this.element.datamodelField]){
                    selectedValueEntity = this.element.getEntity()._embedded[this.element.datamodelField];
                  }
                  if (action.params['sourceDatamodelField'].value
                    && this.selectedOption && this.selectedOption.entity
                    && (this.selectedOption.entity[action.params['sourceDatamodelField'].value] !== null)) {
                      value = this.selectedOption.entity[action.params['sourceDatamodelField'].value];
                  } else if (this.selectedOption === null
                    && selectedValueEntity
                    && typeof selectedValueEntity === 'object'
                    && action.params['sourceDatamodelField'].value) {
                    value = selectedValueEntity[action.params['sourceDatamodelField'].value];
                  }
                  if (value === undefined && this.element instanceof ElementInput) {
                    value = this.element.value;
                  }
                  if (typeof value === 'object' && value instanceof Date) {
                    value = value.toISOString().substr(0,10);
                  }
                  if (typeof value === 'object' || typeof value === 'undefined') {
                    value = false;
                  }
                  if (typeof value === 'boolean') {
                    value = value.toString();
                  }

                  let comparator = this.getActionParamValue(action, 'comparator') ? this.getActionParamValue(action, 'comparator') : '=';

                  const formElementToHide = this.getElementByObjectHashId(action.params['formElement'].value);
                  if (action.params['conditionNotEqual'] && action.params['conditionNotEqual'].value){
                    formElementToHide.isHidden = !this.compareValues(value, comparator, action.params['fieldValue'].value);
                  }else {
                    formElementToHide.isHidden = this.compareValues(value, comparator, action.params['fieldValue'].value);
                  }
                  this.formService.validate(this.form);

                  this.markElementForCheck(formElementToHide);
              }
              break;
          case 'ElementActionReadOnlyFormElementBasedOnValue':
              if (action.params['formElement'].value && action.params['fieldValue'].value) {
                  let value = this.getValue();
                  let selectedValueEntity = null;

                  const formElementToDisable = this.getElementByObjectHashId(action.params['formElement'].value);
                  if(!(formElementToDisable instanceof ElementInput || formElementToDisable instanceof ElementButtonDefault)){
                    break;
                  }

                  if (this.element && this.element.getEntity()
                    && this.element.getEntity()._embedded
                    && this.element.getEntity()._embedded[this.element.datamodelField]){
                    selectedValueEntity = this.element.getEntity()._embedded[this.element.datamodelField];
                  }
                  if (action.params['sourceDatamodelField'].value
                    && this.selectedOption && this.selectedOption.entity
                    && (this.selectedOption.entity[action.params['sourceDatamodelField'].value] !== null)) {
                      value = this.selectedOption.entity[action.params['sourceDatamodelField'].value];
                  } else if (this.selectedOption === null
                    && selectedValueEntity
                    && typeof selectedValueEntity === 'object'
                    && action.params['sourceDatamodelField'].value) {
                    value = selectedValueEntity[action.params['sourceDatamodelField'].value];
                  }

                  let requiredValue = action.params['fieldValue'].value;
                  if (requiredValue === 'false') {
                    requiredValue = false;
                  }

                  if (value === undefined && this.element instanceof ElementInput) {
                    value = this.element.value;
                  }
                  if(DateHelper.isIso(value)){
                    value = DateHelper.parseDate(value);
                  }
                  if (value instanceof Date) {
                    value.setHours(0, 0, 0, 0);
                    if (requiredValue === 'today') {
                      requiredValue = new Date();
                      requiredValue.setHours(0, 0, 0, 0);
                    } else if (requiredValue === 'today + 7') {
                      requiredValue = new Date();
                      requiredValue.setDate(requiredValue.getDate() + 7);
                      requiredValue.setHours(0, 0, 0, 0);
                    } else if (requiredValue === 'validFrom + 10') {
                      requiredValue = new Date();
                      requiredValue.setHours(0, 0, 0, 0);
                      value.setDate(value.getDate() + 10);
                    } else {
                      requiredValue = DateHelper.parseDate(requiredValue);
                      requiredValue.setHours(0, 0, 0, 0);
                    }
                  }else if (typeof value === 'object' || typeof value === 'undefined') {
                    value = false;
                  }else if (typeof value === 'boolean') {
                    value = value.toString();
                  }

                  let comparator = this.getActionParamValue(action, 'comparator') ? this.getActionParamValue(action, 'comparator') : '=';

                  if (action.params['conditionNotEqual'] && action.params['conditionNotEqual'].value){
                    formElementToDisable.readOnly = !this.compareValues(value, comparator, requiredValue);
                  }else {
                    formElementToDisable.readOnly = this.compareValues(value, comparator, requiredValue);
                  }

                this.markElementForCheck(formElementToDisable);
              }
              break;
          case 'ElementActionHideFormElementBasedOnExternalEntity':
              if (action.params['formElement'].value
                && action.params['datamodel'].value
                && action.params['datamodelField'].value
                && action.params['resDatamodelField'].value) {
                  let value = this.getValue();
                  let datamodelId = action.params['datamodel'].value;

                  if(value == undefined && this.element instanceof ElementInput){
                    value = this.element.value;
                  }

                  const formElHide = this.getElementByObjectHashId(action.params['formElement'].value);

                  if(action.params['fieldValueParser'].value){
                    let parsedValue = value.match(action.params['fieldValueParser'].value);
                    if(parsedValue && parsedValue[0]){
                      value = parsedValue[0];
                    }else{
                      value = null;
                    }
                  }

                  if(value){
                    this.subscriptions.push(
                      this.datamodelCrudService.getDatamodel(datamodelId).subscribe((datamodel) => {
                        this.subscriptions.push(
                          this.formService.getGenericCrudService().getEntityBy(datamodel.apiRoute, action.params['datamodelField'].value, value).subscribe((entity) => {
                            formElHide.isHidden = (entity && typeof entity[action.params['resDatamodelField'].value] != 'undefined' && entity[action.params['resDatamodelField'].value].toString() === action.params['resValue'].value);
                            this.formService.validate(this.form);
                            this.markElementForCheck(formElHide);
                          })
                        );
                      })
                    );
                  }else{
                    formElHide.isHidden = false;
                    this.formService.validate(this.form);
                    this.markElementForCheck(formElHide);
                  }

              }
              break;
        case 'ElementActionRequiredFieldBasedOnValue':
          if (action.params['formElement'].value && action.params['fieldValue'].value && action.params['sourceDatamodelField'].value) {
            let value = Entity.getValue(this.entity,action.params['sourceDatamodelField'].value);

            const formElementToRequire = this.getElementByObjectHashId(action.params['formElement'].value);
            if(!(formElementToRequire instanceof ElementInput)){
              break;
            }

            let requiredValue = action.params['fieldValue'].value;

            if(DateHelper.isIso(value)){
              value = DateHelper.parseDate(value);
            }
            if (value instanceof Date) {
              value.setHours(0, 0, 0, 0);
              if(requiredValue === 'today'){
                requiredValue = new Date();
                requiredValue.setHours(0, 0, 0, 0);
              }else{
                requiredValue = DateHelper.parseDate(requiredValue);
                requiredValue.setHours(0, 0, 0, 0);
              }
            }else if (typeof value === 'object' || typeof value === 'undefined') {
              value = false;
            }else if (typeof value === 'boolean') {
              value = value.toString();
            }

            let comparator = this.getActionParamValue(action, 'comparator') ? this.getActionParamValue(action, 'comparator') : '=';
            let requiredFlagIndex = formElementToRequire.validators.findIndex((validator) => {
              return (validator.key.toLowerCase() === 'validators.required');
            });

            if (this.compareValues(value, comparator, requiredValue) && requiredFlagIndex === -1){
              formElementToRequire.validators.push({
                key: "Validators.required",
                params: []
              });
              this.getFormComponent().getValidator().addFormValidations(this.entity.fqn, formElementToRequire);
            }else if(!this.compareValues(value, comparator, requiredValue) && requiredFlagIndex !== -1){
              formElementToRequire.validators.splice(requiredFlagIndex,1);
              this.getFormComponent().getValidator().removeFormValidations(this.entity.fqn, formElementToRequire);
            }
            this.getFormComponent().getValidator().clearCache();
            this.getFormComponent().onValidate().subscribe();
            this.formService.validate(this.form);
          }
          break;
        case 'FilterByCollectiveAgreementFromLocalStorage':
          if (action.params['targetFormElement'].value
            && action.params['isForWorkpersonsField'].value
            && action.params['isForEmployeesField'].value) {
            let isForWorkpersonsFieldValue,
              isForEmployeesFieldValue,
              isForWorkpersonsFieldProperty = action.params['isForWorkpersonsField'].value,
              isForEmployeesFieldProperty = action.params['isForEmployeesField'].value;

            let optionAwareComponent = this.getSelectedOptionAwareComponent(this.element.datamodelField + '_h_r_f_e_' + this.element.objectHashId);

            if (null !== optionAwareComponent &&
              optionAwareComponent.selectedOption &&
              optionAwareComponent.selectedOption.entity
            ) {
              isForWorkpersonsFieldValue = this.formService.getEntityHydrator().getEntityPropertyValue(optionAwareComponent.selectedOption.entity, isForWorkpersonsFieldProperty, false, false);
              isForEmployeesFieldValue = this.formService.getEntityHydrator().getEntityPropertyValue(optionAwareComponent.selectedOption.entity, isForEmployeesFieldProperty, false, false);
            }

            let localStorageCollectiveAgreement = null;
            if(isForWorkpersonsFieldValue){
              localStorageCollectiveAgreement = this.formService.getUserSession().get(Constants.COLLECTIVE_AGREEMENT_HIRED_WORKER);
            }else if(isForEmployeesFieldValue){
              localStorageCollectiveAgreement = this.formService.getUserSession().get(Constants.COLLECTIVE_AGREEMENT_EMPLOYEES);
            }

            let formFieldElement = this.getElementByObjectHashId(action.params['targetFormElement'].value);

            if (formFieldElement instanceof ElementInputAutocomplete ||
              formFieldElement instanceof ElementInputDropdown
            ) {
              formFieldElement.datamodelFieldFilter = 'collectiveAgreement';
              formFieldElement.datamodelFieldFilterValue = localStorageCollectiveAgreement;
            }
          }
          break;
        case 'FilterByEntityProperty':
          if (action.params['propertyName'].value) {
            const propertyName = action.params['propertyName'].value;

            if (this.element instanceof ElementInputAutocomplete ||
              this.element instanceof ElementInputDropdown
            ) {
              if (this.element.datamodelFieldFilter && this.element.datamodelFieldFilter !== propertyName) {
                console.error('Trying to change already set `datamodelFieldFilter`, check configuration!');
              }

              this.element.datamodelFieldFilter = propertyName;
              this.element.datamodelFieldFilterValue = Entity.getValue(this.entity, propertyName) ||
                Entity.getValueInEmbedded(this.entity, propertyName);
            }
          }
          break;
          case 'FilterBy':
            if (action.params['filterKey'] && action.params['filterKey'].value &&
              action.params['entityProperty'] && action.params['entityProperty'].value
            ) {
              const filterKey = action.params['filterKey'].value,
                entityPropertyName = action.params['entityProperty'].value;

              const entityPropertyValue = Entity.getValue(this.entity, entityPropertyName) ||
                Entity.getValueInEmbedded(this.entity, entityPropertyName);

              if (entityPropertyValue && (this.element instanceof ElementInputAutocomplete ||
                this.element instanceof ElementInputDropdown
              )) {
                if (this.element.datamodelFieldFilter && this.element.datamodelFieldFilter !== filterKey) {
                  console.error('Trying to change already set `datamodelFieldFilter`, check configuration!');
                }

                this.element.datamodelFieldFilter = filterKey;
                this.element.datamodelFieldFilterValue = entityPropertyValue;
              }
            }
          break;
          case 'SetStartPreCalculationArticleOptions':
            if (action.params['preCalculationField'] && action.params['targetFormElement']) {
              const preCalculationField = action.params['preCalculationField'].value;
              const preCalculation = Entity.getValue(this.entity, preCalculationField);

              const element = this.getElementByObjectHashId(action.params['targetFormElement'].value);
              const formControlFieldName = FormViewerComponent.getFormControlName(action.params['targetFormElement'].value, element.datamodelField),
                toFieldComponent = this.formService.getComponent(formControlFieldName);

              if (element && toFieldComponent && element instanceof ElementInputDropdown) {
                toFieldComponent.pulldownOptions = [];
                element.isHidden = true;
                const urlParams = {
                  'preCalculation': preCalculation.id,
                  'embedded': 'preCalculation'
                };
                this.subscriptions.push(
                  this.formService.getGenericCrudService().getEntities(`phoenix/precalculationarticles`, '', urlParams).subscribe((articles) => {

                    if (articles.length <= 1) {
                      element.isHidden = true;
                    } else {
                      element.isHidden = false;
                      let index = 0;
                      for (const article of articles) {
                        if (index === 0) {
                          toFieldComponent.addOption({label: 'Ab Beginn', value: index, tmpIsDeleted: false});
                        } else {
                          toFieldComponent.addOption({label: `Nach ${index}. Monat`, value: index, tmpIsDeleted: false});
                        }
                        index++;
                      }
                    }

                    this.markElementForCheck(element);
                  })
                );
              }
            }
          break;
          case 'ExecuteStep':
            if (action.params['step'].value && this.moduleElement && this.entity) {
              const stepName = action.params['step'].value,
                stepFactory: ExecutionStepFactoryService = this.formService.getInjector().get(ExecutionStepFactoryService, null);

              const stack: ElementsStackService = this.formService.getInjector().get(ElementsStackService, null),
                elementContext: ElementContext = stack.findByIdAndType(this.moduleElement.id, ElementType.Form);

              const executorService = new ExecutorService(),
                step = stepFactory.createFromString(stepName, new ExecutionStepPayload({
                  element: this,
                  entity: this.entity,
                  component: elementContext ? elementContext.component : null
                }));

              this.subscriptions.push(
                executorService.setSteps([step])
                  .execute()
                  .subscribe()
              );
            }
          break;
        case 'SetCurrentUserValue':
          const genericCrudService = this.formService.getGenericCrudService(),
            meUserComponent = this;

          if (meUserComponent instanceof ElementInputAutocompleteComponent) {
            const currentValue = meUserComponent.getEntityValue();

            if (!currentValue) {
              genericCrudService
                .get('app/users/me?embedded=none').subscribe((user) => {
                  const currentUserValue = meUserComponent.getEntityValue();

                  // double check
                if (!currentUserValue) {
                    const selectedOption = {
                      label: meUserComponent.figureOutLabel(user),
                      value: { id: user.id },
                      entity: user
                    };

                    meUserComponent
                      .addOption(selectedOption)
                      .setValue(selectedOption, false)
                      .getSelectedOption(selectedOption);
                  }
              });
            }
          }
          break;
        case 'SetCurrentBranchOfficeValue':
          const userSession = this.formService.getInjector().get(UserSessionService, null),
            meBranchOfficeComponent = this;

          if (meBranchOfficeComponent instanceof ElementInputAutocompleteComponent) {
            const currentValue = meBranchOfficeComponent.getEntityValue();

            if (!currentValue) {
              const currentBranchOffice = userSession.get(Branch.LOCAL_STORAGE_NAME);

              if (currentBranchOffice) {
                const selectedOption = {
                  label: meBranchOfficeComponent.figureOutLabel(currentBranchOffice),
                  value: { id: currentBranchOffice.id },
                  entity: currentBranchOffice
                };

                meBranchOfficeComponent
                  .addOption(selectedOption)
                  .setValue(selectedOption, false)
                  .getSelectedOption(selectedOption);
              }
            }
          }
          break;
        case 'SetCustomerFilterToCollectiveAgreement':
          if ((this.element instanceof ElementInputAutocomplete ||
            this.element instanceof ElementInputDropdown
          )) {
            this.element.masterFilterField = 'customer';
          }
          break;
        default:
          console.log('no idea what to do', action, event);
          break;
      }
    }
  }

  protected fillFields(action: ElementAction, mappings, dmRecord) {

      for (let mapping of mappings) {
          let configParts = mapping.split(':');
          let sourceField = configParts[0];
          let targetField = configParts[1];

          let formFieldElement = this.getElementByObjectHashId(targetField),
            formControlField = this.formGroup.get(formFieldElement['datamodelField'] + '_h_r_f_e_' + targetField);

          const value = dmRecord[sourceField] || null;
          const skipOnInit = action.command === 'onInit' && this.entity[sourceField];

          if (skipOnInit) {
            continue;
          }

          if (formFieldElement && formFieldElement.value) {
            continue;
          }

          if (formFieldElement && formControlField) {
              formFieldElement.setValue(value);
              formControlField.setValue(value);

              const toFieldComponent = this.formService.getComponent(formFieldElement['datamodelField'] + '_h_r_f_e_' + targetField);
              if (toFieldComponent) {
                  toFieldComponent.setValue(value, false);
              } else if (this.entity) {
                // fallback
                this.entity[formFieldElement.datamodelField] = value;
              }
          }
      }
  }

  filterActionAndHandleIt(command: string, event?: any) {
    if (this.element && this.element.actions instanceof Array && this.element.actions.length > 0) {
      this.element.actions
        .filter((action: ElementAction) => (action.command && action.command.toLowerCase() === command.toLowerCase()))
        .map((action: ElementAction) => {
          this.handleAction(action, event);
        });
    }
  }

  isHidden() {
    return this.element.isHidden;
  }

  getIsValid() {
    return this.element.isValid;
  }

  setIsValid(isValid: boolean) {
    this.element.isValid = isValid;
    return this;
  }

  getElementBy(key: string, value: string): Element {
    return this.formService.getElementBy(this.form, key, value);
  }

  getElementByObjectHashId(objectHashId: string): Element {
    return this.getElementBy('objectHashId', objectHashId);
  }

  getElementByDatamodelField(datamodelField: string): Element {
    return this.getElementBy('datamodelField', datamodelField);
  }

  public onSelectElement() {
    if (this.editMode && this.formService && !this.formService.blockEditSelect) {
      this.selectedElement = this.element;
      // @todo silly hack cause our subscriber at form editor element tree is not fast enough... better would be a way something like wait
      // till you are subscribed and then emit Oo..
      const me = this;
      setTimeout(function() {
        me.formService.elementChanged.emit(me.selectedElement);
      }, 50);

      // @todo silly hack #2
      if (this.element.parent && this.formService) {
        this.formService.blockEditSelect = true;
        setTimeout(function() {
          me.formService.blockEditSelect = false;
        }, 50);
      }
    }
  }

  getValue() {
    if (this.formGroup && this.formGroup.get(this.formControlName) && this.formGroup.get(this.formControlName).value) {
        return this.formGroup.get(this.formControlName).value;
    }
  }

  setValue(value: any, triggerChange: boolean = true, options: Object = {}, updateFormComponent = true, fromAction: string = '') {

    if (typeof (value) !== 'undefined') {
      this.formService.onFormElementValueChange({
        formControlValue: value,
        element: this.element,
        entityValue: value,
        formControlName: this.formControlName,
        formControlOptions: options,
        triggerChange: triggerChange,
        entity: this.entity,
        component: this,
        updateFormComponent: updateFormComponent,
        action: fromAction
      });
    }

    return this;
  }

  public getDialogAddAddress() {
    return this.showDialogAddAddress;
  }

  public onClickElement(event) {
    this.filterActionAndHandleIt('onclick', event);
  }

  protected getActionParamValue(action: any, param: string): any|null {
    let value = null;

    if(action.params && action.params[param] && action.params[param].value && action.params[param].value) {
      value = action.params[param].value;
    }

    return value;
  }
  protected handlePostAddressFieldsAction(action: ElementAction) {
    if (this.getValue()) {
      const data = {
        'addressId': this.formService.getEntityHydrator().getEntityPropertyValue(this.entity, 'id') || ''
      };

      const moduleStateService: ModulesStateService = this.formService.getInjector().get(ModulesStateService, null),
        currentModuleState = moduleStateService.getByComponent(this.getFormComponent());

      const leasedEmployee = currentModuleState.parent && currentModuleState.parent.parent && currentModuleState.parent.parent.entity ?
        currentModuleState.parent.parent.entity : null;

      if (leasedEmployee && leasedEmployee.id) {
        this.formService.getGenericCrudService().customPost(`${Constants.PHOENIX_API_ROUTE}/addresses/leasedemployee/${leasedEmployee.id}/haspostaddress`, data).subscribe((fileResponse) => {
          this.formService.getInjector().get(MessageService).confirm({
            acceptVisible: true,
            rejectVisible: true,
            header: 'Zuordnung Postadresse',
            message: 'Es wurde bereits eine andere Adresse als Postadresse festgelegt.\n' +
              '            Soll diese Adresse nun zur Postadresse werden?\n\n' +
              '            (Die bisherige Postadresse wird in eine gewöhnliche Adresse\n' +
              '          umgewandelt)',
            reject: () => {
              this.setValue(false);
            }
          });
        });
      }
    }
  }

  // (GU) :: move to separate call, service, action component... consult with LH
  protected handleTakeValueFromAnotherFieldsAction(action: ElementAction) {
    let fields = this.getActionParamValue(action, 'fields') || [],
      overwriteValue = this.getActionParamValue(action, 'overwriteValue') || true;

    if (this.getValue() && !overwriteValue) {
      return;
    }

    let newValue = this.getValueFromAction(action, fields);

    this.setValue(newValue, false);
  }

  // (GU) :: move to separate call, service, action component... consult with LH
  protected handleSetValueToAnotherFieldAction(action: ElementAction): void {
    const fields = this.getActionParamValue(action, 'fields') || [],
      overwriteValue = this.getActionParamValue(action, 'overwriteValue') || true,
      setToNull = this.getActionParamValue(action, 'setToNull') || false,
      evalParse = this.getActionParamValue(action, 'evalParse') || false,
      fieldObjectHashId = this.getActionParamValue(action, 'fieldToSetValueTo'),
      fieldToSetValueTo = this.getElementByObjectHashId(fieldObjectHashId);

    if (!fieldToSetValueTo || (fieldToSetValueTo.getValue() && !overwriteValue)) {
      return;
    }

    const element = this.getElementByObjectHashId(fieldObjectHashId);

    const formControlFieldName = FormViewerComponent.getFormControlName(fieldObjectHashId, element.datamodelField),
      formControlField = this.formGroup.get(formControlFieldName),
      toFieldComponent = this.formService.getComponent(formControlFieldName);

    if (element && toFieldComponent instanceof ElementInputAutocompleteComponent && !setToNull) {
      let entity = null;
      if (!fields || !fields[0] || !fields[0].entitySource) {
        entity = this.formService.getEntityHydrator().getEntityPropertyValue(this.entity, element.datamodelField);
      } else {
        entity = this.formService.getEntityHydrator().getEntityPropertyValue(this.entity, fields[0].fieldDatamodel);
      }
      const selectedOption = {
        label: toFieldComponent.figureOutLabel(entity),
        value: entity[EntityStatus.ENTITY_DRAFT_FLAG],
        entity: entity
      };

      toFieldComponent
        .addOption(selectedOption)
        .setValue(selectedOption, false)
        .getSelectedOption(selectedOption);
    } else if (element && toFieldComponent && element instanceof ElementInputDropdown) {
      let newValue = null;
      if (fields && fields[0] && fields[0].entitySource) {
        newValue = this.formService.getEntityHydrator().getEntityPropertyValue(this.entity, fields[0].fieldDatamodel);
      }else {
        newValue = !setToNull ? this.getValueFromAction(action, fields) : null;
      }

      if (newValue) {
        const selectedOption = {
          label: toFieldComponent.figureOutLabel(newValue),
          value: { id: newValue.id },
          entity: newValue,
          tmpIsDeleted: newValue.tmpIsDeleted || false
        };

        toFieldComponent
          .addOption(selectedOption)
          .setValue(selectedOption, false);
      }
    } else if (element) {
      let newValue = null;
      if (fields && fields[0] && fields[0].entitySource) {
        newValue = this.formService.getEntityHydrator().getEntityPropertyValue(this.entity, fields[0].fieldDatamodel);
      }else {
        newValue = !setToNull ? this.getValueFromAction(action, fields) : null;
      }

      if (evalParse) {
        try {
          newValue = eval(newValue);
        }catch (e) {
          newValue = 0;
        }
      }
      element.setValue(newValue);
      formControlField.setValue(newValue);

      if (toFieldComponent) {
        toFieldComponent.setValue(newValue, false);
      }
    }
  }

  /**
   * (GU) :: move to separate call, service, action component... consult with LH
   *
   * @description return string value from ElementActionTakeValueFromAnotherFields and ElementActionSetValueToAnotherField action field params
   * @param fields
   * @returns {string}
   */
  private getValueFromAction(action: ElementAction, fields: any[]): string {
    let newValue = '';
    for (let fieldParam of fields) {

      if (fieldParam.preFreetext) {
        newValue += ' ' + fieldParam.preFreetext;
      }

      if (fieldParam.fieldElementObjectHashId) {
        let element = this.getElementByObjectHashId(fieldParam.fieldElementObjectHashId);

        if (element) {
          let value = this.formService.getEntityHydrator().getEntityPropertyValue(this.entity, element.datamodelField, false, false);

          if (element instanceof ElementInputDropdown && this.entity) {
            value = element.dropdownFieldLabelValue;

            if (!value) {
              let propertyName = `${element.datamodelField}.${element.dropdownFieldLabel}`;
              value = this.formService.getEntityHydrator().getEntityPropertyValue(this.entity, propertyName) || '';
            }
          }

          if(element instanceof ElementInputAutocomplete && this.entity){
            let propertyName = `${element.datamodelField}.${element.dropdownFieldLabel}`;
            value = this.formService.getEntityHydrator().getEntityPropertyValue(this.entity, propertyName) || '';
          }

          // if it is conditional field param, based on element value show different free text
          if (fieldParam.isCondition && element instanceof ElementInputDropdown) {
            let dropdownFieldValue = element.dropdownFieldValue || 'id';

            let valuePropertyName = `${element.datamodelField}.${dropdownFieldValue}`,
              dropdownValue = this.formService.getEntityHydrator().getEntityPropertyValue(this.entity, valuePropertyName, false, false) || '';

            let conditionParams = fieldParam.conditionParams || [];

            for (let conditionParam of conditionParams) {
              if (conditionParam.key === dropdownValue) {

                value = conditionParam.value;
                break;
              }
            }
          }

          if (!(value instanceof Object) && value !== null && typeof value !== 'undefined') {
            newValue += ' ' + value;
          }
        }
      }

      if (fieldParam.postFreetext) {
        newValue += ' ' + fieldParam.postFreetext;
      }
    }

    // remove extra whitespaces
    newValue = newValue.replace(/\s+/g, " ").trim();

    return newValue;
  }

  private getSelectedOptionAwareComponent(formControlName: string): ComponentSelectedOptionAware|null {
    return this.formService.getComponent(formControlName);
  }

  private compareValues(value1, comparator, value2): boolean {
    let result = false;
    switch (comparator){
      case '=':
      case '==':
        result = value1 === value2;
        break;
      case '>':
        result = value1 > value2;
        break;
      case '<':
        result = value1 < value2;
        break;
      case '>=':
      case '=>':
        result = value1 >= value2;
        break;
      case '<=':
      case '=<':
        result = value1 <= value2;
        break;
      case '!=':
      case '<>':
        result = value1 !== value2;
        break;
      default:
        result = value1 === value2;
    }
    return result;
  }
}
