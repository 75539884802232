import { ModuleElement } from "../../services/module/module-element";
import {GenericElementAbstract} from '../elements/generic-element-abstract.component';

export const enum ElementType {
  Grid = 1,
  Form = 2,
  Tree = 3,
  DynamicGrid = 4,
  DynamicTree = 5,
  Assignments = 6,
  NewGrid = 7,
  Wizard = 8,
  Questionnaire = 9,
  ReportView = 10,
  PlanningGrid = 11,
  MemoFieldDefinitionValue = 12,
  WorkHourGrid = 13,
  WorkHourDocuwareGrid = 14,
  WorkHourTimeEntryOverview = 15,
  CronJobForm = 16,
  CustomerInternalNotesForm = 17,
  TodoForm = 18,
  Chart = 19,
  SqlTable = 20,
  PdfViewer = 21,
  ImageViewer = 22,
  MiniPdfViewer = 23,
  FullCalendar = 24,
  DmsView = 25
}

export const enum RuntimeFlagName {
  TriggerSlaveDisabled = 1,
  ExecuteActionsDisabled = 2,
  DisableDialogPersistHideOnNewDialog = 3,
  IsMemoTypeTextDefinedOnLoad = 4,
  DisableDialogPersistHideAfterSave = 5
}

export interface RuntimeFlag {
  name: RuntimeFlagName;
  status: any;
  active: boolean;
}

export const enum PerformedAction {
  Close = 1,
  Hide = 2,
  Back = 3
}

export class MasterEntityConfig {
  /**
   * the id of the datamodel. If available.
   */
  datamodelId: number;

  /**
   * the name of the field, which will be later used for filtering.
   */
  name: string;

  /**
   * the value to be used when filtering.
   */
  value: any;

  /**
   *
   */
  filterType: string;

  /**
   *
   */
  entity: any;
}

/**
 * Element context used in module stack.
 */
export class ElementContext {
  /**
   * id of the component.
   */
  id: number;

  /**
   * the type of the element - either Grid or Form.
   */
  type: ElementType;

  /**
   * the component itself. might be useful for reloading data and stuff.
   */
  component: any;

  /**
   * the rendered module element.
   */
  moduleElement: ModuleElement;

  /**
   * in case we are in sub view (usually dbl click) - the module element that got us here.
   */
  mainViewModuleElement?: ModuleElement;

  /**
   * if exists, the master module element.
   */
  masterElementContext?: ElementContext;

  /**
   * all the slaves that are there for the master module element,
   */
  slaveElementContexts: ElementContext[];

  /**
   * if true the component exists for itself.
   */
  isIndependant: boolean;

  /**
   * set to true if the data in the component hinges on selection in other components.
   */
  isSlave: boolean;

  /**
   * set to true if the component has other slave components.
   */
  isMaster: boolean;

  /**
   * set to true if it is a sub view -> normally a detailed view.
   */
  isSubView: boolean;

  /**
   * if the module element is rendered inside the parts.
   */
  isPart: boolean;

  /**
   * the list of master entities used to filter the data in the grid.
   */
  masterEntities: MasterEntityConfig[];

  /**
   * the currenctly selected entity (if applicable).
   */
  selectedEntity?: any;

  /**
   * this is the main selected entity - ie. in case of master-slave, this is the selected entity in the master grid.
   * In case of the detailed view, this is the double clicked entity in the main grid etc.
   */
  selectedMasterEntity?: any;

  /**
   * the performed action on the element. Used only do identify close actions.
   */
  performedAction?: PerformedAction;

  /**
   * if the module element is rendered inside the dialog.
   */
  isDialog: boolean = false;

  /**
   * module element isMaster flag - to determine if form in details view is master component
   */
  isDetailsViewMaster: boolean = false;

  runtimeFlags: RuntimeFlag[] = [];

  /**
   *
   * @param {number} id
   * @param {ElementType} type
   * @param {ModuleElement} moduleElement
   * @param {boolean} isIndependant
   * @param {boolean} isSlave
   * @param {boolean} isMaster
   * @param {boolean} isSubView
   * @param {boolean} isPart
   * @param {ModuleElement} mainViewModuleElement
   * @param {ModuleElement} masterElementContext
   * @param {any} selectedEntity
   */
  constructor(id: number,
    type: ElementType,
    component: any,
    moduleElement: ModuleElement,
    isIndependant: boolean,
    isSlave: boolean,
    isMaster: boolean,
    isSubView: boolean,
    isPart: boolean,
    mainViewModuleElement?: ModuleElement,
    masterElementContext?: ElementContext,
    selectedEntity?: any,
    performedAction?: PerformedAction,
    selectedMasterEntity?: any,
    isDialog?: boolean,
    isDetailsViewMaster?: boolean) {
    this.id = id;
    this.type = type;
    this.component = component;
    this.isIndependant = isIndependant;
    this.isSlave = isSlave;
    this.isMaster = isMaster;
    this.isSubView = isSubView;
    this.isPart = isPart;
    this.moduleElement = moduleElement;
    this.masterElementContext = masterElementContext;
    this.mainViewModuleElement = mainViewModuleElement;
    this.masterEntities = [];
    this.slaveElementContexts = [];
    this.selectedEntity = selectedEntity;
    this.performedAction = performedAction;
    this.selectedMasterEntity = selectedMasterEntity;
    this.isDialog = isDialog;
    this.isDetailsViewMaster = isDetailsViewMaster;
  }

  /**
   *
   * @param {MasterEntityConfig} masterEntity
   * @returns {ElementContext}
   */
  public addMasterEntity(masterEntity: MasterEntityConfig): ElementContext {
    this.masterEntities.push(masterEntity);

    return this;
  }

  /**
   * @param {string} name
   * @returns {ElementContext}
   */
  public removeMasterEntity(name: string): ElementContext {
    const index = this.masterEntities.findIndex((aConfig: MasterEntityConfig) => {
      return aConfig.name === name;
    });

    if (index !== -1) {
      this.masterEntities.splice(index, 1);
    }

    return this;
  }

  public isSlaveContext(): boolean {
    return this.isSlave || this.isDialog;
  }

  /**
   *
   * @param {ElementContext} elementContext
   * @returns {ElementContext}
   */
  public removeSlaveElementContext(elementContext: ElementContext): ElementContext {
    const slaveIndex = this.slaveElementContexts.findIndex((element) => {
      return elementContext.id === element.id;
    });

    if (slaveIndex !== -1) {
      this.slaveElementContexts.splice(slaveIndex, 1);
    }

    return this;
  }

  /**
   *
   * @param {ElementContext} elementContext
   * @returns {ElementContext}
   */
  public addSlaveElementContext(elementContext: ElementContext): ElementContext {
    const slaveIndex = this.slaveElementContexts.findIndex((element) => {
      return elementContext.id === element.id;
    });

    if (slaveIndex === -1) {
      this.slaveElementContexts.push(elementContext);
    }

    return this;
  }

  /**
   *
   * @param selectedEntity
   * @returns {ElementContext}
   */
  public setSelectedEntity(selectedEntity: any): ElementContext {
    this.selectedEntity = selectedEntity;

    return this;
  }

  /**
   *
   * @param selectedMasterEntity
   * @returns {ElementContext}
   */
  public setSelectedMasterEntity(selectedMasterEntity: any): ElementContext {
    this.selectedMasterEntity = selectedMasterEntity;

    return this;
  }

  /**
   *
   * @param {ElementContext} elementContext
   * @returns {ElementContext}
   */
  public setMasterElementContext(elementContext: ElementContext): ElementContext {
    this.masterElementContext = elementContext;

    return this;
  }

  /**
   *
   * @returns {ElementContext|null}
   */
  public getMasterElementContext(): ElementContext|null {
    return this.masterElementContext || null;
  }

  /**
   *
   * @returns {ElementContext[]}
   */
  public getSlaveElementContexts(): ElementContext[] {
    return this.slaveElementContexts;
  }

  /**
   *
   * @returns {MasterEntityConfig[]}
   */
  public getMasterEntities(): MasterEntityConfig[] {
    return this.masterEntities;
  }

  public getFirstSlaveElementContextsByType(type: ElementType): ElementContext {
    const contexts = this.slaveElementContexts;

    for (const context of contexts) {
      if (context.type === type) {
        return context;
      }
    }

    return null;
  }

  public getMasterEntity(configName: string): MasterEntityConfig|null {
    let masterEntityConfigIndex = this.masterEntities.findIndex((aMasterEntityConfig: MasterEntityConfig) => { return aMasterEntityConfig.name === configName }),
      masterEntityConfig = null;

    if (masterEntityConfigIndex !== -1) {
      masterEntityConfig = this.masterEntities[masterEntityConfigIndex];
    }

    return masterEntityConfig;
  }

  public getMasterEntityValue(configName: string): any|null {
    const masterEntity = this.getMasterEntity(configName);

    return masterEntity ? masterEntity.value : null;
  }

  /**
   *
   * @param {string} name
   * @returns {MasterEntityConfig}
   */
  public findMasterEntity(name: string): MasterEntityConfig {
    for (let masterEntityConfig of this.masterEntities) {
      if (masterEntityConfig.name == name) {
        return masterEntityConfig;
      }
    }

    return null;
  }

  /**
   *
   * @param {string} name
   * @param {any} value
   * @returns {ElementContext}
   */
  public changeMasterConfigValue(name: string, entity: any): ElementContext {
    let masterEntityConfig = this.findMasterEntity(name);

    if (null !== masterEntityConfig) {
      masterEntityConfig.value = entity.id;
      masterEntityConfig.entity = entity;
    }

    return this;
  }

  public addRuntimeFlag(flag: RuntimeFlag): ElementContext {
    this.runtimeFlags.push(flag);
    return this;
  }

  public getRuntimeFlag(name: RuntimeFlagName): RuntimeFlag|null {
    for (const flag of this.runtimeFlags) {
      if (flag.name === name) {
        return flag;
      }
    }

    return null;
  }

  public getRuntimeFlagStatus(name: RuntimeFlagName): any|null {
    const flag = this.getRuntimeFlag(name);

    if (flag) {
      return flag.status;
    }

    return null;
  }

  public removeRuntimeFlag(name: RuntimeFlagName): ElementContext {
    const index = this.runtimeFlags.findIndex((aFlag: RuntimeFlag) => {
      return name === aFlag.name;
    });

    if (index !== -1) {
      this.runtimeFlags.splice(index, 1);
    }

    return this;
  }

  public isRuntimeFlagActive(name: RuntimeFlagName): boolean {
    const flag = this.getRuntimeFlag(name);

    return flag ? flag.active : false;
  }
}
